import './bulletinBoardList.css';
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Dropdown,
  Select,
  Pagination,
  Icon,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ko } from 'date-fns/esm/locale';
import Modal from '../../../shared/components/uiElements/Modal';
// AXIOS
import axios from 'axios';

import DatePicker from 'react-datepicker';

import BulletinBoard from './bulletinBoard';

function BulletinBoardList(props) {
  const APIToken = localStorage.getItem('login-token');

  const [selectBbs, setSelectBbs] = useState();

  // 제목
  const [bbsListNm, setBbsListNm] = useState('');
  const handleBbsListNm = (e) => {
    e.preventDefault();
    setBbsListNm(e.target.value);
  };

  // 등록일
  const today = new Date();
  const lastYear = new Date();
  lastYear.setFullYear(today.getFullYear() - 1);

  const [startBbsYmd, setBbsStartYmd] = useState(lastYear);
  const [endBbsYmd, setBbsEndYmd] = useState(today);

  const divisionOptions = [
    // { key: 0, text: '전체', value: 0 },
    { key: 1, text: '관심구역 공지', value: 1 },
    { key: 2, text: '보고서', value: 2 },
    { key: 3, text: '기타', value: 3 },
    { key: 4, text: '전체', value: 4 },
  ];

  const [divisionOptions_state, setDivisionOptions_state] = useState(4);
  const handleDivisionOptions = (e, { value }) => {
    setDivisionOptions_state(value);
    props.setPostTy(value);
  };

  const attachmentOptions = [
    { key: 0, text: '전체', value: 'all' },
    { key: 1, text: '첨부파일 있음', value: true },
    { key: 2, text: '첨부파일 없음', value: false },
  ];

  const [attachmentOptions_state, setAttachmentOptions_state] = useState(
    attachmentOptions[0].value
  );
  const handleAttachmentOptions_state = (e, { value }) => {
    setAttachmentOptions_state(value);
  };

  // 페이지
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(0); // 한페이지에 페이지 nav 개수
  const [totalCount, setTotalCount] = useState(0); //페이지 block num

  let calculatedTotalPages = '';
  // let totalCount = ''
  const handlePageChange = (event, { activePage }) => {
    setCurrentPage(activePage);
  };

  // 게시판 조회
  const [bulletinBoardList, setBulletinBoardList] = useState([]);
  // const [totalCount, setTotalCount] = useState('');
  const pageSize = 10;

  const bulletinBoardSearchList = () => {
    // if (!props.isBbs_state) {
    //     props.handleIsBbs_state(sessionStorage.getItem('bbs-state'));
    // }

    let bbsListUrl = `/api/bbs/list?postTy=${
      props.postTy
    }&title=${bbsListNm}&page=${currentPage}&perPage=${pageSize}&startDate=${startBbsYmd.toDateString()}&endDate=${endBbsYmd.toDateString()}&attached=${attachmentOptions_state}`;
    if (
      props.isBbs_state === 'bulletinBoard' &&
      props.selectItrst.itrst_zone_id
    ) {
      bbsListUrl += `&zoneId=${props.selectItrst.itrst_zone_id}`;
    }
    axios({
      method: 'get',
      url: bbsListUrl,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setBulletinBoardList(result.data.data);
          setTotalCount(result.data.count);
          setTotalPages(Math.ceil(result.data.count / pageSize));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 초기화
  const [isBbsClear, setBbsIsClear] = useState(false);
  const onClearSearch = () => {
    setBbsListNm('');
    setBbsStartYmd(lastYear);
    setBbsEndYmd(today);
    // setDivisionOptions_state(divisionOptions[3].value);
    props.setPostTy(divisionOptions[3].value);
    setAttachmentOptions_state(attachmentOptions[0].value);
    setBbsIsClear(true);
    // bulletinBoardSearchList();
  };

  useEffect(() => {
    if (props.type === 'notice') {
      // 공지사항일 때는 무조건 검색 리스트 호출
      bulletinBoardSearchList();
    } else if (props.type === 'board') {
      // 게시판일 때 관심구역이 설정된 경우에만 검색 리스트 호출
      if (props.selectItrst) {
        bulletinBoardSearchList();
      }
    }
    // 불필요한 console.log는 제거
  }, [
    currentPage,
    isBbsClear,
    props.postTy,
    props.isBbs_state,
    props.showBulletinBoard,
    props.selectItrst,
  ]);

  return (
    <Modal
      show={props.showBbsList}
      //   onCancel={() => props.setShowBbsList(false)}
      header={props.isBbs_state === 'notice' ? '공지사항' : '관심구역 게시판'}
      icon={
        <i
          className='code x icon'
          onClick={() => props.setShowBbsList(false)}
        />
      }
      isMobile={props.isMobile}
      className={`bbsList`}
    >
      <React.Fragment>
        <div
          className={`contents-col bulletinBoardList-container ${
            props.showBulletinBoard ? 'hidden' : ''
          }`}
        >
          <div className='bulletinBoardList-contents'>
            <div className='main-title'>
              {props.isBbs_state === 'notice' ? '공지사항' : '관심구역 게시판'}
            </div>
            <div className='main-subtitle'>검색</div>
            <div className='contents-col bulletinBoardList-search'>
              <div className='contents-row item-row'>
                <div className='contents-row item'>
                  <div className='label'>제목</div>
                  <div className='text input'>
                    <input
                      type='text'
                      className='input'
                      // className="bulletinBoardList-input"
                      value={bbsListNm || ''}
                      onChange={handleBbsListNm}
                    ></input>
                  </div>
                </div>
                <div
                  className={`contents-row item ${
                    props.isBbs_state === 'notice' ? 'hidden' : ''
                  }`}
                >
                  <div className='label'>구분</div>
                  <div className='text input'>
                    <Select
                      options={divisionOptions}
                      className='division'
                      id='division'
                      name='division'
                      // value={divisionOptions_state}
                      value={divisionOptions_state}
                      onChange={handleDivisionOptions}
                    />
                  </div>
                </div>
              </div>
              <div className='contents-row item-row'>
                <div className='contents-row item'>
                  <div className='label'>등록일</div>
                  <div className='text input'>
                    <div className='contents-row datepick'>
                      <div className='datepicker-container'>
                        <DatePicker
                          className='datepicker'
                          selected={startBbsYmd}
                          onChange={(date) => setBbsStartYmd(date)}
                          dateFormat='yyyy-MM-dd'
                          maxDate={endBbsYmd}
                          locale={ko}
                        ></DatePicker>
                        <i className='calendar alternate outline icon' />
                      </div>
                      <div className='divisionC'>~</div>

                      <div className='datepicker-container'>
                        <DatePicker
                          className='datepicker'
                          selected={endBbsYmd}
                          onChange={(date) => setBbsEndYmd(date)}
                          dateFormat='yyyy-MM-dd'
                          minDate={startBbsYmd}
                          maxDate={0}
                          locale={ko}
                        ></DatePicker>
                        <i className='calendar alternate outline icon' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='contents-row item'>
                  <div className='label'>첨부파일여부</div>
                  <div className='text input'>
                    <Select
                      options={attachmentOptions}
                      className='attachment'
                      id='attachment'
                      name='attachment'
                      value={attachmentOptions_state}
                      onChange={handleAttachmentOptions_state}
                    />
                  </div>
                </div>
              </div>
              <div className='contents-row bulletinBoardList-btn'>
                <div
                  className='gc-btn btn ok'
                  onClick={bulletinBoardSearchList}
                >
                  검색
                </div>
                <div className='gc-btn btn init' onClick={onClearSearch}>
                  초기화
                </div>
              </div>
            </div>
            <div className='contents-row'>
              <div className='main-subtitle'>검색결과</div>
              <div className='main-subtitle result'>
                총 {totalCount}건이 검색되었습니다
              </div>
            </div>
            <div className='contents-col bulletinBoardList-list'>
              <div className='bulletinBoardList-table'>
                <div className='contents-row table-label'>
                  <div className='table-cell label'>No</div>
                  <div
                    className={`table-cell label ${
                      props.isBbs_state === 'notice' ? 'hidden' : ''
                    }`}
                  >
                    구분
                  </div>
                  <div className='table-cell label nm'>제목</div>
                  <div className='table-cell label'>작성자</div>
                  <div className='table-cell label'>작성일자</div>
                  <div className='table-cell label'>조회수</div>
                </div>
                {bulletinBoardList &&
                  bulletinBoardList.map((bbs, i) => {
                    return (
                      <div className='contents-row table-label' key={i}>
                        <div className='table-cell'>
                          {totalCount - (currentPage - 1) * pageSize - i}
                        </div>
                        <div
                          className={`table-cell ${
                            props.isBbs_state === 'notice' ? 'hidden' : ''
                          }`}
                        >
                          {Number(bbs.pst_ty) === 1
                            ? '관심구역 공지'
                            : Number(bbs.pst_ty) === 2
                            ? '보고서'
                            : '기타'}
                        </div>
                        <div
                          // to={'/bbs'}
                          className='table-cell nm'
                          onClick={() => {
                            setSelectBbs(bbs);
                            props.setShowBulletinBoard(true);
                            props.setActiveBbs('bbs-list');
                            // sessionStorage.setItem('bbs-state', props.isBbs_state);
                            // bulletinBoardViews(bbs.pst_id);
                          }}
                        >
                          {bbs.pst_ttl}
                          {bbs.orgnl_file_nm !== null &&
                          bbs.orgnl_file_nm.length > 0 ? (
                            <i className='fa-solid fa-paperclip'></i>
                          ) : null}
                        </div>
                        {/* <Link to={'/bbs'} state={{ bulletinBoardList: bulletinBoardList }} className='table-cell nm' onClick={() => props.setActiveBbs('bbs-list')}>{bbs.pst_ttl}{bbs.orgnl_file_nm !== null && bbs.orgnl_file_nm.length > 0 ? <i className="fa-solid fa-paperclip"></i> : null}</Link> */}
                        {/* <Link to={'/bbs'} className='table-cell nm' onClick={() => props.setActiveBbs('bbs-list')}>{bbs.pst_ttl}{bbs.orgnl_file_nm !== null && bbs.orgnl_file_nm.length > 0 ? <i className="fa-solid fa-paperclip"></i> : null}</Link> */}
                        {/* <div className="table-cell nm" onClick={() => props.setActiveBbs('bbs-list')}>{bbs.pst_ttl}{bbs.orgnl_file_nm !== null && bbs.orgnl_file_nm.length > 0 ? <i className="fa-solid fa-paperclip"></i> : null}</div> */}
                        <div className='table-cell'>{bbs.user_nm}</div>
                        <div className='table-cell'>
                          {bbs.pstg_dt.substring('', 10)}
                        </div>
                        <div className='table-cell'>{bbs.inq_cnt}</div>
                      </div>
                    );
                  })}
              </div>
              <div
                // to={'/bbslist'}
                className='contents-row link'
              >
                <div
                  className='gc-btn btn new'
                  onClick={() => {
                    props.setShowBulletinBoard(true);
                    props.setActiveBbs('bbs-add');
                  }}
                >
                  등록
                </div>
              </div>
            </div>
            <div className='bulletinBoardList-pagination'>
              {/* 현재 게시판 레코드의 토탈 갯수 - ((현재 페이지-1) * 한 화면에 보여질 레코드의 갯수)*/}
              <Pagination
                className='pagination'
                activePage={currentPage}
                totalPages={totalPages}
                ellipsisItem={{
                  content: <Icon name='ellipsis horizontal' />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name='angle double left' />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name='angle double right' />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                onPageChange={handlePageChange}
              ></Pagination>
              {/* </div> */}
            </div>
          </div>
          {/* {
                props.showBulletinBoard ? 
                    // <BulletinBoard
                    //     showBulletinBoard={props.showBulletinBoard}
                    // />
                    <BulletinBoard 
                        showBulletinBoard={props.showBulletinBoard}
                    />
                : null
            } */}
        </div>

        {/* {props.showBulletinBoard && <BulletinBoard/>} */}
        {props.showBulletinBoard && (
          <BulletinBoard
            isBbs_state={props.isBbs_state}
            handleIsBbs_state={props.handleIsBbs_state}
            selectItrst={props.selectItrst}
            postTy={props.postTy}
            setPostTy={props.setPostTy}
            handlePostTy={props.handlePostTy}
            activeBbs={props.activeBbs}
            setActiveBbs={props.setActiveBbs}
            showBulletinBoard={props.showBulletinBoard}
            setShowBulletinBoard={props.setShowBulletinBoard}
            selectBbs={selectBbs}
          />
        )}
      </React.Fragment>
    </Modal>
  );
}

export default BulletinBoardList;
