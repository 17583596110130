import React, { useState, useEffect} from 'react'
import { useDebounce } from '../../hooks/debounce';
import { Icon } from '@iconify/react';
import './SearchBar.css';

const SearchBar = ({
    searchListWord,
    setSearchListWord,
    handleKeyPress,
    onClearSearch,
    searchList
}) => {
    const debouncedInput = useDebounce(searchListWord, 300);

    useEffect(() => {
        setSearchListWord(debouncedInput);
        searchList();
    }, [debouncedInput]);

    const handleBlur = () => {
        searchList();
    };

    return (
        <div
            style={{
                width: '100%',
            }}
            >
            <div className="search-input">
                <div
                    style={{
                        flexGrow: '2',
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: '5px',
                        
                    }}
                >
                    <Icon icon="stash:search-light" width='20px'height='20px'/>
                </div>
                
                <input
                    className="search-text"
                    type="text"
                    placeholder="검색어를 입력하세요"
                    style={{
                        border: 'none',
                        width: 'calc(100% - 30px - 30px)',
                        padding: '7px 0px',
                        flexGrow: 0,
                        fontFamily: "Noto Sans KR",
                    }}
                    value={searchListWord}
                    onChange={(e) => setSearchListWord(e.target.value)}
                    onKeyDown={handleKeyPress}
                    onBlur={handleBlur}
                />
                <div
                    onClick={onClearSearch}
                    style={{
                        cursor: 'pointer',
                        flexGrow: '3',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth:'30px'
                    }}
                >
                    {searchListWord && <Icon icon="iwwa:delete"/>}
                </div>
            </div>
        </div>
    )
}

export default SearchBar
