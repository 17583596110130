// import React from 'react'
import React from 'react';
import { Icon } from '@iconify/react';
import config from '../../../config/config';
import './fixedSidebar.css';
import { useNavigate } from 'react-router-dom';

import BulletinBoardList from '../../../routes/bbs/pages/bulletinBoardList';

const FixedSidebar = (props) => {
  const userName = localStorage.getItem('user-name');
  const isAdmin = localStorage.getItem('user-level') >= 10;
  const metaUrl = config.metaSystemUrl;

  const navigate = useNavigate();

  const handleLogout = () => {
    props.logout();
    navigate('/');
    // window.location.reload();
  };

  // 게시판 랜더링 함수
  //  const renderMenuItem = (bbsType, iconType, label, postType, menuPath) => {

  //   const isActive = props.activeHeaderMenu === menuPath;

  //   return (
  //     <div className={`bbs-${bbsType} ${isActive ? 'active' : ''}`}>
  //       <div
  //         className={`bbs-link-text ${props.isMine ? '' : 'hidden'}`}
  //         onClick={(e) => {
  //           if(props.farmworkSelect){
  //             setShowPopup(false);
  //         } else {
  //           props.handleIsBbs_state(bbsType);
  //           props.handlePostTy(postType);
  //           props.setShowBulletinBoard(false);
  //           props.handleHeader_state(1);
  //           if (bbsType === 'notice' || (bbsType === 'bulletinBoard' && props.selectItrst)) {
  //             // 공지사항이거나, 관심구역이 설정된 게시판일 경우
  //             props.handleShowBbsList(true);
  //           } else if (bbsType === 'bulletinBoard' && !props.selectItrst) {
  //             // 관심구역이 설정되지 않은 게시판일 경우
  //             setShowPopup(true);
  //             props.handleShowBbsList(false);
  //           }
  //          }
  //         }}
  //       >
  //         <Icon
  //           icon={iconType}
  //           width="1.2rem"
  //           height="1.2rem"
  //           style={{
  //             color: isActive ? '#216aa1' : null,
  //           }}
  //         />
  //         {!props.isTablet && (
  //           <span
  //             className="text"
  //             style={{
  //               color: isActive ? '#216aa1' : null,
  //             }}
  //           >
  //             {label}
  //           </span>
  //         )}
  //       </div>
  //     </div>
  //     );
  //   };

  return (
    <div className='side-fixed-bar'>
      <div className='menu'>
        <div
          className='menu-sidebar-closer'
          onClick={(e) => {
            if (props.farmworkSelect) {
              e.stopPropagation();
            } else {
              props.setMainHidden(!props.mainHidden);
            }
          }}
        >
          <div className='fixedbar-icon'>
            {props.mainHidden ? (
              <>
                <Icon
                  icon='system-uicons:window-collapse-left'
                  width='1.5rem'
                  height='1.5rem'
                />
                <div className='fixed-bar-text'>사이드바<br/>접기</div>
              </>
            ) : (
              <>
                <Icon
                  icon='system-uicons:window-collapse-right'
                  width='1.5rem'
                  height='1.5rem'
                />
                <div className='fixed-bar-text'>사이드바<br/>펴기</div>
              </>
            )}
          </div>
        </div>

        {!props.showThemeMapAdd ? (
          <>
            <div className='fixedbar-icon'>
              <div
                className='fixed-bar-text'
                onClick={() => window.open(metaUrl)}
                style={{ fontSize: '13px' }}
              >
                <Icon
                  icon='fluent:door-arrow-right-20-regular'
                  width='1.5rem'
                  height='1.5rem'
                />
                <br />
                메타데이터
                <br />
                등록 서비스
                <br />
                바로가기
              </div>
            </div>
            <div onClick={() => props.handleShowGen(true)}>
              <div className='fixedbar-icon'>
                <Icon
                  icon='flowbite:star-solid'
                  width='1.2rem'
                  height='1.2rem'
                />
                <div className='fixed-bar-text'>
                  관심 구역
                  <br />
                  생성
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className='menu'>
        {/* 공지사항/게시판 */}

        {isAdmin && (
          <div className='menu-admin' onClick={() => props.setShowAdmin(true)}>
            <Icon icon='lets-icons:setting-fill' width='2rem' height='2rem' />
            <div className='fixed-bar-text'>관리자</div>
          </div>
        )}
        <div className='fixedbar-icon'>
          <div
            className='menu-user-info'
            data-tooltip={`${userName} 님`}
            data-position='right center'
            style={{ fontSize: '800' }}
            onClick={() => {
              props.setShowProfile(true);
            }}
          >
            <Icon icon='material-symbols:person' width='2rem' height='2rem' />
            <div className='fixed-bar-text'>회원정보</div>
          </div>
        </div>

        <div className='fixedbar-icon'>
          <div className='menu-logout' onClick={handleLogout}>
            <Icon icon='material-symbols:logout' width='2rem' height='2rem' />
            <div className='fixed-bar-text'>로그아웃</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedSidebar;
