import './themeMapAdd.css';

import React, { useEffect, useState } from 'react';
import { Select } from 'semantic-ui-react';

import { ChromePicker } from 'react-color';

import Popup from '../../shared/components/uiElements/Popup';

// AXIOS
import axios from 'axios';

import moment from 'moment';

import config from '../../config/config';

function ThemeMapAdd(props) {
  const APIToken = localStorage.getItem('login-token');

  const [themeName, setThemeName] = useState('');
  const [addAttrMode, setAddAttrMode] = useState(false);

  const [drone_ArrList, setDrone_ArrList] = useState([]);
  const [selDrnImg, setSelDrnImg] = useState(false);

  const [cropName, setCropName] = useState('이름없음');
  const [cropColor, setCropColor] = useState('#000000');

  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('');
  const [showPopupAlarm, setShowPopupAlarm] = useState(false);
  const [popupMsgAlarm, setPopupMsgAlarm] = useState('');
  const [showPopupCancel, setShowPopupCancel] = useState(false);
  const [popupMsgCancel, setPopupMsgCancel] = useState('');

  const [tempFlag, setTempFlag] = useState(false);

  useEffect(() => {
    setTempFlag(props.tempFlag);
  }, [props.tempFlag]);

  function handleThemeName(e) {
    setThemeName(e.target.value);
  }

  function handleNameChange(e) {
    setCropName(e.target.value);
  }

  function handleColorChange(e) {
    setCropColor(e.hex);
  }

  useEffect(() => {
    if (props.themeName) {
      setThemeName(props.themeName);
    }
  }, [props.themeName]);

  useEffect(() => {
    if (props.selectItrst) {
      let drone_Arr = [];
      axios({
        method: 'get',
        url:
          `/api/drone/img/info?` +
          `itrstZoneId=${props.selectItrst.itrst_zone_id}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          let droneList = result.data.data;

          if (droneList !== null && droneList) {
            for (let i = 0; i < droneList.length; i++) {
              drone_Arr.push({
                key: i,
                text: droneList[i].drn_img_ttl,
                value: JSON.stringify(droneList[i]),
              });
            }
          }
          setDrone_ArrList(drone_Arr);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.selectItrst]);

  function handleSelDrnImg(e, data) {
    if (props.mapRef.current != null) {
      const map = props.mapRef.current.getMap();
      if (map.getLayer('visualDetection_image')) {
        map.removeLayer('visualDetection_image');
        map.removeSource('visualDetection_image');
      }
    }

    const selectData = JSON.parse(data.value);
    const DRN_IMG_LAYER_ID = 'visualDetection_image';
    const DRN_SRC_ACTIVE = {
      id: DRN_IMG_LAYER_ID,
      type: 'raster',
      tiles:
        // ["http://geocapture.co.kr:8080/geoserver/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&LAYER="
        [
          config.geoServer +
            '/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&LAYER=' +
            // [config.geoServerProxy + "/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&LAYER="
            selectData.layer_nm +
            '&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&format=image%2Fpng&TileCol={x}&TileRow={y}',
        ],
      bounds: [
        selectData.bbox[0],
        selectData.bbox[1],
        selectData.bbox[2],
        selectData.bbox[3],
      ],
    };
    // Layer Style
    const DRN_LAYER_STYLE_ACTIVE = {
      id: DRN_IMG_LAYER_ID,
      type: 'raster',
      source: DRN_SRC_ACTIVE,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'raster-opacity': 1.0,
      },
    };

    setSelDrnImg(selectData.layer_nm);

    const map = props.mapRef.current.getMap();
    map.addLayer(DRN_LAYER_STYLE_ACTIVE, 'tn_fmap_info');
  }

  useEffect(() => {
    if (props.activeLayerList) {
      props.activeLayerListHandler();
    }
    if (props.curActiveLayer) {
      props.curActiveLayerHandler();
    }
  }, []);

  function addVisualDetectionMap() {
    if (themeName.length == 0) {
      setPopupMsgAlarm('주제도 제목을 입력하세요.');
      setShowPopupAlarm(true);
    } else {
      let afterGeojson;

      if (props.cropColorMap.length == 1) {
        afterGeojson = {
          type: 'FeatureCollection',
          name: 'reg_cut_test',
          crs: {
            type: 'name',
            properties: { name: 'urn:ogc:def:crs:EPSG::5179' },
          },
          features: props.cropColorMap[0].CROP_GEOJSON,
        };
      } else if (props.cropColorMap.length >= 2) {
        let dummyGeojson = [];

        props.cropColorMap.map((e) => {
          e.CROP_GEOJSON.map((geojson) => {
            dummyGeojson.push(geojson);
          });
        });

        afterGeojson = {
          type: 'FeatureCollection',
          name: 'reg_cut_test',
          crs: {
            type: 'name',
            properties: { name: 'urn:ogc:def:crs:EPSG::5179' },
          },
          features: dummyGeojson,
        };
      }

      // console.log("afterGeojson", afterGeojson);

      let themeData = {
        zoneId: props.selectItrst.itrst_zone_id,
        themeNm: themeName,
        crtrYmd: moment().format('YYYY-MM-DD'),
        crtTy: 4,
        geoJson: JSON.stringify(afterGeojson),
      };

      axios({
        method: 'post',
        url: `/api/theme-map/add`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: themeData,
      })
        .then((result) => {
          // console.log("주제도 등록 result", result);
          if (result.data.success) {
            setPopupMsg('주제도 등록이 완료되었습니다.');
            setShowPopup(true);
            props.setSelectedFarm(null);
          } else {
            setPopupMsg('주제도 등록 실패');
            setShowPopup(true);
            props.setSelectedFarm(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function addcropAttr() {
    // let croplistArray = [...cropList];
    let croplistArray = [...props.cropColorMap];

    if (croplistArray.length == 0) {
      croplistArray.push({
        CROP_ID: 0,
        CROP_NAME: cropName,
        CROP_COLOR: cropColor,
        CROP_COUNT: 0,
        CROP_AREA: 0.0,
        CROP_FMAPID: [],
        CROP_GEOJSON: [],
      });
    } else if (croplistArray.length >= 1) {
      let index = -1;
      croplistArray &&
        croplistArray.map((e, i) => {
          if (e.CROP_COLOR == cropColor || e.CROP_NAME == cropName) {
            index = i;
          }
        });

      if (index == -1) {
        croplistArray.push({
          CROP_ID: croplistArray.length,
          CROP_NAME: cropName,
          CROP_COLOR: cropColor,
          CROP_COUNT: 0,
          CROP_AREA: 0.0,
          CROP_FMAPID: [],
          CROP_GEOJSON: [],
        });
      } else {
        setPopupMsgAlarm('동일 조건의 속성이 이미 존재합니다.');
        setShowPopupAlarm(true);
      }
    }
    props.cropColorMapHandler(croplistArray);
  }

  function initVisualDetection() {
    props.setShowThemeMapAdd(false);
    props.visualDetectionHandler(false);
    props.setSideBar(true);
    if (props.mapRef.current != null) {
      const map = props.mapRef.current.getMap();
      if (map.getLayer('visualDetection_image')) {
        map.removeLayer('visualDetection_image');
        map.removeSource('visualDetection_image');
      }
    }
    props.INIT_FM();
    props.cropColorMapHandler([]);
    setThemeName('');
    setAddAttrMode(false);
    setDrone_ArrList([]);
    setSelDrnImg(false);
    setCropName('이름없음');
    setCropColor('#000000');
    props.handleShowImage(true);
  }

  // useEffect(() => {
  //   if (props.mapRef.current) {
  //     console.log(
  //       "props.selectItrst",
  //       JSON.parse(props.selectItrst.geojson).features
  //     );
  //     const map = props.mapRef.current.getMap();
  //     let multiPolygon = JSON.parse(props.selectItrst.geojson).features;

  //     map.addSource("polygonP", {
  //       type: "geojson",
  //       data: { type: "FeatureCollection", features: multiPolygon },
  //     });
  //     map.addLayer({
  //       id: "polygonP",
  //       source: "polygonP",
  //       type: "fill",
  //       layout: { visibility: "visible" },
  //       paint: {
  //         "fill-color": "rgba(255, 255, 255, 0.0)",
  //         "fill-outline-color": "rgba(255, 255, 0, 1.0)",
  //       },
  //     });
  //     map.moveLayer("polygonP");

  //     const mapLayers = map.getStyle().layers;
  //     console.log("map :", mapLayers);
  //   }
  // }, []);

  return (
    <>
      <div
        className={`contents-col thememapadd-container ${
          !props.mainHidden == 1 ? 'hidden' : ''
        }`}
      >
        {/* <div className='image'></div> */}
        <div
          className={`sidemenu-contatiner ${
            !props.mainHidden == 1 ? 'hidden' : ''
          }`}
        >
          <div className='contents-col sidemenu-info position-btn'>
            <label className='label'>주제도 제목</label>
            <input
              className='input-theme'
              type='text'
              id='name'
              name='name'
              placeholder='주제도 제목 입력'
              onChange={handleThemeName}
              value={themeName}
            />
            {/* <div className='gc-btn dupCheck'>중복확인</div>   */}
          </div>
          <div className='contents-col sidemenu-info'>
            <label className='label'>드론영상 선택</label>
            <Select
              className='input-theme'
              options={drone_ArrList}
              placeholder='드론영상 선택'
              onChange={handleSelDrnImg}
              // value={selDrnImg}
            />
          </div>
          <div className='contents-col sidemenu-info'>
            <label className='label'>필드 속성정보</label>
            <div className='contents-col input-theme info'>
              <div
                className='gc-btn field-add'
                onClick={() => {
                  setAddAttrMode(!addAttrMode);
                }}
              >
                + 속성 추가
              </div>
              {addAttrMode ? (
                <div className='contents-col add-attr-container flex-center'>
                  <div className='info-text'>
                    ※색상과 속성명 입력 후 '추가'를 클릭하세요
                  </div>
                  <div className='contents-row flex-center'>
                    <div className='add-attr-text'>속성명</div>
                    <input
                      className='add-attr-name'
                      onChange={handleNameChange}
                    ></input>
                  </div>
                  <ChromePicker
                    className='sketchPicker'
                    color={cropColor}
                    onChange={handleColorChange}
                    disableAlpha={true}
                  />
                  <button
                    className='gc-btn add-attr-button'
                    onClick={() => {
                      addcropAttr();
                      setAddAttrMode(false);
                    }}
                  >
                    추가
                  </button>
                </div>
              ) : null}
              {props.cropColorMap &&
                props.cropColorMap.map((e, i) => {
                  return (
                    <div className='contents-row crop' key={i}>
                      <div
                        className='attributeClick'
                        onClick={() => {
                          props.visualDetectionHandler(i + 1);
                          props.selCropColorHandler(e.CROP_COLOR);
                          props.selCropNameHandler(e.CROP_NAME);
                        }}
                      >
                        <div
                          className='cropSeleted'
                          style={{ backgroundColor: e.CROP_COLOR }}
                        />
                        {props.visualDetection === i + 1 ? (
                          <div
                            className={`gc-btn field-color ${
                              props.visualDetection === i + 1 ? 'active' : ''
                            }`}
                            // style={{ border: `3px solid ${e.CROP_COLOR}` }}
                            style={{ border: `2px solid #999999` }}
                            // style={{
                            //   boxShadow: `1.5px 1.5px 0 2px ${e.CROP_COLOR}`,
                            // }}
                            // style={{ boxShadow: `5px 5px 7px -5px inset` }}
                            // style={{
                            //   boxShadow: `rgb(124 124 124) -5px -5px 8px -5px inset`,
                            // }}
                          >
                            {e.CROP_NAME}
                          </div>
                        ) : (
                          <div
                            className={`gc-btn field-color ${
                              props.visualDetection === i + 1 ? 'active' : ''
                            }`}
                          >
                            {e.CROP_NAME}
                          </div>
                        )}

                        {/* <div className="image"></div> */}
                      </div>
                      {/* <i
                      className="large code x icon close"
                      onClick={() => {
                        props.cropColorDelMapHandler(i);
                        props.visualDetectionHandler(false);
                        setTempFlag(!tempFlag);
                      }}
                    /> */}
                      <i
                        className='x-icon'
                        onClick={() => {
                          props.cropColorDelMapHandler(i);
                          props.visualDetectionHandler(false);
                          setTempFlag(!tempFlag);
                        }}
                      />
                    </div>
                  );
                })}
              <div
                className='contents-col gc-btn reset'
                onClick={() => {
                  const dummyArray = props.cropColorMap;

                  dummyArray.map((e) => {
                    e.CROP_AREA = 0;
                    e.CROP_COUNT = 0;
                    e.CROP_FMAPID = [];
                    e.CROP_GEOJSON = [];
                  });

                  props.visualDetectionHandler(false);
                  props.cropColorMapHandler(dummyArray);
                  props.INIT_FM();
                }}
              >
                <i className='redo alternate icon' />
                선택 초기화
              </div>
            </div>
          </div>
          <div className='contents-row btn-group-bottom'>
            {/* <div className="gc-btn btn-theme-map save">임시저장</div> */}
            <div
              className='gc-btn btn-theme-map close'
              onClick={() => {
                setPopupMsgCancel('생성을 취소하시겠습니까?');
                setShowPopupCancel(true);
              }}
            >
              취소
            </div>
            <div
              className='gc-btn btn-theme-map add'
              onClick={addVisualDetectionMap}
            >
              등록
            </div>
          </div>
        </div>

        {props.cropColorMap && props.cropColorMap.length != 0 ? (
          <div className='contents-col field-info-group'>
            <div className='title flex-center'>속성정보</div>
            {props.cropColorMap &&
              props.cropColorMap.map((e, i) => {
                return (
                  <div className='contents-row info-group' key={i}>
                    <div
                      className='area'
                      style={{ background: e.CROP_COLOR }}
                    ></div>
                    <div className='text'>
                      {e.CROP_NAME} ( {e.CROP_COUNT} 필지 /{' '}
                      {(Number(e.CROP_AREA) / 10000).toFixed(2)} ㏊ ){/* ㎡ */}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : null}

        <Popup
          show={showPopup}
          onCancel={() => {
            setShowPopup(false);
            initVisualDetection();
          }}
          header='알림'
          icon={
            <i
              className='code x icon'
              onClick={() => {
                setShowPopup(false);
                initVisualDetection();
              }}
            />
          }
        >
          <div className='modal-info'>
            <p>{popupMsg}</p>
            <button
              className='close_btn'
              onClick={() => {
                setShowPopup(false);
                initVisualDetection();
              }}
            >
              닫기
            </button>
          </div>
        </Popup>

        <Popup
          show={showPopupAlarm}
          onCancel={() => {
            setShowPopupAlarm(false);
          }}
          header='알림'
          icon={
            <i
              className='code x icon'
              onClick={() => {
                setShowPopupAlarm(false);
              }}
            />
          }
        >
          <div className='modal-info'>
            <p>{popupMsgAlarm}</p>
            <button
              className='close_btn'
              onClick={() => {
                setShowPopupAlarm(false);
              }}
            >
              닫기
            </button>
          </div>
        </Popup>

        <Popup
          show={showPopupCancel}
          onCancel={() => {
            setShowPopupCancel(false);
          }}
          header='알림'
          icon={
            <i
              className='code x icon'
              onClick={() => {
                setShowPopupCancel(false);
              }}
            />
          }
        >
          <div className='modal-info'>
            <p>{popupMsgCancel}</p>
            <button
              className='zoneinfo_close_btn'
              onClick={() => {
                setShowPopupCancel(false);
              }}
            >
              아니오
            </button>
            <button
              className='zoneinfo_ok_btn'
              onClick={() => {
                initVisualDetection();
                props.onFarmmapHandler(false);
              }}
            >
              예
            </button>
          </div>
        </Popup>
      </div>
      {
        // cropList.length != 0?
        props.visualDetection ? (
          <div
            className={`selection-mode-container ${
              !props.mainHidden == 1 ? 'hidden' : ''
            }`}
          >
            <div className='contents-col selection-mode'>선택 모드</div>
            <div className='contents-row shortcutimage-box'>
              <button
                className={`contents-col shortcutimage individua-selection ${
                  props.selectedMode == 2 ? 'active' : ''
                }`}
                onClick={() => props.handleSelectionMode(2)}
              >
                <div className='image'></div>
                <p className='text'>개별선택</p>
              </button>
              <button
                className={`contents-col shortcutimage drag-drop ${
                  props.selectedMode == 3 ? 'active' : ''
                }`}
                onClick={() => props.handleSelectionMode(3)}
              >
                <div className='image'></div>
                <p className='text'>영역선택</p>
              </button>
            </div>
          </div>
        ) : null
      }
    </>
  );
}

export default ThemeMapAdd;
