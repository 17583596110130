import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isTabletHeight = useMediaQuery({ query: "(max-height: 720px)" });
  const isTabletWidth = useMediaQuery({
    query:
      "(min-width: 501px) and (max-width: 1210px) and (orientation: portrait)",
  });

  return {
    isTabletHeight,
    isTabletWidth,
  };

  // const isDestTop = useMediaQuery({
  //   query: '(min-width: 1024px)', // 노트북 및 PC (1024px 이상)
  // });

  // const isTabletWidth = useMediaQuery({
  //   query:
  //     '(min-width: 768px) and (max-width: 1023px) and (orientation: landscape)', // 태블릿 가로 모드
  // });

  // const isMobile = useMediaQuery({
  //   query: '(max-width: 767px)', // 모바일 (767px 이하)
  // });

  // return {
  //   isDestTop,
  //   isTabletWidth,
  //   isMobile,
  // };
};

export default useResponsive;
