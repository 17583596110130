import "./login.css";
import React, { useState, useEffect } from "react";

import { Input, Checkbox } from "semantic-ui-react";

import { Link } from "react-router-dom";

import Popup from "../../../shared/components/uiElements/Popup";

// AXIOS
import axios from "axios";

function Login(props) {
  const [ID, setID] = useState();
  const [PW, setPW] = useState();

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState(false);

  const handleID = (event) => {
    event.preventDefault();
    setID(event.target.value);
  };

  const handlePW = (event) => {
    event.preventDefault();
    setPW(event.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem("save-id") == "true") {
      setID(localStorage.getItem("user-id"));
    } else if (localStorage.getItem("save-id") == "false") {
      setID();
    }
  }, []);

  const loginPost = () => {
    if (ID && PW) {
      axios({
        method: "post",
        url: `/api/login`,
        data: {
          id: ID,
          password: PW,
        },
      })
        .then((result) => {
          if (result.data.success) {
            localStorage.setItem("login-token", result.data.token);
            localStorage.setItem("user-name", result.data.data.userNm);
            localStorage.setItem("user-level", result.data.data.levelCd);
            localStorage.setItem("user-id", result.data.data.userId);
            if (props.saveIdCheckbox == true) {
              localStorage.setItem("save-id", "true");
            } else if (props.saveIdCheckbox == false) {
              localStorage.setItem("save-id", "false");
            }
            if (props.autoLoginCheckbox == true) {
              localStorage.setItem("auto-login", "true");
              localStorage.setItem(
                "login-time",
                new Date().getTime() + 86400000000
              );
            } else if (props.autoLoginCheckbox == false) {
              localStorage.setItem("auto-login", "false");
              localStorage.setItem(
                "login-time",
                new Date().getTime() + 86400000
              );
            }
            props.login();
          } else {
            if (result.data.hasOwnProperty("msg")) {
              const parts = result.data.msg.split(".");
              setPopupMsg(
                parts.map((part, index) => (
                  <React.Fragment key={index}>
                    {part}
                    {index < parts.length - 1 && (
                      <>
                        .<br />
                      </>
                    )}
                  </React.Fragment>
                ))
              );
              //   setPopupMsg(result.data.msg);
            } else if (result.data.hasOwnProperty("message")) {
              setPopupMsg(result.data.message);
            } else {
              setPopupMsg("로그인 실패");
            }

            setShowAddPopup(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (!ID) {
      setPopupMsg("ID를 입력하세요.");
      setShowAddPopup(true);
    } else if (!PW) {
      setPopupMsg("비밀번호를 입력하세요.");
      setShowAddPopup(true);
    } else {
      setPopupMsg("로그인 실패");
      setShowAddPopup(true);
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      loginPost();
    }
  };

  return (
    <div className="login-container">
      <div className="logo">
        <Link to={"/"} className="login-image"/>
      </div>

      <div className="login-input">
        <Input
          type="text"
          placeholder="아이디"
          className="login-input-id"
          value={ID || ""}
          onChange={handleID}
          onKeyPress={handleOnKeyPress}
        />
        <Input
          type="password"
          placeholder="비밀번호"
          className="login-input-pw"
          onChange={handlePW}
          onKeyPress={handleOnKeyPress}
        />
      </div>

      <div className="login-checkbox">
        <Checkbox
          className="login-check save-id"
          checked={props.saveIdCheckbox}
          onChange={props.handleSaveIdCheckbox}
        />
        <p className="text">아이디 저장</p>
        <div className="space"></div>
        <Checkbox
          className="login-check auto-login"
          checked={props.autoLoginCheckbox}
          onChange={props.handleAutoLoginCheckbox}
        />
        <p className="text">자동 로그인</p>
      </div>

      {/* <button className="login-button" onClick={()=>{props.login();}}>로그인</button> */}
      <button className="login-button" onClick={loginPost}>
        로그인
      </button>

      <div className="login-option">
        <Link to={"/findid"} className="login-option-text">
          아이디 찾기
        </Link>
        {/* <div onClick={()=>{setPopupMsg('개발 중');setShowAddPopup(true);}} className="login-option-text">아이디 찾기</div> */}
        <div className="division"></div>
        <Link to={"/findpw"} className="login-option-text">
          비밀번호 찾기
        </Link>
        {/* <div onClick={()=>{setPopupMsg('개발 중');setShowAddPopup(true);}} className="login-option-text">비밀번호 찾기</div> */}
        <div className="division"></div>
        <Link to={"/signup"} className="login-option-text">
          회원가입
        </Link>
      </div>

      <Popup
        show={showAddPopup}
        onCancel={() => setShowAddPopup(false)}
        header="알림"
        icon={
          <i className="code x icon" onClick={() => setShowAddPopup(false)} />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button className="close_btn" onClick={() => setShowAddPopup(false)}>
            닫기
          </button>
        </div>
      </Popup>
    </div>
  );
}

export default Login;
