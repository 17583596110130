import './SideBar.css';
import config from '../../../config/config';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, Pagination, Checkbox, Tab, Button } from 'semantic-ui-react';

import Modal from '../uiElements/Modal';
import Popup from '../uiElements/Popup';
import FarmworkReqeust from '../../../routes/farmMng/components/farmworkRequest';
import FarmworkDelete from '../../../routes/farmMng/components/farmworkDelete';
import FarmworkSelect from '../../../routes/farmworkSelect/farmworkSelect';
import NewFarmworkRequest from '../../../routes/farmworkSelect/newFarmworkRequest';
import InstFarmwork from '../../../routes/instFarmwork/instFarmwork';
import InstRequestList from '../../../routes/farmworkSelect/instRequestList';
import InstFarmworkFail from '../../../routes/instFarmwork/instFarmworkFail';
import SvgIcon from '@mui/material/SvgIcon';
import ReactDragListView from 'react-drag-listview';

import Swipe from 'react-easy-swipe';

// AXIOS
import axios from 'axios';

import mapboxgl from 'mapbox-gl';

import c3 from 'c3';
import 'c3/c3.css';

import { FileInputButton } from '@files-ui/react';

import styled, { keyframes } from 'styled-components';

// TURF
import turf, { polygon } from 'turf';
import useResponsive from '../../hooks/screenSize-hook';
import SearchBar from '../searchBar/SearchBar';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s infinite linear;
`;

function SideBar(props) {
  const APIToken = localStorage.getItem('login-token');
  const userID = localStorage.getItem('user-id');
  const navigate = useNavigate();

  const [map, setMap] = useState();
  const [currentTestList, setCurrentTestList] = useState(props.curActiveLayer);

  const [farmworkDummyArr, setFarmworkDummyArr] = useState([]);

  useEffect(() => {
    if (props.mapRef.current) {
      setMap(props.mapRef.current.getMap());
    }
  }, [props.mapRef.current]);

  let Logchart = props.chartDIV.current;

  // 태블릿 일 때 보여지는 정보 수 변경
  const [pageSize, setPageSize] = useState(6);
  // 태블릿 높이일 때
  const { isTabletHeight, isTabletWidth } = useResponsive();
  const [searchRstHeight, setSearchRstHeight] = useState('auto');
  const [layerCtnHeight, setLayerCtnHeight] = useState('auto');

  // 다시 수정한 useEffect
  useEffect(() => {
    const calculateHeights = (height) => {
      if (height > 821) {
        return {
          searchRstHeight: 'calc(80% - 62px)',
          layerCtnHeight: '35%',
        };
      }
      if (height > 600) {
        return {
          searchRstHeight: 'calc(80% - 178px)',
          layerCtnHeight: '32%',
        };
      }
      return {
        searchRstHeight: 'auto',
        layerCtnHeight: 'auto',
      };
    };

    const windowHeight = window.innerHeight;
    const { searchRstHeight, layerCtnHeight } = calculateHeights(windowHeight);

    // ---------------------------------------------

    const windowWidth = window.innerWidth;
    // console.log(windowWidth);

    if (windowWidth < 1150) {
      props.setSidebarHiddenFlag(true);
      props.setSidebarHiddenFlag(true);
    }

    setSearchRstHeight(searchRstHeight);
    setLayerCtnHeight(layerCtnHeight);
  }, [isTabletWidth, isTabletHeight]);

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState();

  //주제도 태그
  const [themeMapTypeList, setThemeMapTypeList] = useState();
  const [showList, setShowList] = useState(false);
  const [selectID, setSelectID] = useState();
  const [selectTycd, setSelectTycd] = useState([]);

  // 사이드바 상태
  // const [mainHidden, setMainHidden] = useState(true);
  // const [subHidden, setSubHidden] = useState(false);
  // const [hidden, setHidden] = useState(2);

  // 검색어
  const [searchListWord, setSearchListWord] = useState('');

  // 드론 로그 여부
  // const [droneLog, setDroneLog] = useState({});
  // const [csvLog, setCsvLog] = useState({});
  // const [states, setStates] = useState({});
  // const [csvStates, setCsvStates] = useState({});

  // const [clickNum, setClickNum] = useState(null);

  // 농작업 삭제 리스트
  const [selectedFrmwrkId, setSelectedFrmwrkId] = useState([]);
  const [selectedMetaId, setSelectedMetaId] = useState([]);

  // 농작업 삭제 확인 모달
  const [showDeleteFarmworkModal, setShowDeleteFarmworkModal] = useState(false); // setShowModal

  // 농작업 요청(경작자) checkbox
  const [beforeSelectFarmwork, setBeforeSelectFarmwork] = useState(true);
  const [afterSelectFarmwork, setAfterSelectFarmwork] = useState(true);
  const [doneSelectFarmwork, setDoneSelectFarmwork] = useState(true);

  useEffect(() => {
    searchFarmworkSelectList();
  }, [beforeSelectFarmwork, afterSelectFarmwork, doneSelectFarmwork]);

  // 농작업 지원(기관) checkbox
  const [beforeSelect, setBeforeSelect] = useState(true);
  const [applySelectFarmwork, setApplySelectFarmwork] = useState(true);
  const [successSelectFarmwork, setSuccessSelectFarmwork] = useState(true);
  const [failSelectFarmwork, setFailSelectFarmwork] = useState(true);
  // 농작업 지원(기관) 작업완료 리스트
  const [farmworkDoneList, setFarmworkDoneList] = useState();
  // 농작업 지원(기관) tab index
  const [activeIndex, setActiveIndex] = useState(0);
  // 농작업 지원 결과 업로드 checkbox
  const [selectedFarmwork, setSelectedFarmwork] = useState();
  const [selectedProcId, setSelectedProcId] = useState();
  // 농작업 지원 결과 업로드 Modal
  const [farmworkUpload, setFarmworkUpload] = useState(false);
  // 지원 실패 결과 조회
  const [showFarmworkFail, setShowFarmworkFail] = useState(false);

  // 농작업 결과 업로드
  // 파일 관련 state************************************************
  const inputFile = useRef(null);
  const submitButton = useRef(null);
  const [files, setFiles] = React.useState([]);
  const [fileName, setFileName] = useState(null);
  const [beforeFileSelect, setBeforeFileSelect] = useState(true);
  const [fileLoading, setFileLoading] = useState(false);
  const [showFilePopup, setShowFilePopup] = useState(false);
  const [filePopupMsg, setFilePopupMsg] = useState();

  // 기타의견
  const [opinion, setOpinion] = useState();
  const handleOpinion = (e) => {
    setOpinion(e.target.value);
  };

  const cancel = () => {
    setFiles();
    setFileName();
    setBeforeFileSelect(true);
    props.setActChart(false);
  };

  const handleButton = (e) => {
    if (inputFile.current) {
      // console.log("inputFile.current", inputFile.current);
      inputFile.current.click();
      // 만약 파일 선택안하고 취소한다면??? 이벤트 추가해야함
    }
  };

  const fileChange = (e) => {
    if (e.target.value) {
      setBeforeFileSelect(false);
      setFiles(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } else {
      cancel();
    }
    e.target.value = '';
  };

  const handleSubmit = async (e) => {
    setFileLoading(true);

    e.preventDefault();

    const formData = new FormData();
    formData.append('farmSelected', 'farmSelected');
    formData.append('folderNum', selectedFarmwork);
    formData.append('procId', selectedProcId);
    formData.append('opinion', opinion);
    formData.append('file', files); // 'logFile'은 input의 name 속성 값

    const headers = {
      Authorization: `Bearer {${APIToken}}`,
    };

    axios.post('/api/droneLog/file/upload', formData, headers).then((res) => {
      // console.log("파일 업로드 결과", res);
      setFileLoading(false);
      if (res.data.result === 'success') {
        setFilePopupMsg('업로드가 완료되었습니다??');
        setShowFilePopup(true);
        props.setActChart(false);
        // cancel();
        setFiles();
        setFileName();
        setBeforeFileSelect(true);
        setSelectedFarmwork(null);
        setSelectedProcId(null);
        searchFarmworkListforInst();

        // props.setClickNum(null);
        // drawLog(folderNum);
        // setCurClickNum();

        // props.droneHandler(false);
        // props.setDrone(false);
        // setCurClickNum(folderNum);
        // props.setActChart(true);
      } else {
        // console.log("Error uploading file");
        setFilePopupMsg('업로드에 실패하였습니다. 다시 시도해주시기 바랍니다.');
        setShowFilePopup(true);
        cancel();

        // setCurClickNum();
        props.setActChart(false);
      }
    });
  };
  //*******************************************************************************

  const farmworkCheckboxHandler = (id) => {
    if (id.meta_frmwrk_id !== selectedFarmwork) {
      setSelectedFarmwork(null);
      setSelectedFarmwork(id.meta_frmwrk_id);
      setSelectedProcId(null);
      setSelectedProcId(id.frmwkt_proc_id);
    } else if (id.meta_frmwrk_id === selectedFarmwork) {
      setSelectedFarmwork(null);
      setSelectedProcId(null);
    }
  };

  const onClickFarmworkUpload = () => {
    if (selectedFarmwork !== undefined) {
      if (selectedFarmwork.length === 0) {
        setPopupMsg('선택된 농작업이 없습니다.');
        setShowPopup(true);
      } else {
        setFarmworkUpload(true);
      }
    } else {
      setPopupMsg('선택된 농작업이 없습니다.');
      setShowPopup(true);
    }
  };

  const farmworkDoneListHandler = (e) => {
    setFarmworkDoneList(e);
  };
  const handleTabChange = (e, { activeIndex }) => {
    setActiveIndex(activeIndex);
  };

  useEffect(() => {
    props.drnLogHandler({});
    props.csvLogHandler({});
  }, [props.modalDel]);

  useEffect(() => {
    searchFarmworkListforInst();
  }, [
    beforeSelect,
    applySelectFarmwork,
    successSelectFarmwork,
    failSelectFarmwork,
  ]);

  useEffect(() => {
    if (
      props.activeHeaderMenu === '/farmworkSelect' ||
      props.activeHeaderMenu === '/instFarmwork'
    ) {
      if (activeIndex === 0) {
        searchFarmworkListforInst();
      } else if (activeIndex === 1) {
        setTotalCount(0);
        // 작업 완료 리스트 불러오기
        searchFarmworkDoneList();
      }
    }
  }, [activeIndex]);

  //*************************************************************************************

  //로그데이터 뿌리는 fucn
  // const drnLogData = async (e) => {
  //   if (e !== undefined) {
  //     axios({
  //       method: 'get',
  //       url:
  //         `/api/droneLog/${e.log_sn}?` +
  //         `&itrstZoneId=${props.selectItrst.itrst_zone_id}`,
  //       headers: {
  //         Authorization: `Bearer {${APIToken}}`,
  //       },
  //     })
  //       .then(async (result) => {
  //         if (result.data.result == 'success') {
  //           // await props.setVisibleDrnLog(true);
  //           let res = result.data.data.result; //mode
  //           const missionResponse = result.data.data.cmd;

  //           let uniqueData = [];
  //           let seenSeconds = new Set();

  //           res.features.forEach((item) => {
  //             // time을 0.2초 단위로 변환
  //             const timeInSeconds = Math.floor(item.properties.time / 200);

  //             // 만약 이 시간이 아직 처리되지 않았다면
  //             if (!seenSeconds.has(timeInSeconds)) {
  //               // 이 시간을 처리된 것으로 기록하고
  //               seenSeconds.add(timeInSeconds);
  //               // 데이터를 결과 배열에 추가
  //               uniqueData.push(item);
  //             }
  //           });

  //           res.features = uniqueData;

  //           let autoArray = [];
  //           let manualArray = [];

  //           res.features.forEach(function (item) {
  //             if (item.properties.ModeNum == 5) {
  //               manualArray.push(item);
  //             } else {
  //               autoArray.push(item);
  //             }
  //           });

  //           await drawLogChart(res, result.data.result);
  //           await drawMissonLogLayer(
  //             missionResponse.features,
  //             'drnLog' + e.log_sn,
  //             res
  //           );
  //           // console.log("missionRes"+JSON.stringify(missionResponse.features));
  //           // drawMissionLayer(missionResponse.features, e.log_sn);

  //           if (autoArray && autoArray.length !== 0) {
  //             await drawAutoLogLayer(autoArray, 'drnLog' + e.log_sn, res);
  //             // drawAutoLayer(autoArray, e.log_sn);
  //           }
  //           if (manualArray && manualArray.length !== 0) {
  //             await drawManualLogLayer(manualArray, 'drnLog' + e.log_sn, res);
  //             // drawManualLayer(manualArray, e.log_sn);
  //           }
  //           // props.setDrone(true);
  //           // props.droneHandler(true);
  //         } else if (result.data.result == 'csv_data') {
  //           // await props.setVisibleDrnLog(true);
  //           let res = result.data.data.csvFile;

  //           let uniqueData = [];
  //           let seenSeconds = new Set();

  //           res.features.forEach((item) => {
  //             // time을 0.2초 단위로 변환
  //             const timeInSeconds = Math.floor(item.properties.time / 200);

  //             // 만약 이 시간이 아직 처리되지 않았다면
  //             if (!seenSeconds.has(timeInSeconds)) {
  //               // 이 시간을 처리된 것으로 기록하고
  //               seenSeconds.add(timeInSeconds);
  //               // 데이터를 결과 배열에 추가
  //               uniqueData.push(item);
  //             }
  //           });
  //           res.features = uniqueData;
  //           await drawLogChart(res, result.data.result);
  //           await drawCSVLogLayer(res.features, 'drnLog' + e.log_sn, res);
  //         } else {
  //           props.setVisibleDrnLog(true);
  //         }
  //       })
  //       .catch((e) => {
  //         // props.setWaypoint(false);
  //       });
  //     // showConsole("props.farmworkList.e", e);
  //     // setHidden(3);
  //     props.setSubHidden(true);
  //     let index = -1;
  //     props.activeLayerList &&
  //       props.activeLayerList.map((act_e, act_i) => {
  //         if (act_e.log_sn === e.log_sn) {
  //           index = act_i;
  //         }
  //       });
  //     if (index == -1) {
  //       let copyActiveLayerList = [];
  //       if (props.activeLayerList) {
  //         copyActiveLayerList = [...props.activeLayerList];
  //       }
  //       e.layerType = 5;
  //       copyActiveLayerList.unshift(e);
  //       props.activeLayerListHandler(copyActiveLayerList);
  //       props.curActiveLayerHandler(e);
  //     }
  //     if (props.isMobile) {
  //       props.setMobileActiveMenu(0);
  //       props.setMobileLayerMenu(true);
  //     }
  //   }
  // };

  // 활성화 레이어 수정 & 삭제 / 등록
  const prevListRef = useRef();

  useEffect(() => {
    if (!props.drnLogFileList) return;

    const findChanges = (prevList, currentList) => {
      if (!prevList) return currentList.map((item, index) => ({ index, item }));
      return currentList.reduce((changes, item, index) => {
        if (JSON.stringify(item) !== JSON.stringify(prevList[index])) {
          changes.push({ index, item });
        }
        return changes;
      }, []);
    };

    const prevTestList = prevListRef.current;
    const currentList = props.drnLogFileList;

    const changes = findChanges(prevTestList, currentList);

    let curKey = props.curActiveLayer?.log_sn;

    let updatedActiveList = Array.isArray(props.activeLayerList)
      ? [...props.activeLayerList]
      : [];

    //삭제가 아닌 경우 (상세보기 수정)
    if (changes.length > 0 && props.modalDel == 3) {
      changes.forEach((change) => {
        const newIndex = updatedActiveList.findIndex(
          (item) => item.log_sn === change.item.log_sn
        );
        if (newIndex !== -1) {
          updatedActiveList[newIndex] = { ...change.item, layerType: 5 };
        }
      });

      props.activeLayerListHandler(updatedActiveList);

      let curIndex = updatedActiveList.findIndex(
        (item) => item.log_sn === curKey
      );

      props.curActiveLayerHandler(updatedActiveList[curIndex]);
      // drnLogData(updatedActiveList[curIndex]);
      props.handleModalDelState(0);
      // props.setDrnLogFlag(false);
    } else if (changes.length >= 0 && props.modalDel == 1) {
      //모달 상세보기 삭제

      updatedActiveList = updatedActiveList.filter(
        (item) => !curKey.includes(item.log_sn)
      );

      props.activeLayerListHandler(updatedActiveList);
      props.curActiveLayerHandler();
      props.handleModalDelState(0);
    } else if (props.modalDel == 2) {
      let log = changes[0].item;
      // removeDrnLogLayer(log.log_sn);
      // props.handleModalDelState(0);
      // drnLogData(log);
    }

    prevListRef.current = currentTestList;
  }, [props.drnLogFileList]);

  useEffect(() => {
    if (props.activeLayerList) {
      if (props.activeLayerList.length === 0) {
        props.setSubHidden(false);
      }
    }
  }, [props.activeLayerList]);

  const panes = [
    {
      menuItem: {
        key: 'before',
        content: <div style={{ fontFamily: 'Noto Sans KR' }}>작업 전</div>,
      },
      render: () => (
        <>
          <div className="list-count">
            총 {totalCount}개의 지원 결과가 검색되었습니다.
          </div>
          {props.farmworkListforInst &&
            props.farmworkListforInst.map((e, i) => {
              return (
                i >= (currentPage - 1) * pageSize &&
                i < currentPage * pageSize && (
                  <>
                    <div
                      // key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderTop: '1px solid white',
                        minHeight: '80px',
                        padding: '3px 10px',
                      }}
                    >
                      <div>
                        <Checkbox
                          style={{ margin: '4px 2px' }}
                          checked={
                            selectedFarmwork === e.meta_frmwrk_id &&
                            selectedFarmwork !== null
                          }
                          onChange={() => farmworkCheckboxHandler(e)}
                          disabled={e.proc_status !== '6'}
                        />
                      </div>
                      <div
                        key={i}
                        className="search-results-item"
                        style={{ padding: '0', border: 'none' }}
                        onClick={async () => {
                          // 계획 경로 확인
                          await axios({
                            method: 'get',
                            url:
                              config.backend +
                              '/api/data/frmwrk/' +
                              e.meta_frmwrk_id +
                              '/' +
                              e.meta_frmwrk_id +
                              'spray_path.waypoints',
                          })
                            .then((response) => {
                              if (response.data.startsWith('QGC')) {
                                props.setWaypoint(true);
                              } else {
                                props.setWaypoint(false);
                              }
                            })
                            .catch((e) => {
                              props.setWaypoint(false);
                            });

                          if (
                            e.frmwkt_stts_cd === 2 &&
                            e.proc_status === '7' &&
                            e.frmwkt_wrkr_id === userID
                          ) {
                            {
                              setShowFarmworkFail(true);
                              props.curActiveLayerHandler(e);
                            }
                          } else if (
                            e.frmwkt_stts_cd === 2 &&
                            e.proc_status === '6' &&
                            e.frmwkt_wrkr_id === userID
                          ) {
                            await axios({
                              method: 'get',
                              url:
                                `/api/instFarmwork/getSpecifyInst?` +
                                `userId=${e.user_id}` +
                                `&zoneId=${e.itrst_zone_id}` +
                                `&frmwkt_req_id=${e.frmwkt_req_id}`,
                              headers: {
                                Authorization: `Bearer {${APIToken}}`,
                              },
                            })
                              .then((result) => {
                                if (result.data.data.length > 0) {
                                  e.specifyInst = result.data.data[0].inst_nm;
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                            props.setSubHidden(false);
                            e.layerType = 6;
                            let originDate = new Date(e.frmwkt_req_dt);

                            const TIME_ZONE = 9 * 60 * 60 * 1000; // 9시간
                            let editDate = new Date(
                              originDate.getTime() + TIME_ZONE
                            )
                              .toISOString()
                              .replace('T', ' ')
                              .slice(0, -5);

                            // console.log("editDate", editDate);
                            e.koreanTime = editDate;
                            props.curActiveLayerHandler(e);
                          } else if (
                            e.frmwkt_stts_cd === 1 &&
                            e.proc_status === null
                          ) {
                            props.curActiveLayerHandler(e);
                            props.setShowRegistInstModal(true);
                            // console.log("e", e);
                          }
                        }}
                      >
                        <div className="contents-row">
                          <div className="item-date">필지 : {e.fmap_innb}</div>
                        </div>
                        <div className="item-date">
                          작업 요청일 : {e.frmwkt_ymd}
                        </div>
                        <div className="item-date">
                          대상 작물 : {e.trgt_crps}
                        </div>
                        <div className="contents-row">
                          <div className="item-crop">
                            농작업 종류 :{' '}
                            {e.frmwkt_ty_cd == 'lp'
                              ? '액체 방제'
                              : e.frmwkt_ty_cd == 'sp'
                              ? '고체 방제'
                              : e.frmwkt_ty_cd == 'lf'
                              ? '액체 비료'
                              : e.frmwkt_ty_cd == 'sf'
                              ? '고체 비료'
                              : e.frmwkt_ty_cd == 'so'
                              ? '파종'
                              : null}
                          </div>
                        </div>
                        <div className="contents-row"></div>
                      </div>

                      {e.frmwkt_stts_cd === 1 && e.proc_status === null ? (
                        <Button
                          style={{
                            position: 'absolute',
                            right: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'Noto Sans KR',
                            fontSize: '14px',
                            width: '100px',
                            height: '25px',
                            backgroundColor: 'white',
                            border: '1px solid #999999',
                            borderRadius: '6px',
                            padding: 0,
                          }}
                          onClick={() => {
                            // props.curActiveLayerHandler(e);
                            // props.setShowRegistInstModal(true);
                            // console.log("e", e);
                          }}
                        >
                          기관 선정 전
                        </Button>
                      ) : e.frmwkt_stts_cd === 1 &&
                        e.proc_status === '5' &&
                        e.frmwkt_wrkr_id === userID ? (
                        <Button
                          style={{
                            position: 'absolute',
                            right: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'Noto Sans KR',
                            fontSize: '14px',
                            minWidth: '100px',
                            height: '25px',
                            backgroundColor: 'white',
                            border: '1px solid #999999',
                            borderRadius: '6px',
                            color: '#2987cd',
                          }}
                        >
                          지원 완료
                        </Button>
                      ) : e.frmwkt_stts_cd === 2 &&
                        e.proc_status === '6' &&
                        e.frmwkt_wrkr_id === userID ? (
                        <Button
                          style={{
                            position: 'absolute',
                            right: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'Noto Sans KR',
                            fontSize: '14px',
                            minWidth: '100px',
                            height: '25px',
                            backgroundColor: 'white',
                            border: '1px solid #999999',
                            borderRadius: '6px',
                            color: '#2DB84B',
                          }}
                        >
                          지원 성공
                        </Button>
                      ) : e.frmwkt_stts_cd === 2 &&
                        e.proc_status === '7' &&
                        e.frmwkt_wrkr_id === userID ? (
                        <Button
                          style={{
                            position: 'absolute',
                            right: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'Noto Sans KR',
                            fontSize: '14px',
                            minWidth: '100px',
                            height: '25px',
                            backgroundColor: 'white',
                            border: '1px solid #999999',
                            borderRadius: '6px',
                            color: '#CC2029',
                          }}
                        >
                          지원 실패
                        </Button>
                      ) : e.frmwkt_stts_cd === 4 &&
                        e.proc_status === '4' &&
                        e.frmwkt_wrkr_id === userID ? (
                        <Button
                          style={{
                            position: 'absolute',
                            right: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'Noto Sans KR',
                            fontSize: '14px',
                            minWidth: '100px',
                            height: '25px',
                            backgroundColor: '#17375E',
                            color: 'white',
                            // border: "1px solid #999999",
                            borderRadius: '6px',
                          }}
                        >
                          작업 완료
                        </Button>
                      ) : null}
                    </div>
                  </>
                )
              );
            })}
        </>
      ),
    },
    {
      menuItem: {
        content: <div style={{ fontFamily: 'Noto Sans KR' }}>작업 완료</div>,
      },
      render: () => (
        <>
          <div className="list-count">
            총 {totalCount}개의 작업 완료된 리스트가 검색되었습니다.
          </div>
          {farmworkDoneList &&
            farmworkDoneList.map((e, i) => {
              return (
                i >= (currentPage - 1) * pageSize &&
                i < currentPage * pageSize && (
                  <>
                    <div
                      // key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderTop: '1px solid white',
                        minHeight: '80px',
                        padding: '3px 10px',
                      }}
                    >
                      <div
                        key={i}
                        className="search-results-item"
                        style={{ padding: '0', border: 'none' }}
                        onClick={async () => {
                          // 계획 경로 확인
                          await axios({
                            method: 'get',
                            url:
                              config.backend +
                              '/api/data/frmwrk/' +
                              e.meta_frmwrk_id +
                              '/' +
                              e.meta_frmwrk_id +
                              'spray_path.waypoints',
                          })
                            .then((response) => {
                              if (response.data.startsWith('QGC')) {
                                props.setWaypoint(true);
                              } else {
                                props.setWaypoint(false);
                              }
                            })
                            .catch((e) => {
                              props.setWaypoint(false);
                            });

                          // 수행 업체, 고유식별자 확인
                          await axios({
                            method: 'get',
                            url:
                              `/api/instFarmwork/getDoneInst?` +
                              `userId=${e.user_id}` +
                              `&zoneId=${e.itrst_zone_id}` +
                              `&frmwkt_req_id=${e.frmwkt_req_id}`,
                            headers: {
                              Authorization: `Bearer {${APIToken}}`,
                            },
                          })
                            .then((result) => {
                              if (result.data.data) {
                                e.specifyInst = result.data.data.inst_nm;
                                e.uid = result.data.data.uid;
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                          // showConsole("props.farmworkList.e", e);

                          // setHidden(3);
                          props.setSubHidden(false);
                          let index = -1;
                          e.layerType = 6;
                          if (e.frmwkt_stts_cd === 4 && e.geoJson) {
                            props.setSubHidden(true);
                            props.activeLayerList &&
                              props.activeLayerList.map((act_e, act_i) => {
                                if (act_e.frmwkt_req_id === e.frmwkt_req_id) {
                                  index = act_i;
                                }
                              });
                            if (index == -1) {
                              let copyActiveLayerList = [];
                              if (props.activeLayerList) {
                                copyActiveLayerList = [
                                  ...props.activeLayerList,
                                ];
                              }
                              copyActiveLayerList.unshift(e);
                              props.activeLayerListHandler(copyActiveLayerList);
                            }
                          }
                          let originDate = new Date(e.frmwkt_req_dt);

                          const TIME_ZONE = 9 * 60 * 60 * 1000; // 9시간
                          let editDate = new Date(
                            originDate.getTime() + TIME_ZONE
                          )
                            .toISOString()
                            .replace('T', ' ')
                            .slice(0, -5);

                          // console.log("editDate", editDate);
                          e.koreanTime = editDate;
                          props.curActiveLayerHandler(e);
                          // await drawLayer(311);
                        }}
                      >
                        <div className="contents-row">
                          <div className="item-date">필지 : {e.fmap_innb}</div>
                        </div>
                        <div className="item-date">
                          작업 요청일 : {e.frmwkt_ymd}
                        </div>
                        <div className="item-date">
                          대상 작물 : {e.trgt_crps}
                        </div>
                        <div className="contents-row">
                          <div className="item-crop">
                            농작업 종류 :{' '}
                            {e.frmwkt_ty_cd == 'lp'
                              ? '액체 방제'
                              : e.frmwkt_ty_cd == 'sp'
                              ? '고체 방제'
                              : e.frmwkt_ty_cd == 'lf'
                              ? '액체 비료'
                              : e.frmwkt_ty_cd == 'sf'
                              ? '고체 비료'
                              : e.frmwkt_ty_cd == 'so'
                              ? '파종'
                              : null}
                          </div>
                        </div>
                        <div className="contents-row"></div>
                      </div>

                      {e.frmwkt_stts_cd === 4 && e.proc_status === '4' ? (
                        <Button
                          style={{
                            position: 'absolute',
                            right: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'Noto Sans KR',
                            fontSize: '14px',
                            minWidth: '100px',
                            height: '25px',
                            backgroundColor: '#17375E',
                            color: 'white',
                            // border: "1px solid #999999",
                            borderRadius: '6px',
                          }}
                        >
                          작업 완료
                        </Button>
                      ) : null}
                    </div>
                  </>
                )
              );
            })}
        </>
      ),
    },
  ];

  const handleCheckboxChange = (id) => {
    if (selectedFrmwrkId.includes(id.frmwkt_req_id)) {
      setSelectedFrmwrkId(
        selectedFrmwrkId.filter((item) => item !== id.frmwkt_req_id)
      );
      setSelectedMetaId(selectedMetaId.filter((i) => i !== id.meta_frmwrk_id));
    } else {
      setSelectedFrmwrkId([...selectedFrmwrkId, id.frmwkt_req_id]);
      setSelectedMetaId([...selectedMetaId, id.meta_frmwrk_id]);
    }
  };

  const onClickDeleteBtn = () => {
    if (selectedFrmwrkId.length == 0) {
      setPopupMsg('선택된 농작업이 없습니다.');
      setShowPopup(true);
    } else {
      setShowDeleteFarmworkModal(true);
    }
  };

  useEffect(() => {
    setSelectedFrmwrkId([]);
    setSelectedMetaId([]);
  }, [props.activeHeaderMenu]);

  useEffect(() => {
    if (props.activeLayerList != undefined) {
      if (props.activeLayerList.length == 0) {
        const mapLayers = map.getStyle().layers;
        mapLayers.forEach((layer) => {
          let changeName = layer.id.replace('Layer', 'Source');
          if (layer.id.startsWith('missionLayer')) {
            map.removeLayer(layer.id);
            map.removeSource(changeName);
            map.removeSource(layer.source);
          }
          if (layer.id.startsWith('autoLayer')) {
            map.removeLayer(layer.id);
            map.removeSource(changeName);
            map.removeSource(layer.source);
          }
          if (layer.id.startsWith('manualLayer')) {
            map.removeLayer(layer.id);
            map.removeSource(changeName);
            map.removeSource(layer.source);
          }
          if (layer.id.startsWith('csvLayer')) {
            map.removeLayer(layer.id);
            map.removeSource(changeName);
            map.removeSource(layer.source);
          }
        });
      }
    }
  }, [props.activeLayerList]);

  const removeAllLayer = async () => {
    if (map !== undefined && map !== null) {
      const mapLayers = await map.getStyle().layers;
      await mapLayers.forEach((layer) => {
        let changeName = layer.id.replace('Layer', 'Source');
        if (layer.id.startsWith('missionLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('autoLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('manualLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('csvLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
      });
    }
  };

  const removeLayer = (id) => {
    let changeName = id.replace('Layer', 'Source');
    map.removeLayer(id);
    map.removeSource(id);
    map.removeSource(changeName);
  };

  const handleClick = (id) => {
    // 클릭된 요소의 ID를 기반으로 상태를 업데이트
    // 상태가 존재하지 않으면 true로 설정하고, 존재하면 현재 값의 반대로 설정
    props.setStates((prevStates) => ({
      ...prevStates,
      // [id]: !prevStates[id],
      [id]: prevStates[id] === true ? false : true,
    }));
  };

  const csvHandleClick = (id) => {
    props.setCsvStates((prevStates) => ({
      ...prevStates,
      [id]: prevStates[id] === true ? false : true,
    }));
  };

  const csvLayerOnOff = (layerName) => {
    const existingLayer = map.getLayer(layerName);

    if (existingLayer) {
      if (
        !props.csvStates.hasOwnProperty(layerName) ||
        props.csvStates[layerName] === false
      ) {
        map.setLayoutProperty(layerName, 'visibility', 'none');
      } else {
        map.setLayoutProperty(layerName, 'visibility', 'visible');
      }
    }
  };

  const layerOnOff = (layerName) => {
    const existingLayer = map.getLayer(layerName);

    if (existingLayer) {
      // const visibility = map.getLayoutProperty(layerName, "visibility");

      if (
        !props.states.hasOwnProperty(layerName) ||
        props.states[layerName] === false
      ) {
        map.setLayoutProperty(layerName, 'visibility', 'none');
      } else {
        map.setLayoutProperty(layerName, 'visibility', 'visible');
      }
    }
  };

  //드론로그업로드로그
  const drawAutoLogLayer = async (res, clickNum) => {
    const isLayer = map.getLayer('autoLayer' + clickNum);

    if (isLayer) {
      removeLayer('autoLayer' + clickNum);
    }

    // Source 생성
    const autoSource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: res,
      },
    };

    // Layer 생성
    const autoLayer = {
      id: 'autoLayer' + clickNum,
      source: autoSource,
      type: 'circle',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-radius': 4,
        'circle-color': 'blue',
      },
    };

    // console.log(
    //   "props.curActiveLayer.frmwkt_req_id",
    //   props.curActiveLayer.frmwkt_req_id
    // );
    // Source 및 Layer를 지도에 추가
    map.addSource('autoSource' + clickNum, autoSource);
    map.addLayer(autoLayer);
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on('mouseenter', 'autoLayer' + clickNum, (e) => {
      map.getCanvas().style.cursor = 'pointer';

      let selectedFeature = e.features[0];
      let match_time = selectedFeature.properties.time;
      let match_data = null;

      for (let data of res) {
        if (match_time == data.properties['time']) {
          match_data = data;
          break;
        }
      }

      if (match_data) {
        let coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let dt = new Date(e.features[0].properties.time);
        const date =
          dt.getFullYear() +
          '-' +
          ('0' + (dt.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + dt.getDate()).slice(-2);

        const time =
          ('0' + dt.getHours()).slice(-2) +
          ':' +
          ('0' + dt.getMinutes()).slice(-2) +
          ':' +
          ('0' + dt.getSeconds()).slice(-2) +
          '.' +
          ('00' + dt.getMilliseconds()).slice(-3);

        // 팝업에 들어갈 content
        let content =
          "<table style='border:1px solid #000; font-family:Noto Sans KR;font-weight: 600;'>" +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>날짜</td><td style='border:1px solid #000; padding:5px 8px 5px 8px; text-align: left;'>" +
          date +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>시간</td><td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          time +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>경도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lng.toFixed(7) +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>위도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lat.toFixed(7) +
          '</td></tr>' +
          '</table>';

        coordinates = e.lngLat;
        popup.setLngLat(coordinates).setHTML(content).addTo(map);

        // point에 mouseenter 되었을 때, 차트 확대 하는 부분
        Logchart.zoom([
          e.features[0].properties.time - 15000,
          e.features[0].properties.time + 15000,
        ]);
        Logchart.tooltip.show({ x: e.features[0].properties.time });
      }
    });

    // point에 mouseleave 되었을 때, 차트 확대 해제 및 팝업 제거
    map.on('mouseleave', 'autoLayer' + clickNum, () => {
      map.getCanvas().style.cursor = '';
      Logchart.tooltip.show(false);
      Logchart.internal.hideTooltip();
      popup.remove();
      Logchart.unzoom();
      Logchart.flush(); // 차트 갱신
    });
  };

  const drawManualLogLayer = async (res, clickNum, origin) => {
    const isLayer = map.getLayer('manualLayer' + clickNum);
    if (isLayer) {
      removeLayer('manualLayer' + clickNum);
    }

    // Source 생성
    const manualSource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: res,
      },
    };

    // Layer 생성
    const manualLayer = {
      id: 'manualLayer' + clickNum,
      source: manualSource,
      type: 'circle',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-radius': 4,
        'circle-color': 'red',
      },
    };

    // Source 및 Layer를 지도에 추가
    map.addSource('manualSource' + clickNum, manualSource);
    map.addLayer(manualLayer, null);

    // await map.setCenter(res[res.length / 2].geometry.coordinates);
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on('mouseenter', 'manualLayer' + clickNum, (e) => {
      map.getCanvas().style.cursor = 'pointer';

      let selectedFeature = e.features[0];
      let match_time = selectedFeature.properties.time;
      let match_data = null;

      for (let data of res) {
        if (match_time == data.properties['time']) {
          match_data = data;
          break;
        }
      }

      if (match_data) {
        let coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let dt = new Date(e.features[0].properties.time);
        const date =
          dt.getFullYear() +
          '-' +
          ('0' + (dt.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + dt.getDate()).slice(-2);

        const time =
          ('0' + dt.getHours()).slice(-2) +
          ':' +
          ('0' + dt.getMinutes()).slice(-2) +
          ':' +
          ('0' + dt.getSeconds()).slice(-2) +
          '.' +
          ('00' + dt.getMilliseconds()).slice(-3);

        // 팝업에 들어갈 content
        let content =
          "<table style='border:1px solid #000; font-family:Noto Sans KR;font-weight: 600;'>" +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>날짜</td><td style='border:1px solid #000; padding:5px 8px 5px 8px; text-align: left;'>" +
          date +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>시간</td><td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          time +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>경도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lng.toFixed(7) +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>위도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lat.toFixed(7) +
          '</td></tr>' +
          '</table>';

        coordinates = e.lngLat;
        popup.setLngLat(coordinates).setHTML(content).addTo(map);

        // point에 mouseenter 되었을 때, 차트 확대 하는 부분
        Logchart.zoom([
          e.features[0].properties.time - 15000,
          e.features[0].properties.time + 15000,
        ]);
        Logchart.tooltip.show({ x: e.features[0].properties.time });
      }
    });

    // point에 mouseleave 되었을 때, 차트 확대 해제 및 팝업 제거
    map.on('mouseleave', 'manualLayer' + clickNum, () => {
      map.getCanvas().style.cursor = '';
      Logchart.tooltip.show(false);
      Logchart.internal.hideTooltip();
      popup.remove();
      Logchart.unzoom();
      Logchart.flush(); // 차트 갱신
    });
  };

  const drawMissonLogLayer = async (res, clickNum, origin) => {
    props.clickDrnLogLayerFeaHandler(res);
    const isLayer = map.getLayer('missionLayer' + clickNum);
    if (isLayer) {
      removeLayer('missionLayer' + clickNum);
    }

    let array = [];
    res.forEach((item) => {
      let point = new mapboxgl.LngLat(
        item.geometry.coordinates[0],
        item.geometry.coordinates[1]
      );
      array.push(point);
    });

    // Source 생성
    const missionSource = {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: array.map((coord) => [coord.lng, coord.lat]),
        },
      },
    };

    // Layer 생성
    const missionLayer = {
      id: 'missionLayer' + clickNum,
      source: missionSource,
      type: 'line',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': '#46ce3c',
        'line-width': 2,
      },
    };

    let id = 'missionSource' + clickNum;

    // Source 및 Layer를 지도에 추가
    map.addSource(id, missionSource);
    map.addLayer(missionLayer);

    props.setDroneLog((prevStates) => ({
      ...prevStates,
      // [clickNum]: !prevStates[clickNum],
      [clickNum]: prevStates.hasOwnProperty(clickNum)
        ? prevStates[clickNum]
        : true,
    }));

    // await map.setCenter(res[res.length / 2].geometry.coordinates);
    const bbox = turf.bbox(origin);
    map.fitBounds(bbox, {
      padding: 100,
      animate: false,
    });
  };

  const drawCSVLogLayer = async (res, clickNum, origin) => {
    props.clickDrnLogLayerFeaHandler(res);
    const isLayer = map.getLayer('csvLayer' + clickNum);

    if (isLayer) {
      removeLayer('csvLayer' + clickNum);
    }
    // Source 생성
    const csvSource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: res,
      },
    };

    // Layer 생성
    const csvLayer = {
      id: 'csvLayer' + clickNum,
      source: csvSource,
      type: 'circle',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-radius': 4,
        'circle-color': 'blue',
      },
    };

    let id = 'csvSource' + clickNum;
    // Source 및 Layer를 지도에 추가
    map.addSource(id, csvSource);
    map.addLayer(csvLayer);

    props.setCsvLog((prevStates) => ({
      ...prevStates,
      // [clickNum]: prevStates.hasOwnProperty(clickNum) ? prevStates[id] : true,
      [clickNum]: prevStates[clickNum] === true ? true : true,
    }));

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on('mouseenter', 'csvLayer' + clickNum, (e) => {
      map.getCanvas().style.cursor = 'pointer';

      let selectedFeature = e.features[0];
      let match_time = selectedFeature.properties.time;
      let match_data = null;

      for (let data of res) {
        if (match_time == data.properties['time']) {
          match_data = data;
          break;
        }
      }
      if (match_data) {
        let coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let dt = new Date(e.features[0].properties.time);
        const date =
          dt.getFullYear() +
          '-' +
          ('0' + (dt.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + dt.getDate()).slice(-2);

        const time =
          ('0' + dt.getHours()).slice(-2) +
          ':' +
          ('0' + dt.getMinutes()).slice(-2) +
          ':' +
          ('0' + dt.getSeconds()).slice(-2) +
          '.' +
          ('00' + dt.getMilliseconds()).slice(-3);

        // 팝업에 들어갈 content
        let content =
          "<table style='border:1px solid #000; font-family:Noto Sans KR;font-weight: 600;'>" +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>날짜</td><td style='border:1px solid #000; padding:5px 8px 5px 8px; text-align: left;'>" +
          date +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>시간</td><td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          time +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>경도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lng.toFixed(7) +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>위도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lat.toFixed(7) +
          '</td></tr>' +
          '</table>';

        coordinates = e.lngLat;
        popup.setLngLat(coordinates).setHTML(content).addTo(map);

        // point에 mouseenter 되었을 때, 차트 확대 하는 부분
        Logchart.zoom([
          e.features[0].properties.time - 15000,
          e.features[0].properties.time + 15000,
        ]);
        Logchart.tooltip.show({ x: e.features[0].properties.time });
      }
    });

    // point에 mouseleave 되었을 때, 차트 확대 해제 및 팝업 제거
    map.on('mouseleave', 'csvLayer' + clickNum, () => {
      map.getCanvas().style.cursor = '';
      Logchart.tooltip.show(false);
      Logchart.internal.hideTooltip();
      popup.remove();
      Logchart.unzoom();
      Logchart.flush(); // 차트 갱신
    });

    const bbox = turf.bbox(origin);
    map.fitBounds(bbox, {
      padding: 100,
      animate: false,
    });
  };

  //드론로그업로드차트
  const drawLogChart = async (res, type) => {
    let bat = [];
    let alt = [];
    let amount = [];
    let date_time = [];

    if (type == 'csv_data') {
      await res.features.forEach((item) => {
        alt.push(item.properties['altitude']);
        date_time.push(item.properties['time']);
      });

      Logchart = await c3.generate({
        bindto: '#Logchart',
        data: {
          x: 'x',
          columns: [
            ['x', ...date_time],
            ['고도', ...alt],
          ],
          type: 'line',
          selection: {
            enabled: true,
          },
        },
        axis: {
          x: {
            label: {
              text: '시간(hh:mm:ss:ms)',
              position: 'outer-center',
            },
            type: 'timeseries',
            categories: date_time,
            tick: {
              format: '%H:%M:%S.%L',
              fit: true,
              count: 4,
              // culling: false,
            },
          },
        },
        zoom: {
          enabled: true,
        },
        point: {
          // show: true,
          focus: {
            expand: {
              enabled: true,
              r: 6, // 확대될 때의 포인트 반지름
            },
          },
        },
        tooltip: {
          horizontal: true,
        },
        padding: {
          right: 40,
          top: 10,
        },
      });
    } else {
      await res.features.forEach((item) => {
        bat.push(item.properties['Volt']);
        alt.push(item.properties['altitude']);
        amount.push(item.properties['C9']);
        date_time.push(item.properties['time']);
      });

      // C3 차트 생성
      Logchart = await c3.generate({
        bindto: '#Logchart',
        data: {
          x: 'x',
          columns: [
            ['x', ...date_time],
            ['살포량', ...amount],
            ['배터리 잔량', ...bat],
            ['고도', ...alt],
          ],
          type: 'line',
          selection: {
            enabled: true,
          },
        },
        axis: {
          x: {
            label: {
              text: '시간(hh:mm:ss:ms)',
              position: 'outer-center',
            },
            type: 'timeseries',
            categories: date_time,
            tick: {
              format: '%H:%M:%S.%L',
              fit: true,
              count: 4,
              // culling: false,
            },
          },
          y: {
            label: {
              text: 'Y축 살포량',
              position: 'outer-middle',
            },
            tick: {
              culling: false, // 글자가 겹치지 않도록 설정
            },
          },
        },
        zoom: {
          enabled: true,
        },
        point: {
          // show: true,
          focus: {
            expand: {
              enabled: true,
              r: 6, // 확대될 때의 포인트 반지름
            },
          },
        },
        tooltip: {
          horizontal: true,
        },
        padding: {
          right: 40,
          top: 10,
        },
        // size,
      });
    }
    // componentWillUnmount 시에 차트 파기
    return () => {
      Logchart.destroy();
    };
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `api/theme-map/type/list`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        // console.log("result : ", result);
        let themeType_Arr = [];
        result.data.data.map((e, i) => {
          themeType_Arr.push({
            key: i,
            text: e.cd_expln,
            value: e.cd_vl,
          });
        });
        setThemeMapTypeList(themeType_Arr);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCheck = (e, data) => {
    const dummyArr = [...selectTycd];

    if (data.checked == true) {
      dummyArr.push(data.value);
    } else if (data.checked == false) {
      let index = -1;
      dummyArr.map((d, i) => {
        if (d == data.value) index = i;
      });
      dummyArr.splice(index, 1);
    }

    setSelectTycd(dummyArr);
  };

  function themeTypeReg() {
    let selectTycd_str = '0';

    if (selectTycd.length !== 0) {
      selectTycd_str = selectTycd.toString();
    }

    let themeData = {
      themeId: selectID,
      themeTy: selectTycd_str,
    };

    axios({
      method: 'post',
      url: `/api/theme-map/update-type`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: themeData,
    })
      .then((result) => {
        setShowList(false);
        setSelectID();
        setSelectTycd([]);
        axios({
          method: 'get',
          url: `/api/theme-map/list?zoneId=${props.selectItrst.itrst_zone_id}&perPage=0`,
          headers: {
            Authorization: `Bearer {${APIToken}}`,
          },
        })
          .then((result) => {
            props.themeMapListHandler(result.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (totalPages == 1) {
      setCurrentPage(1);
    }
    paginationComponent();
  }, [totalPages]);

  const paginationComponent = () => {
    return !props.isMobile ? (
      <div className="pagination-container">
        <Pagination
          className="pagination"
          // activePage={currentPage}
          totalPages={totalPages}
          ellipsisItem={{
            key: 'ellipsisItem-1',
            content: <Icon name="ellipsis horizontal" />,
            icon: true,
          }}
          firstItem={{
            content: <Icon name="angle double left" />,
            icon: true,
          }}
          lastItem={{
            content: <Icon name="angle double right" />,
            icon: true,
          }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          onPageChange={handlePagination}
        />
      </div>
    ) : null;
  };

  const handleSearchListWord = (e) => {
    setSearchListWord(e.target.value);
  };

  const handlePagination = (e, data) => {
    setCurrentPage(data.activePage);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchList();
    }
  };

  const onClearSearch = () => {
    setSearchListWord('');
  };

  useEffect(() => {
    setCurrentPage(1);
    setSearchListWord('');
    props.setSearchFlag(true);
  }, [
    props.selectItrst,
    props.showImage,
    props.showTheme,
    props.showFarmwork,
    props.showDrnLog,
    props.showFarmworkSelect,
    props.showInstFarmwork,
  ]);

  useEffect(() => {
    if (props.searchFlag) {
      searchList();
    }
  }, [props.searchFlag]);

  function searchList() {
    if (props.activeHeaderMenu == '/image') {
      searchImageRegList();
    } else if (props.activeHeaderMenu == '/theme') {
      searchThemeList();
    } else if (props.activeHeaderMenu == '/farmwork') {
      searchFarmworkList();
      // searchDroneList();
    } else if (props.activeHeaderMenu == '/drnLog') {
      searchDrnLogList();
    } else if (props.activeHeaderMenu == '/farmworkSelect') {
      searchFarmworkSelectList();
    } else if (props.activeHeaderMenu == '/instFarmwork') {
      searchFarmworkListforInst();
      searchFarmworkDoneList();
    }
    props.setSearchFlag(false);
  }

  useEffect(() => {
    if (props.listReturnFlag) {
      searchImageRegList();
      props.listReturnFlagHandler(false);
    }
  }, [props.listReturnFlag]);

  const searchThemeList = () => {
    if (props.selectItrst && props.showTheme) {
      axios({
        method: 'get',
        url:
          `/api/theme-map/list?` +
          `key=${searchListWord}` +
          `&zoneId=${props.selectItrst.itrst_zone_id}` +
          `&perPage=0`,
        // + `&page=${currentPage}`
        // + `&perPage=${pageSize}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            props.themeMapListHandler(result.data.data);
            setTotalCount(result.data.data.length);
          } else {
            props.themeMapListHandler();
            setTotalCount(0);
            setTotalPages(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const searchImageRegList = () => {
    if (props.selectItrst) {
      axios({
        method: 'get',
        url:
          `/api/drone/img/info?` +
          `keyword=${searchListWord}` +
          `&itrstZoneId=${props.selectItrst.itrst_zone_id}`,
        // + `&page=${currentPage}`
        // + `&perPage=${pageSize}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            props.droneImageListHandler(result.data.data);
            setTotalCount(result.data.data.length);
          } else {
            props.droneImageListHandler();
            setTotalCount(0);
            setTotalPages(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const searchFarmworkList = () => {
    if (props.selectItrst && props.showFarmwork) {
      axios({
        method: 'get',
        url:
          // `http://localhost:3005` +
          `/api/farmwork/list?` +
          `zoneId=${props.selectItrst.itrst_zone_id}` +
          (searchListWord ? `&frmwrkAddr=${searchListWord}` : ''),
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            props.farmworkListHandler(result.data.data);
            setTotalCount(result.data.data.length);
          } else {
            props.farmworkListHandler();
            setTotalCount(0);
            setTotalPages(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const searchDrnLogList = () => {
    if (props.selectItrst && props.selectItrst.itrst_zone_id !== 'undefined') {
      axios({
        method: 'get',
        url:
          `/api/droneLog/list?` +
          `keyword=${searchListWord}` +
          `&itrstZoneId=${props.selectItrst.itrst_zone_id}`,
        // + `&page=${currentPage}`
        // + `&perPage=${pageSize}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            props.drnLogFileListHandler(result.data.data);
            setTotalCount(result.data.data.length);
          } else {
            props.drnLogFileListHandler();
            setTotalCount(0);
            setTotalPages(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const searchFarmworkSelectList = () => {
    if (props.selectItrst && props.showFarmworkSelect) {
      axios({
        method: 'get',
        url:
          `/api/farmworkSelect/list?` +
          `zoneId=${props.selectItrst.itrst_zone_id}` +
          `&beforeSelectFarmwork=${beforeSelectFarmwork}` +
          `&afterSelectFarmwork=${afterSelectFarmwork}` +
          `&doneSelectFarmwork=${doneSelectFarmwork}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            props.farmworkSelectListHandler(result.data.data);
            setTotalCount(result.data.data.length);
          } else {
            props.farmworkSelectListHandler();
            setTotalCount(0);
            setTotalPages(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const searchFarmworkListforInst = () => {
    if (props.selectItrst && props.showInstFarmwork) {
      if (
        !beforeSelect &&
        !applySelectFarmwork &&
        !successSelectFarmwork &&
        !failSelectFarmwork
      ) {
        props.farmworkListforInstHandler();
        setTotalCount(0);
        setTotalPages(0);
      } else {
        axios({
          method: 'get',
          url:
            `/api/instFarmwork/list?` +
            `zoneId=${props.selectItrst.itrst_zone_id}` +
            `&beforeSelect=${beforeSelect}` +
            `&applySelectFarmwork=${applySelectFarmwork}` +
            `&successSelectFarmwork=${successSelectFarmwork}` +
            `&failSelectFarmwork=${failSelectFarmwork}`,
          headers: {
            Authorization: `Bearer {${APIToken}}`,
          },
        })
          .then((result) => {
            // console.log("농작업 작업 전 리스트", result);
            if (result.data.success) {
              props.farmworkListforInstHandler(result.data.data);
              setTotalCount(result.data.data.length);
            } else {
              props.farmworkListforInstHandler();
              setTotalCount(0);
              setTotalPages(0);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const searchFarmworkDoneList = () => {
    if (props.selectItrst) {
      axios({
        method: 'get',
        url:
          `/api/instFarmwork/doneList?` +
          `zoneId=${props.selectItrst.itrst_zone_id}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          // console.log("농작업 작업 완료 리스트", result);
          if (result.data.success) {
            farmworkDoneListHandler(result.data.data);
            setTotalCount(result.data.data.length);
          } else {
            farmworkDoneListHandler();
            setTotalCount(0);
            setTotalPages(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (props.addCheckDrn) {
      searchImageRegList();
      props.setAddCheckDrn(false);
    }
  }, [props.addCheckDrn]);

  useEffect(() => {
    if (props.addCheckTheme) {
      searchThemeList();
      props.setAddCheckTheme(false);
    }
  }, [props.addCheckTheme]);

  useEffect(() => {
    if (props.addCheckDrnLog) {
    searchDrnLogList();
    props.setAddCheckDrnLog(false);
    }
  }, [props.addCheckDrnLog]);

  useEffect(() => {
    let listLength = 0;
    let currentList = null;

    switch (props.activeHeaderMenu) {
      case '/image':
        currentList = props.droneImageList;
        break;
      case '/theme':
        currentList = props.themeMapList;
        break;
      case '/farmwork':
        currentList = props.farmworkList;
        break;
      case '/drnLog':
        currentList = props.drnLogFileList;
        break;
      case '/farmworkSelect':
        currentList = props.farmworkSelectList;
        break;
      case '/instFarmwork':
        currentList = props.farmworkListforInst;
        break;
      default:
        currentList = null;
    }

    if (currentList) {
      listLength = currentList.length;
      const newTotalPages = Math.ceil(listLength / pageSize);
      setTotalPages(newTotalPages);
    }
  }, [
    props.activeHeaderMenu,
    props.droneImageList,
    props.themeMapList,
    props.farmworkList,
    props.drnLogFileList,
    props.farmworkSelectList,
    props.farmworkListforInst,
    pageSize,
  ]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const layerInfo = [...props.activeLayerList];
      const item = layerInfo.splice(fromIndex, 1);
      layerInfo.splice(toIndex, 0, item[0]);
      props.activeLayerListHandler(layerInfo);
      props.delModeHandler(-2);
      props.indexFromHandler(fromIndex);
      props.indexToHandler(toIndex);
    },
    nodeSelector: 'li',
    handleSelector: 'li',
  };

  const textRefs = useRef([]);
  const myDivRefs = useRef([]);
  useEffect(() => {
    props.activeLayerList &&
      props.activeLayerList.forEach((e, i) => {
        const myDivRef = myDivRefs.current[i];
        const textRef = textRefs.current[i];

        if (myDivRef && textRef) {
          const contentWidth = textRef.offsetWidth;
          const divWidth = myDivRef.offsetWidth;

          if (contentWidth > divWidth) {
            Object.assign(textRef.style, {
              textDecoration: 'none',
              color: '#404143',
              position: 'relative',
              left: '0%',
              transition: 'left 1s ease',
            });
            myDivRef.addEventListener('mouseover', () => {
              const leftValue = -(contentWidth - divWidth) + 'px';
              Object.assign(textRef.style, {
                textDecoration: 'underline',
                color: '#2987cd',
                left: leftValue,
              });
            });
            myDivRef.addEventListener('mouseout', () => {
              Object.assign(textRef.style, {
                textDecoration: 'none',
                color: '#404143',
                left: '0%',
              });
            });
          } else {
            Object.assign(textRef.style, {
              textDecoration: 'none',
              color: '#404143',
              position: 'relative',
              transition: 'none',
            });
            myDivRef.addEventListener('mouseover', () => {
              Object.assign(textRef.style, {
                textDecoration: 'underline',
                color: '#2987cd',
                left: '0%',
              });
            });
            myDivRef.addEventListener('mouseout', () => {
              Object.assign(textRef.style, {
                textDecoration: 'none',
                color: '#404143',
              });
            });
          }
        }
      });
  }, [props.activeLayerList]);

  useEffect(() => {
    if (props.mobileLayerMenu == true) {
      props.setSubHidden(true);
    } else if (props.mobileLayerMenu == false) {
      props.setSubHidden(false);
    }
  }, [props.mobileLayerMenu]);

  function getDateAndCrop(metadata) {
    let date = '정보없음';
    let crop = '정보없음';

    if (metadata.hasOwnProperty('shoot_start_dt') && metadata.shoot_start_dt) {
      date = metadata.shoot_start_dt.substr(0, 10);
    }

    if (metadata.hasOwnProperty('shoot_trgt') && metadata.shoot_trgt) {
      crop = metadata.shoot_trgt;
    }

    return { date, crop };
  }

  // 더보기 mobileActiveMenu
  const [showImageListAll, setShowImageListAll] = useState(false);
  const [showThemeMapListAll, setShowThemeMapListAll] = useState(false);
  const [showFarmworkListAll, setShowFarmworkListAll] = useState(false);
  const [showDrnLogListAll, setShowDrnLogListAll] = useState(false);

  const imageListCount = showImageListAll
    ? props.droneImageList && props.droneImageList.length
    : 5;
  const themeMapListCount = showThemeMapListAll
    ? props.themeMapList && props.themeMapList.length
    : 7;
  const farmworkListCount = showFarmworkListAll
    ? props.farmworkList && props.farmworkList.length
    : 7;
  const drnLogListCount = showDrnLogListAll
    ? props.drnLogFileList && props.drnLogFileList.length
    : 7;

  const toggleShowImageListAll = () => {
    setShowImageListAll(!showImageListAll);
  };
  const toggleShowThemeListAll = () => {
    setShowThemeMapListAll(!showThemeMapListAll);
  };
  const toggleShowFarmworkListAll = () => {
    setShowFarmworkListAll(!showFarmworkListAll);
  };
  const toggleShowDrnLogListAll = () => {
    setShowDrnLogListAll(!showDrnLogListAll);
  };

  const isMenuActive = (menu, activeMenus) => activeMenus.includes(menu);
  const activeMenus = [1, 2, 3, 5]; // 활성화된 메뉴 ID들
  const { mobileActiveMenu, selectItrst, isMobile, activeHeaderMenu } = props;

  return (
    <>
      <div
        className={`sidebar-container ${
          !props.mainHidden == 1 ? 'hidden' : ''
        }`}
      >
        <div className="sider-header">
          <div className="title-image"
           onClick={() => {
            navigate('/')
            props.setHeaderHidden(false);
            // window.location.reload();
           }}
          ></div>
        </div>
        <div
          className={`list-container ${
            isMenuActive(mobileActiveMenu, activeMenus) ? 'active' : ''
          }`}
        >
          <div className="img-main">
            <div className="main-text">
              {props.selectItrst
                ? props.selectItrst.itrst_zone_nm
                : '관심구역 선택'}
            </div>
            {isMobile && (
              <i
                className="img-xicon code x icon"
                onClick={() => props.setMobileActiveMenu(0)}
              />
            )}
          </div>

          <div className="search-container">
            <div className="contents-row full-width">
              <div className="flex-space"></div>
              {/* <div className="subtitle-text">이모작여부_년도</div> */}
            </div>
            {props.activeHeaderMenu == '/farmworkSelect' ? (
              <>
                <div className="contents-row full-width sideBar-search">
                  <input
                    className="search-input"
                    type="text"
                    placeholder={'검색어를 입력하세요'}
                    value={searchListWord}
                    onChange={handleSearchListWord}
                    onKeyDown={handleKeyPress}
                  ></input>
                  <div
                    // className="search-icon"
                    className={`search-icon ${props.isMobile ? 'mobile' : ''}`}
                    onClick={searchList}
                  ></div>
                </div>

                <div
                  style={{
                    fontFamily: 'Noto Sans KR',
                    fontWeight: 500,
                    fontSize: '15px',
                    // textAlign: "left",
                    padding: '5px',
                    color: 'var(--default-text-color)',
                    border: '1px solid #2987CD',
                    borderRadius: '5px',
                    // height: "100px",
                    margin: '10px 0',
                    minHeight: '60px',
                    // lineHeight: "140%",
                    // width: "450px",
                    // alignItems: "center",
                    justifyContent: 'center',
                  }}
                  className="contents-row"
                >
                  <div style={{ marginRight: '15px' }} className="contents-col">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <Checkbox
                        style={{ marginRight: '10px' }}
                        onClick={(e, { checked }) => {
                          // beforeSelectFarmworkHandler(e, { checked });
                          setBeforeSelectFarmwork(!beforeSelectFarmwork);
                        }}
                        checked={beforeSelectFarmwork}
                      />
                      기관 선정 전
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        style={{ marginRight: '10px' }}
                        onChange={() => {
                          setDoneSelectFarmwork(!doneSelectFarmwork);
                        }}
                        checked={doneSelectFarmwork}
                      />
                      작업 완료
                    </div>
                  </div>

                  <div
                    style={{ margin: '0 15px 0 0' }}
                    className="contents-col"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <Checkbox
                        style={{ marginRight: '10px' }}
                        onChange={() => {
                          setAfterSelectFarmwork(!afterSelectFarmwork);
                        }}
                        checked={afterSelectFarmwork}
                      />
                      기관 선정 완료
                    </div>
                  </div>
                </div>
              </>
            ) : props.activeHeaderMenu == '/instFarmwork' ? (
              <>
                <div className="contents-row full-width sideBar-search">
                  <input
                    className="search-input"
                    type="text"
                    placeholder={'검색어를 입력하세요'}
                    value={searchListWord}
                    onChange={handleSearchListWord}
                    onKeyDown={handleKeyPress}
                  ></input>
                  {/* <div className="filter-icon"></div> */}
                  <div
                    // className="search-icon"
                    className={`search-icon ${props.isMobile ? 'mobile' : ''}`}
                    onClick={searchList}
                  ></div>
                </div>
                {/* 작업 전 탭에만 checkbox 보이게 */}
                {activeIndex === 0 ? (
                  <div
                    style={{
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 500,
                      fontSize: '15px',
                      // textAlign: "left",
                      padding: '5px',
                      color: 'var(--default-text-color)',
                      border: '1px solid #2987CD',
                      borderRadius: '5px',
                      // height: "100px",
                      margin: '10px 0',
                      minHeight: '60px',
                      // lineHeight: "140%",
                      // width: "450px",
                      // alignItems: "center",
                      justifyContent: 'space-around',
                    }}
                    className="contents-row"
                  >
                    <div
                      style={{ marginRight: '15px' }}
                      className="contents-col"
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <Checkbox
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            setBeforeSelect(!beforeSelect);
                          }}
                          checked={beforeSelect}
                        />
                        기관 선정 전
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          style={{ marginRight: '10px' }}
                          onChange={() => {
                            setSuccessSelectFarmwork(!successSelectFarmwork);
                          }}
                          checked={successSelectFarmwork}
                        />
                        지원 성공
                      </div>
                    </div>

                    <div
                      style={{ margin: '0 15px 0 0' }}
                      className="contents-col"
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <Checkbox
                          style={{ marginRight: '10px' }}
                          onChange={() => {
                            setApplySelectFarmwork(!applySelectFarmwork);
                          }}
                          checked={applySelectFarmwork}
                        />
                        지원 완료
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          style={{ marginRight: '10px' }}
                          onChange={() => {
                            setFailSelectFarmwork(!failSelectFarmwork);
                          }}
                          checked={failSelectFarmwork}
                        />
                        지원 실패
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              // <div className="contents-row full-width sideBar-search">
              //   <input
              //     className="search-input"
              //     type="text"
              //     placeholder={'검색어를 입력하세요'}
              //     value={searchListWord}
              //     onChange={handleSearchListWord}
              //     onKeyDown={handleKeyPress}
              //   ></input>
              //   <div
              //     // className="search-icon"
              //     className={`search-icon ${props.isMobile ? 'mobile' : ''}`}
              //     onClick={searchList}
              //   ></div>
              // </div>
              <SearchBar
                searchListWord={searchListWord}
                setSearchListWord={setSearchListWord}
                handleKeyPress={handleKeyPress}
                onClearSearch={onClearSearch}
                searchList={searchList}
              />
            )}
            <div className="contents-row full-width">
              {!props.isMobile ? (
                <div className="subtitle-text">검색결과</div>
              ) : null}
            </div>

            {activeHeaderMenu == '/image' ? (
              <div
                className={`contents-col search-results ${
                  isMobile ? 'mobile' : ''
                }`}
              >
                {isMobile && mobileActiveMenu == 1 ? (
                  <div className="contents-row search-result-count">
                    {totalCount}개의 드론영상이 검색되었습니다.
                  </div>
                ) : (
                  <div className="list-count">
                    총 {totalCount}개의 드론영상이 검색되었습니다.
                  </div>
                )}

                {isMobile && mobileActiveMenu == 1 ? (
                  <div className="imgList">
                    {props.droneImageList &&
                      props.droneImageList
                        .slice(0, imageListCount)
                        .map((e, i) => {
                          const metadata =
                            e.meta_data_cn != 'undefined'
                              ? JSON.parse(e.meta_data_cn)
                              : {};
                          const { date, crop } = getDateAndCrop(metadata);

                          return (
                            <div
                              key={i}
                              className="search-results-item"
                              onClick={() => {
                                // setHidden(3);
                                props.setSubHidden(true);
                                let index = -1;
                                props.activeLayerList &&
                                  props.activeLayerList.map((act_e, act_i) => {
                                    if (act_e.drn_img_id === e.drn_img_id) {
                                      index = act_i;
                                    }
                                  });
                                if (index == -1) {
                                  let copyActiveLayerList = [];
                                  if (props.activeLayerList) {
                                    copyActiveLayerList = [
                                      ...props.activeLayerList,
                                    ];
                                  }
                                  e.layerType = 1;
                                  copyActiveLayerList.unshift(e);
                                  props.activeLayerListHandler(
                                    copyActiveLayerList
                                  );
                                  props.curActiveLayerHandler(e);
                                }
                                if (props.isMobile) {
                                  props.setMobileActiveMenu(0);
                                  props.setMobileLayerMenu(true);
                                }
                              }}
                            >
                              <div className="item-name">{e.drn_img_ttl}</div>
                              <div className="contents-row date-crop">
                                <div className="item-date">{date}</div>
                                <div className="contents-row seedling-crop">
                                  <div className="item-seedling"></div>
                                  <div className="item-crop">{crop}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    {props.droneImageList &&
                      props.droneImageList.length > 7 && (
                        <div
                          className="contents-row toggle-imageList"
                          onClick={(e) => {
                            toggleShowImageListAll();
                          }}
                        >
                          {showImageListAll ? '접기' : '... 더보기'}
                        </div>
                      )}
                  </div>
                ) : (
                  <div className="search-result-list">
                    {props.droneImageList &&
                      props.droneImageList.map((e, i) => {
                        const metadata =
                          e.meta_data_cn != 'undefined'
                            ? JSON.parse(e.meta_data_cn)
                            : {};
                        const { date, crop } = getDateAndCrop(metadata);

                        return (
                          <div
                            key={i}
                            className="search-results-item"
                            onClick={() => {
                              props.sidebarHiddenFlag
                                ? props.setMainHidden(false)
                                : props.setMainHidden(true);
                              if (e.drn_raw_file_yn == 'Y' && !e.layer_nm) {
                                setPopupMsg('정사영상 생성 전입니다.');
                                setShowPopup(true);
                              } else {
                                props.setSubHidden(true);
                                let index = -1;
                                props.activeLayerList &&
                                  props.activeLayerList.map((act_e, act_i) => {
                                    if (act_e.drn_img_id === e.drn_img_id) {
                                      index = act_i;
                                    }
                                  });
                                if (index == -1) {
                                  let copyActiveLayerList = [];
                                  if (props.activeLayerList) {
                                    copyActiveLayerList = [
                                      ...props.activeLayerList,
                                    ];
                                  }
                                  e.layerType = 1;
                                  copyActiveLayerList.unshift(e);
                                  props.activeLayerListHandler(
                                    copyActiveLayerList
                                  );
                                  props.curActiveLayerHandler(e);
                                }
                              }
                            }}
                          >
                            <div className="item-name">
                              {e.drn_img_ttl}
                              {/* {e.drn_raw_file_yn == 'Y' &&
                                e.layer_nm &&
                                e.layer_stl_nm ? (
                                  <div className="badge blue">
                                    정사영상 생성 완료
                                  </div>
                                ) : e.drn_raw_file_yn == 'Y' ? (
                                  <div className="badge">정사영상 생성 전</div>
                                ) : null} */}
                              {e.drn_raw_file_yn == 'Y' &&
                                !e.layer_nm &&
                                !e.layer_stl_nm && (
                                  <div className="badge">정사영상 생성 전</div>
                                )}
                            </div>
                            <div className="contents-row">
                              <div className="item-date">촬영일자 : {date}</div>
                              <div className="item-crop">대상작물 : {crop}</div>
                            </div>
                            <div className="item-index-group contents-row">
                              {e.ndvi_gen &&
                                e.ndvi_gen.map((v, index) => {
                                  return (
                                    <div key={index} className="item-index">
                                      {v}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ) : props.activeHeaderMenu == '/theme' ? (
              // <div className="contents-col search-results">
              <div
                className={`contents-col search-results ${
                  props.isMobile ? 'mobile' : ''
                }`}
                // style={{ height: searchRstHeight }}
              >
                {/* <div className="list-count">총 {totalCount}개의 주제도가 검색되었습니다.</div> */}
                {/* {!props.isMobile ? <div className="list-count">총 {totalCount}개의 주제도가 검색되었습니다.</div> : <div className='contents-row search-result-count'>{totalCount}개의 주제도가 검색되었습니다.</div>} */}
                {props.isMobile && props.mobileActiveMenu == 2 ? (
                  <div className="contents-row search-result-count">
                    {totalCount}개의 주제도가 검색되었습니다.
                  </div>
                ) : (
                  <div className="list-count">
                    총 {totalCount}개의 주제도가 검색되었습니다.
                  </div>
                )}
                {props.isMobile && props.mobileActiveMenu == 2 ? (
                  <div className="imgList">
                    {props.themeMapList &&
                      props.themeMapList
                        .slice(0, themeMapListCount)
                        .map((e, i) => {
                          let theme_type = [];
                          if (e.theme_map_ty_cd) {
                            theme_type = e.theme_map_ty_cd.split(',');
                          }

                          return (
                            <div
                              key={i}
                              className="search-results-item"
                              onClick={() => {
                                axios({
                                  method: 'get',
                                  url: `api/theme-map/${e.them_map_id}`,
                                  headers: {
                                    Authorization: `Bearer {${APIToken}}`,
                                  },
                                })
                                  .then((result) => {
                                    // setHidden(3);
                                    props.setSubHidden(true);
                                    if (result.data.success == true) {
                                      let index = -1;
                                      props.activeLayerList &&
                                        props.activeLayerList.map(
                                          (act_e, act_i) => {
                                            if (
                                              !act_e.hasOwnProperty(
                                                'frmwkt_req_id'
                                              )
                                            ) {
                                              if (
                                                act_e.them_map_id ===
                                                result.data.data.them_map_id
                                              ) {
                                                index = act_i;
                                              }
                                            }
                                          }
                                        );
                                      if (index == -1) {
                                        let copyActiveLayerList = [];
                                        if (props.activeLayerList) {
                                          copyActiveLayerList = [
                                            ...props.activeLayerList,
                                          ];
                                        }
                                        result.data.data.layerType = 2;
                                        e.layerType = 2;
                                        copyActiveLayerList.unshift(
                                          result.data.data
                                        );
                                        props.activeLayerListHandler(
                                          copyActiveLayerList
                                        );
                                        props.curActiveLayerHandler(
                                          result.data.data
                                        );
                                      }
                                      if (props.isMobile) {
                                        props.setMobileActiveMenu(0);
                                        props.setMobileLayerMenu(true);
                                      }
                                    } else if (result.data.success == false) {
                                      setPopupMsg('주제도 정보 조회 오류');
                                      setShowPopup(true);
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }}
                            >
                              <div className="item-name">{e.theme_map_ttl}</div>
                              <div className="contents-row date-crop">
                                <div className="item-date">
                                  기준일자 : {e.crtr_ymd.substr('', 10)}
                                </div>
                                <div className="item-tag">
                                  등록방식 :{' '}
                                  {e.crt_ty_cd == 1
                                    ? '속성정보'
                                    : e.crt_ty_cd == 2
                                    ? '회귀식'
                                    : e.crt_ty_cd == 3
                                    ? '래스터 계산'
                                    : e.crt_ty_cd == 4
                                    ? '육안판독'
                                    : e.crt_ty_cd == 5
                                    ? '직접입력'
                                    : '정보없음'}
                                </div>
                              </div>
                              <div className="date-crop item-index-group contents-row">
                                {theme_type &&
                                  theme_type.map((t, index) => {
                                    let name = '';
                                    themeMapTypeList.map((d) => {
                                      if (d.value == t) {
                                        name = d.text;
                                      }
                                    });
                                    if (name != '') {
                                      return (
                                        <div key={index} className="item-index">
                                          {name}
                                        </div>
                                      );
                                    }
                                  })}
                                <div
                                  className="type-plus"
                                  onClick={(c) => {
                                    setSelectID(e.them_map_id);
                                    if (e.theme_map_ty_cd != null) {
                                      const oldArr =
                                        e.theme_map_ty_cd.split(',');
                                      const newArr = oldArr.map(Number);
                                      setSelectTycd(newArr);
                                    } else {
                                      setSelectTycd([]);
                                    }
                                    setShowList(true);
                                  }}
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    {props.themeMapList && props.themeMapList.length > 7 && (
                      <div
                        className="contents-row toggle-imageList"
                        onClick={(e) => {
                          toggleShowThemeListAll();
                        }}
                      >
                        {showThemeMapListAll ? '접기' : '... 더보기'}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="search-result-list">
                    {props.themeMapList &&
                      props.themeMapList.map((e, i) => {
                        let theme_type = [];
                        if (e.theme_map_ty_cd) {
                          theme_type = e.theme_map_ty_cd.split(',');
                        }
                        return (
                          <div
                            key={i}
                            className="search-results-item"
                            onClick={() => {
                              axios({
                                method: 'get',
                                url: `api/theme-map/${e.them_map_id}`,
                                headers: {
                                  Authorization: `Bearer {${APIToken}}`,
                                },
                              })
                                .then((result) => {
                                  // setHidden(3);
                                  props.sidebarHiddenFlag
                                    ? props.setMainHidden(false)
                                    : props.setMainHidden(true);
                                  props.setSubHidden(true);
                                  if (result.data.success == true) {
                                    let index = -1;
                                    props.activeLayerList &&
                                      props.activeLayerList.map(
                                        (act_e, act_i) => {
                                          if (
                                            !act_e.hasOwnProperty(
                                              'frmwkt_req_id'
                                            )
                                          ) {
                                            if (
                                              act_e.them_map_id ===
                                              result.data.data.them_map_id
                                            ) {
                                              index = act_i;
                                            }
                                          }
                                        }
                                      );
                                    if (index == -1) {
                                      let copyActiveLayerList = [];
                                      if (props.activeLayerList) {
                                        copyActiveLayerList = [
                                          ...props.activeLayerList,
                                        ];
                                      }
                                      result.data.data.layerType = 2;
                                      e.layerType = 2;
                                      copyActiveLayerList.unshift(
                                        result.data.data
                                      );
                                      props.activeLayerListHandler(
                                        copyActiveLayerList
                                      );
                                      props.curActiveLayerHandler(
                                        result.data.data
                                      );
                                    }
                                  } else if (result.data.success == false) {
                                    // console.log(e.them_map_id);
                                    setPopupMsg('주제도 정보 조회 오류');
                                    setShowPopup(true);
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }}
                          >
                            <div className="item-name">{e.theme_map_ttl}</div>
                            <div className="contents-row">
                              <div className="item-date">
                                기준일자 : {e.crtr_ymd.substr('', 10)}
                              </div>
                              <div className="item-tag">
                                등록방식 :{' '}
                                {e.crt_ty_cd == 1
                                  ? '속성정보'
                                  : e.crt_ty_cd == 2
                                  ? '회귀식'
                                  : e.crt_ty_cd == 3
                                  ? '래스터 계산'
                                  : e.crt_ty_cd == 4
                                  ? '육안판독'
                                  : e.crt_ty_cd == 5
                                  ? '직접입력'
                                  : '정보없음'}
                              </div>
                            </div>
                            <div className="item-index-group contents-row">
                              {theme_type &&
                                theme_type.map((t, index) => {
                                  let name = '';
                                  if (themeMapTypeList) {
                                    themeMapTypeList.map((d) => {
                                      if (d.value == t) {
                                        name = d.text;
                                      }
                                    });
                                  }
                                  if (name != '') {
                                    return (
                                      <div key={index} className="item-index">
                                        {name}
                                      </div>
                                    );
                                  }
                                })}
                              <div
                                className="type-plus"
                                onClick={(c) => {
                                  setSelectID(e.them_map_id);
                                  if (e.theme_map_ty_cd != null) {
                                    const oldArr = e.theme_map_ty_cd.split(',');
                                    const newArr = oldArr.map(Number);
                                    setSelectTycd(newArr);
                                  } else {
                                    setSelectTycd([]);
                                  }
                                  setShowList(true);
                                }}
                              >
                                +
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ) : props.activeHeaderMenu == '/farmwork' ? (
              // <div className="contents-col search-results">
              <div
                className={`contents-col search-results ${
                  props.isMobile ? 'mobile' : ''
                }`}
                // style={{ height: searchRstHeight }}
              >
                {/* <div className="list-count">총 {props.farmworkList.length}개의 농작업이 검색되었습니다.</div> */}
                {/* <div className="list-count">총 {totalCount}개의 농작업이 검색되었습니다.</div> */}
                {/* {!props.isMobile ? <div className="list-count">총 {totalCount}개의 농작업이 검색되었습니다.</div> : <div className='contents-row search-result-count'>{totalCount}개의 농작업이 검색되었습니다.</div>} */}
                {props.isMobile && props.mobileActiveMenu == 3 ? (
                  <div className="contents-row search-result-count">
                    {totalCount}개의 농작업이 검색되었습니다.
                  </div>
                ) : (
                  <div className="list-count">
                    총 {totalCount}개의 농작업이 검색되었습니다.
                  </div>
                )}
                {props.isMobile && props.mobileActiveMenu == 3 ? (
                  <div className="imgList">
                    {props.farmworkList &&
                      props.farmworkList
                        .slice(0, farmworkListCount)
                        .map((e, i) => {
                          return (
                            <div
                              key={i}
                              className="search-results-item"
                              onClick={() => {
                                axios({
                                  method: 'get',
                                  url:
                                    config.backend +
                                    '/api/data/frmwrk/' +
                                    e.meta_frmwrk_id +
                                    '/' +
                                    e.meta_frmwrk_id +
                                    'spray_path.waypoints',
                                })
                                  .then((response) => {
                                    if (response.data.startsWith('QGC')) {
                                      props.setWaypoint(true);
                                    } else {
                                      props.setWaypoint(false);
                                    }
                                  })
                                  .catch((e) => {
                                    props.setWaypoint(false);
                                  });
                                props.setClickNum(e.meta_frmwrk_id);
                                // setHidden(3);
                                props.setSubHidden(true);
                                let index = -1;
                                props.activeLayerList &&
                                  props.activeLayerList.map((act_e, act_i) => {
                                    if (
                                      act_e.frmwkt_req_id === e.frmwkt_req_id
                                    ) {
                                      index = act_i;
                                    }
                                  });
                                if (index == -1) {
                                  let copyActiveLayerList = [];
                                  if (props.activeLayerList) {
                                    copyActiveLayerList = [
                                      ...props.activeLayerList,
                                    ];
                                  }
                                  e.layerType = 3;
                                  copyActiveLayerList.unshift(e);
                                  props.activeLayerListHandler(
                                    copyActiveLayerList
                                  );
                                  props.curActiveLayerHandler(e);
                                }
                                if (props.isMobile) {
                                  props.setMobileActiveMenu(0);
                                  props.setMobileLayerMenu(true);
                                }
                                props.setShowChart(false);
                                props.setNoChart(false);
                              }}
                            >
                              {/* <div className="item-name">{e.fmap_innb}</div> */}
                              {/* <div className="contents-row">
                                              <div className="item-date">요청일자 : {e.frmwkt_ymd}</div>
                                              <div className="item-crop">대상작물 : {e.trgt_crps}</div>
                                          </div>
                                          <div className="item-date">등록일자 : {e.frmwkt_req_dt.substr(0,10)}</div> */}
                              <div className="contents-row">
                                <div className="item-name">{e.fmap_innb}</div>
                                <div className="contents-row seedling-crop">
                                  <div className="item-seedling"></div>
                                  <div className="item-crop">{e.trgt_crps}</div>
                                </div>
                              </div>
                              <div className="contents-row date-crop">
                                <div className="item-date">
                                  요청일자 : {e.frmwkt_ymd}
                                </div>
                                <div className="item-date">
                                  등록일자 : {e.frmwkt_req_dt.substr(0, 10)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    {props.farmworkList && props.farmworkList.length > 7 && (
                      <div
                        className="contents-row toggle-imageList"
                        onClick={(e) => {
                          toggleShowFarmworkListAll();
                        }}
                      >
                        {showFarmworkListAll ? '접기' : '... 더보기'}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="search-result-list">
                    {props.farmworkList &&
                      props.farmworkList.map((e, i) => {
                      
                        return (
                          <div
                            key={i}
                            className="search-results-item"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'left' 
                            }}
                          >
                            <div>
                              <Checkbox
                                style={{ margin: '6px 0 10px 0px' }}
                                checked={selectedFrmwrkId.includes(
                                  e.frmwkt_req_id
                                )}
                                onChange={() => handleCheckboxChange(e)}
                              />
                            </div>
                            <div
                              // key={i}
                              style={{ paddingLeft: 0 , paddingTop: '2px'}}
                              onClick={async () => {
                                props.sidebarHiddenFlag
                                  ? props.setMainHidden(false)
                                  : props.setMainHidden(true);
                                props.setFarmWorkLogFlag(true);
                                axios({
                                  method: 'get',
                                  url:
                                    config.backend +
                                    '/api/data/frmwrk/' +
                                    e.meta_frmwrk_id +
                                    '/' +
                                    e.meta_frmwrk_id +
                                    'spray_path.waypoints',
                                })
                                  .then((response) => {
                                    // console.log(response);
                                    if (response.data.startsWith('QGC')) {
                                      props.setWaypoint(true);
                                    } else {
                                      props.setWaypoint(false);
                                    }
                                  })
                                  .catch((e) => {
                                    props.setWaypoint(false);
                                  });
                                // showConsole("props.farmworkList.e", e);
                                // setHidden(3);
                                props.setSubHidden(true);
                                let index = -1;
                                props.activeLayerList &&
                                  props.activeLayerList.map((act_e, act_i) => {
                                    if (
                                      act_e.frmwkt_req_id === e.frmwkt_req_id
                                    ) {
                                      // showConsole(
                                      //   "act_e.frmwkt_req_id",
                                      //   act_e.frmwkt_req_id
                                      // );
                                      // showConsole("e.frmwkt_req_id", e.frmwkt_req_id);
                                      index = act_i;
                                    }
                                  });
                                if (index == -1) {
                                  let copyActiveLayerList = [];
                                  if (props.activeLayerList) {
                                    copyActiveLayerList = [
                                      ...props.activeLayerList,
                                    ];
                                  }
                                  e.layerType = 3;
                                  copyActiveLayerList.unshift(e);
                                  // props.setClickNum(e.meta_frmwrk_id);
                                  props.activeLayerListHandler(
                                    copyActiveLayerList
                                  );
                                  props.curActiveLayerHandler(e);
                                }
                                // props.setFarmWorkLogFlag(true);
                              }}
                            >
                              <div
                                className="item-name"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                주소 : {e.search_keyword}
                              </div>
                              <div className="item-date">
                                작업 요청일 : {e.frmwkt_ymd}
                                {/* 필지 : {e.fmap_innb} */}
                              </div>
                              <div className="contents-row">
                                <div className="item-crop">
                                  {/* 대상작물 : {e.trgt_crps} */}
                                  농작업 종류 :{' '}
                                  {e.frmwkt_ty_cd == 'lp'
                                    ? '액체 방제'
                                    : e.frmwkt_ty_cd == 'sp'
                                    ? '고체 방제'
                                    : e.frmwkt_ty_cd == 'lf'
                                    ? '액체 비료'
                                    : e.frmwkt_ty_cd == 'sf'
                                    ? '고체 비료'
                                    : e.frmwkt_ty_cd == 'so'
                                    ? '파종'
                                    : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ) : props.activeHeaderMenu == '/drnLog' ? (
              <div
                className={`contents-col search-results ${
                  props.isMobile ? 'mobile' : ''
                }`}
                // style={{ height: searchRstHeight }}
              >
                {props.isMobile && props.mobileActiveMenu == 5 ? (
                  <div className="contents-row search-result-count">
                    {totalCount}개의 드론 로그 파일이 검색되었습니다.
                  </div>
                ) : (
                  <div className="list-count">
                    총 {totalCount}개의 드론 로그 파일이 검색되었습니다.
                  </div>
                )}
                {props.isMobile && props.mobileActiveMenu == 5 ? (
                  <div className="search-result-list">
                    {props.drnLogFileList &&
                      props.drnLogFileList
                        .slice(0, drnLogListCount)
                        .map((e, i) => {
                          return (
                            <div
                              key={i}
                              className="search-results-item"
                              onClick={() => {
                                props.sidebarHiddenFlag
                                  ? props.setMainHidden(false)
                                  : props.setMainHidden(true);
                                let index = -1;
                                props.activeLayerList &&
                                  props.activeLayerList.map((act_e, act_i) => {
                                    if (act_e.log_sn === e.log_sn) {
                                      index = act_i;
                                    }
                                  });
                                if (index == -1) {
                                  let copyActiveLayerList = [];
                                  if (props.activeLayerList) {
                                    copyActiveLayerList = [
                                      ...props.activeLayerList,
                                    ];
                                  }
                                  e.layerType = 5;
                                  copyActiveLayerList.unshift(e);
                                  props.activeLayerListHandler(
                                    copyActiveLayerList
                                  );
                                  props.curActiveLayerHandler(e);
                                }
                                if (props.isMobile) {
                                  props.setMobileActiveMenu(0);
                                  props.setMobileLayerMenu(true);
                                }
                              }}
                            >
                              <div className="contents-row">
                                <div className="item-name">
                                  제목 : {e.ttl_nm}
                                </div>
                              </div>
                              <div className="contents-row date-crop">
                                <div className="item-date">
                                  작업일 : {e.job_ymd}
                                </div>
                              </div>
                              <div className="item-date">
                                {e.orgnl_file_nm
                                  .split('.')
                                  .slice(0, -1)
                                  .join('.')}
                              </div>
                            </div>
                          );
                        })}

                    {props.drnLogFileList &&
                      props.drnLogFileList.length > 7 && (
                        <div
                          className="contents-row toggle-imageList"
                          onClick={(e) => {
                            toggleShowDrnLogListAll();
                          }}
                        >
                          {showDrnLogListAll ? '접기' : '... 더보기'}
                        </div>
                      )}
                  </div>
                ) : (
                  <div className="search-result-list">
                    {props.drnLogFileList &&
                      props.drnLogFileList.map((e, i) => {
                        return (
                          <div
                            key={i}
                            className="search-results-item"
                            onClick={() => {
                              // drnLogData(e);
                              // --------------------------
                              props.sidebarHiddenFlag
                                ? props.setMainHidden(false)
                                : props.setMainHidden(true);
                              let index = -1;
                              props.activeLayerList &&
                                props.activeLayerList.map((act_e, act_i) => {
                                  if (act_e.log_sn === e.log_sn) {
                                    index = act_i;
                                  }
                                });
                              if (index == -1) {
                                let copyActiveLayerList = [];
                                if (props.activeLayerList) {
                                  copyActiveLayerList = [
                                    ...props.activeLayerList,
                                  ];
                                }
                                e.layerType = 5;
                                copyActiveLayerList.unshift(e);
                                props.activeLayerListHandler(
                                  copyActiveLayerList
                                );
                                props.curActiveLayerHandler(e);
                              }
                              if (props.isMobile) {
                                props.setMobileActiveMenu(0);
                                props.setMobileLayerMenu(true);
                              }
                            }}
                          >
                            <div className="contents-row">
                              <div className="item-name">제목 : {e.ttl_nm}</div>
                            </div>
                            <div className="contents-row date-crop">
                              <div className="item-date">
                                작업일 : {e.job_ymd}
                              </div>
                            </div>
                            <div className="item-log">
                              로그파일 :{' '}
                              {e.orgnl_file_nm
                                .split('.')
                                .slice(0, -1)
                                .join('.')}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ) : props.activeHeaderMenu == '/farmworkSelect' ? (
              <div
                className={`contents-col search-results ${
                  props.isMobile ? 'mobile' : ''
                }`}
              >
                {props.isMobile && props.mobileActiveMenu == 4 ? null : (
                  <div className="list-count">
                    총 {totalCount}개의 농작업이 검색되었습니다.
                  </div>
                )}
                {props.isMobile && props.mobileActiveMenu == 4
                  ? null
                  : props.farmworkSelectList &&
                    props.farmworkSelectList.map((e, i) => {
                      return (
                        <>
                          <div
                            // key={i}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              borderTop: '1px solid white',
                              minHeight: '80px',
                              padding: '3px 10px',
                            }}
                          >
                            <div
                              key={i}
                              className="search-results-item"
                              style={{ padding: '0' }}
                              onClick={async () => {
                                // 계획 경로 확인
                                await axios({
                                  method: 'get',
                                  url:
                                    config.backend +
                                    '/api/data/frmwrk/' +
                                    e.meta_frmwrk_id +
                                    '/' +
                                    e.meta_frmwrk_id +
                                    'spray_path.waypoints',
                                })
                                  .then((response) => {
                                    // console.log(response);
                                    if (response.data.startsWith('QGC')) {
                                      props.setWaypoint(true);
                                    } else {
                                      props.setWaypoint(false);
                                    }
                                  })
                                  .catch((e) => {
                                    props.setWaypoint(false);
                                  });
                                {
                                  e.frmwkt_stts_cd === 2
                                    ? await axios({
                                        method: 'get',
                                        url:
                                          `/api/instFarmwork/getSpecifyInst?` +
                                          `zoneId=${e.itrst_zone_id}` +
                                          `&frmwkt_req_id=${e.frmwkt_req_id}`,
                                        headers: {
                                          Authorization: `Bearer {${APIToken}}`,
                                        },
                                      })
                                        .then((result) => {
                                          if (result.data.data.length > 0) {
                                            e.specifyInst =
                                              result.data.data[0].inst_nm;
                                          }
                                        })
                                        .catch((error) => {
                                          console.log(error);
                                        })
                                    : await axios({
                                        method: 'get',
                                        url:
                                          `/api/instFarmwork/getDoneInst?` +
                                          `userId=${e.user_id}` +
                                          `&zoneId=${e.itrst_zone_id}` +
                                          `&frmwkt_req_id=${e.frmwkt_req_id}`,
                                        headers: {
                                          Authorization: `Bearer {${APIToken}}`,
                                        },
                                      })
                                        .then((result) => {
                                          if (result.data.data) {
                                            e.specifyInst =
                                              result.data.data.inst_nm;
                                            e.uid = result.data.data.uid;
                                            e.frmwkt_rslt_cn =
                                              result.data.data.frmwkt_rslt_cn;
                                          }
                                        })
                                        .catch((error) => {
                                          console.log(error);
                                        });
                                }
                                props.setSubHidden(false);

                                let index = -1;
                                e.layerType = 4;
                                if (e.frmwkt_stts_cd !== 1) {
                                  if (e.frmwkt_stts_cd === 4 && e.geoJson) {
                                    props.setSubHidden(true);
                                    props.activeLayerList &&
                                      props.activeLayerList.map(
                                        (act_e, act_i) => {
                                          if (
                                            act_e.frmwkt_req_id ===
                                            e.frmwkt_req_id
                                          ) {
                                            index = act_i;
                                          }
                                        }
                                      );
                                    if (index == -1) {
                                      let copyActiveLayerList = [];
                                      if (props.activeLayerList) {
                                        copyActiveLayerList = [
                                          ...props.activeLayerList,
                                        ];
                                      }

                                      copyActiveLayerList.unshift(e);
                                      props.activeLayerListHandler(
                                        copyActiveLayerList
                                      );
                                      let originDate = new Date(
                                        e.frmwkt_req_dt
                                      );

                                      const TIME_ZONE = 9 * 60 * 60 * 1000; // 9시간
                                      let editDate = new Date(
                                        originDate.getTime() + TIME_ZONE
                                      )
                                        .toISOString()
                                        .replace('T', ' ')
                                        .slice(0, -5);

                                      e.koreanTime = editDate;
                                      props.curActiveLayerHandler(e);
                                    }
                                  }
                                }
                              }}
                            >
                              <div className="contents-row">
                                <div className="item-date">
                                  필지 : {e.fmap_innb}
                                </div>
                              </div>
                              <div className="item-date">
                                작업 요청일 : {e.frmwkt_ymd}
                              </div>
                              <div className="item-date">
                                대상 작물 : {e.trgt_crps}
                              </div>
                              <div className="contents-row">
                                <div className="item-crop">
                                  농작업 종류 :{' '}
                                  {e.frmwkt_ty_cd == 'lp'
                                    ? '액체 방제'
                                    : e.frmwkt_ty_cd == 'sp'
                                    ? '고체 방제'
                                    : e.frmwkt_ty_cd == 'lf'
                                    ? '액체 비료'
                                    : e.frmwkt_ty_cd == 'sf'
                                    ? '고체 비료'
                                    : e.frmwkt_ty_cd == 'so'
                                    ? '파종'
                                    : null}
                                </div>
                                <div className="item-crop">
                                  지원 업체 수 : {e.count}
                                </div>
                              </div>
                              <div className="contents-row"></div>
                            </div>

                            {e.frmwkt_stts_cd === 1 ? (
                              <Button
                                style={{
                                  position: 'absolute',
                                  right: '20px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontFamily: 'Noto Sans KR',
                                  fontSize: '14px',
                                  width: '100px',
                                  height: '25px',
                                  backgroundColor: 'white',
                                  border: '1px solid #999999',
                                  borderRadius: '6px',
                                  padding: 0,
                                }}
                                onClick={() => {
                                  props.setCheckInst(true);
                                }}
                              >
                                기관 선정하기
                              </Button>
                            ) : e.frmwkt_stts_cd === 2 ? (
                              <Button
                                style={{
                                  position: 'absolute',
                                  right: '20px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontFamily: 'Noto Sans KR',
                                  fontSize: '14px',
                                  height: '25px',
                                  backgroundColor: 'white',
                                  border: '1px solid #999999',
                                  borderRadius: '6px',
                                  color: '#2DB84B',
                                  width: '100px',
                                  padding: 0,
                                }}
                              >
                                기관 선정 완료
                              </Button>
                            ) : (
                              <Button
                                style={{
                                  position: 'absolute',
                                  right: '20px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontFamily: 'Noto Sans KR',
                                  fontSize: '14px',
                                  minWidth: '100px',
                                  height: '25px',
                                  backgroundColor: '#17375E',
                                  color: 'white',
                                  // border: "1px solid #999999",
                                  borderRadius: '6px',
                                }}
                              >
                                작업 완료
                              </Button>
                            )}
                          </div>
                        </>
                      );
                    })}
              </div>
            ) : props.activeHeaderMenu == '/instFarmwork' ? (
              <div
                className={`contents-col search-results ${
                  props.isMobile ? 'mobile' : ''
                }`}
              >
                {props.isMobile && props.mobileActiveMenu == 6 ? null : (
                  <Tab
                    menu={{
                      secondary: true,
                      pointing: true,
                    }}
                    panes={panes}
                    // activeIndex={activeIndex}
                    onTabChange={handleTabChange}
                    defaultActiveIndex={0}
                  />
                )}
              </div>
            ) : null}
            <div className="button-group flex-center">
              {props.activeHeaderMenu == '/image' ? (
                <button
                  // className={`gc-btn search-add-button`}
                  className={`gc-btn search-add-button ${
                    props.isMine ? null : 'hidden'
                  }`}
                  onClick={() => {
                    props.handleActiveImg(1);
                    props.setShowImageReg(true);
                  }}
                >
                  관측영상 등록
                </button>
              ) : props.activeHeaderMenu == '/theme' ? (
                <button
                  // className={`gc-btn search-add-button`}
                  className={`gc-btn search-add-button ${
                    props.isMine ? null : 'hidden'
                  }`}
                  onClick={() => {
                    props.handleActiveTheme(1);
                    props.setShowThemeReg(true);
                  }}
                >
                  주제도 등록
                </button>
              ) : props.activeHeaderMenu == '/farmwork' ? (
                <>
                  <button
                    // className={`gc-btn search-add-button`}
                    className={`gc-btn search-add-button ${
                      props.isMine ? null : 'hidden'
                    }`}
                    onClick={() => {
                      props.setSelectedFarm();
                      props.setRequestFarmwork(true);
                    }}
                  >
                    농작업 요청
                  </button>
                  <button
                    // className={`gc-btn delete-farmwork-button`}
                    className={`gc-btn delete-farmwork-button ${
                      props.isMine ? null : 'hidden'
                    }`}
                    onClick={() => {
                      onClickDeleteBtn();
                    }}
                  >
                    농작업 삭제
                  </button>
                </>
              ) : props.activeHeaderMenu == '/farmworkSelect' ? (
                <>
                  <button
                    // className={`gc-btn search-add-button`}
                    className={`gc-btn search-add-button ${
                      props.isMine ? null : 'hidden'
                    }`}
                    onClick={() => {
                      props.setSelectedFarm();
                      props.setRegistFarmwork(true);
                    }}
                  >
                    농작업 요청
                  </button>
                </>
              ) : props.activeHeaderMenu == '/instFarmwork' ? (
                <>
                  {activeIndex === 0 ? (
                    <button
                      // className={`gc-btn search-add-button`}
                      className={`gc-btn search-add-button ${
                        props.isMine ? null : 'hidden'
                      }`}
                      onClick={() => {
                        onClickFarmworkUpload();
                      }}
                    >
                      작업 완료
                    </button>
                  ) : null}
                </>
              ) : props.activeHeaderMenu == '/drnLog' ? (
                <>
                  <button
                    // className={`gc-btn search-add-button}`}
                    className={`gc-btn search-add-button ${
                      props.isMine ? null : 'hidden'
                    }`}
                    style={{ width: '60%' }}
                    onClick={() => {
                      props.handleActiveDrnLog(1);
                      props.setShowDrnLogReg(true);
                    }}
                  >
                    드론 로그 업로드
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div
          className={`layer-container contents-col ${
            !props.subHidden ? 'hidden' : ''
          }`}
          style={{ height: layerCtnHeight }}
          // style={{ height: searchRstHeight }}
        >
          <div className="layer-header contents-row">
            <div className="subtitle-text">활성화 레이어</div>
            {props.isMobile ? (
              // <div className="layer-onoff flex-center" onClick={()=>{
              //     props.setMobileLayerMenu(!props.mobileLayerMenu);
              // }}></div>:null}
              <Swipe
                className="layer-onoff flex-center"
                onSwipeStart={(event) => {}}
                onSwipeMove={(position, event) => {
                  // console.log(`Moved ${position.y} pixels vertically`, event);
                  if (position.y > 60) {
                    props.setMobileLayerMenu(false);
                    return true;
                  }
                }}
                onSwipeEnd={(event) => {}}
              ></Swipe>
            ) : null}
            {props.isMobile ? (
              <div
                className="deleteAll"
                onClick={async () => {
                  await removeAllLayer();
                  props.setClickNum();
                  props.setShowChart(false);
                  props.setNoChart(false);
                  props.setReadyChart(false);
                  props.setReadyCSVChart(false);
                  props.activeLayerListHandler(null);
                  props.curActiveLayerHandler(null);
                }}
              >
                전체삭제
              </div>
            ) : null}
          </div>
          <div className="layer-content contents-col">
            <ReactDragListView {...dragProps}>
              <ol>
                {props.activeLayerList &&
                  props.activeLayerList.map((e, i) => {
                    return (
                      <React.Fragment>
                        <li key={i} className="item-container">
                          <div className="li-row">
                            <div className="icon-group contents-row">
                              <div
                                className={`layer-visual-icon ${
                                  props.VISIBLE_ARRAY[i] == true
                                    ? ''
                                    : 'invisible'
                                }`}
                                id={'icon' + i}
                                onClick={() => {
                                  if (props.VISIBLE_ARRAY[i] === true) {
                                    props.layerOff(i);

                                    // const existingLayer1 = map.getLayer(
                                    //   "missionLayer" + e.meta_frmwrk_id
                                    // );
                                    // if (existingLayer1) {
                                    //   layerOff("missionLayer" + e.meta_frmwrk_id);
                                    //   handleClick(
                                    //     "missionLayer" + e.meta_frmwrk_id
                                    //   );
                                    // }

                                    // const existingLayer2 = map.getLayer(
                                    //   "autoLayer" + e.meta_frmwrk_id
                                    // );
                                    // if (existingLayer2) {
                                    //   layerOff("autoLayer" + e.meta_frmwrk_id);
                                    //   handleClick("autoLayer" + e.meta_frmwrk_id);
                                    // }

                                    // const existingLayer3 = map.getLayer(
                                    //   "manualLayer" + e.meta_frmwrk_id
                                    // );
                                    // if (existingLayer3) {
                                    //   layerOff("manualLayer" + e.meta_frmwrk_id);
                                    //   handleClick(
                                    //     "manualLayer" + e.meta_frmwrk_id
                                    //   );
                                    // }

                                    // const existingLayer4 = map.getLayer(
                                    //   "csvLayer" + e.meta_frmwrk_id
                                    // );
                                    // if (existingLayer4) {
                                    //   layerOff("csvLayer" + e.meta_frmwrk_id);
                                    //   csvHandleClick(
                                    //     "csvLayer" + e.meta_frmwrk_id
                                    //   );
                                    // }

                                    //logupload test
                                    // const existingLayer5 = map.getLayer(
                                    //   "missionLayer" +"drnLog"+e.log_sn
                                    // );
                                    // if (existingLayer5) {
                                    //   layerOff("missionLayer" +"drnLog"+e.log_sn);
                                    //   handleClick(
                                    //     "missionLayer" + "drnLog"+e.log_sn
                                    //   );
                                    // }

                                    // const existingLayer6 = map.getLayer(
                                    //   "autoLayer" + "drnLog"+e.log_sn
                                    // );
                                    // if (existingLayer6) {
                                    //   layerOff("autoLayer" +"drnLog"+e.log_sn);
                                    //   handleClick(
                                    //     "autoLayer" +"drnLog"+e.log_sn
                                    //   );
                                    // }
                                    // const existingLayer7 = map.getLayer(
                                    //   "manualLayer" +"drnLog"+e.log_sn
                                    // );
                                    // if (existingLayer7) {
                                    //   layerOff("manualLayer" + "drnLog"+e.log_sn);
                                    //   handleClick(
                                    //     "manualLayer" +"drnLog"+e.log_sn
                                    //   );
                                    // }
                                    // const existingLayer8 = map.getLayer(
                                    //   "csvLayer" +"drnLog"+e.log_sn
                                    // );
                                    // if (existingLayer8) {
                                    //   layerOff("csvLayer" + "drnLog"+e.log_sn);
                                    //   handleClick(
                                    //     "csvLayer" + "drnLog"+e.log_sn
                                    //   );
                                    // }
                                  } else if (props.VISIBLE_ARRAY[i] === false) {
                                    props.layerOn(i);
                                    // const existingLayer1 = map.getLayer(
                                    //   "missionLayer" + e.meta_frmwrk_id
                                    // );
                                    // if (existingLayer1) {
                                    //   layerOn("missionLayer" + e.meta_frmwrk_id);
                                    //   handleClick(
                                    //     "missionLayer" + e.meta_frmwrk_id
                                    //   );
                                    // }

                                    // const existingLayer2 = map.getLayer(
                                    //   "autoLayer" + e.meta_frmwrk_id
                                    // );
                                    // if (existingLayer2) {
                                    //   layerOn("autoLayer" + e.meta_frmwrk_id);
                                    //   handleClick("autoLayer" + e.meta_frmwrk_id);
                                    // }

                                    // const existingLayer3 = map.getLayer(
                                    //   "manualLayer" + e.meta_frmwrk_id
                                    // );
                                    // if (existingLayer3) {
                                    //   layerOn("manualLayer" + e.meta_frmwrk_id);
                                    //   handleClick(
                                    //     "manualLayer" + e.meta_frmwrk_id
                                    //   );
                                    // }

                                    // const existingLayer4 = map.getLayer(
                                    //   "csvLayer" + e.meta_frmwrk_id
                                    // );
                                    // if (existingLayer4) {
                                    //   layerOn("csvLayer" + e.meta_frmwrk_id);
                                    //   csvHandleClick(
                                    //     "csvLayer" + e.meta_frmwrk_id
                                    //   );
                                    // }

                                    // //logupload test
                                    // const existingLayer5 = map.getLayer(
                                    //   "missionLayer" + "drnLog"+e.log_sn
                                    // );
                                    // if (existingLayer5) {
                                    //   layerOn("missionLayer"+ "drnLog"+e.log_sn);
                                    //   handleClick(
                                    //     "missionLayer" +"drnLog"+e.log_sn
                                    //   );

                                    // }

                                    // const existingLayer6 = map.getLayer(
                                    //   "autoLayer" +"drnLog"+e.log_sn
                                    // );
                                    // if (existingLayer6) {
                                    //   layerOn("autoLayer" + "drnLog"+e.log_sn);
                                    //   handleClick(
                                    //     "autoLayer" +"drnLog"+e.log_sn
                                    //   );
                                    // }
                                    // const existingLayer7 = map.getLayer(
                                    //   "manualLayer" +"drnLog"+e.log_sn
                                    // );
                                    // if (existingLayer7) {
                                    //   layerOn("manualLayer" +"drnLog"+e.log_sn);
                                    //   handleClick(
                                    //     "manualLayer" +"drnLog"+e.log_sn
                                    //   );
                                    // }
                                    // const existingLayer8 = map.getLayer(
                                    //   "csvLayer" +"drnLog"+e.log_sn
                                    // );
                                    // if (existingLayer8) {
                                    //   layerOn("csvLayer" +"drnLog"+e.log_sn);
                                    //   csvHandleClick(
                                    //     "csvLayer" +"drnLog"+e.log_sn
                                    //   );
                                    // }
                                  }
                                }}
                              ></div>
                            </div>
                            <div className="division-A"></div>
                            {/* {e.layerType == 3 ? (
                              // <i className="layertype-icon flex-center fa-solid fa-wheat-awn"></i>
                              <div className="layertype-icon flex-center frmwork"></div>
                            ) : e.layerType == 2 ? (
                              // <i className="layertype-icon flex-center fa-solid fa-map-location-dot"></i>
                              <div className="layertype-icon flex-center theme"></div>
                            ) : (
                              // <i className="layertype-icon flex-center fa-solid fa-panorama"></i>
                              <div className="layertype-icon flex-center drone"></div>
                            )} */}
                            <div
                              className="item"
                              // ref={(el) => (myDivRefs.current[i] = el)}
                              // onClick={() => {
                              //   props.curActiveLayerHandler(e);
                              //   props.moveCurActiveLayer();
                              //   props.setShowChart(false);
                              //   props.setNoChart(false);
                              // }}
                            >
                              <div
                                className="item-nm"
                                // ref={(el) => (textRefs.current[i] = el)}
                              >
                                <div className="activeLayer">
                                  {e.layerType == 1
                                    ? e.drn_img_ttl
                                    : e.layerType == 2
                                    ? e.theme_map_ttl
                                    : e.layerType == 3
                                    ? '필지 : ' +
                                      e.fmap_innb +
                                      '  (' +
                                      e.meta_frmwrk_id +
                                      ')'
                                    : e.layerType == 5
                                    ? '제목 : ' + e.ttl_nm
                                    : e.layerType == 4
                                    ? '필지 : ' + e.fmap_innb
                                    : e.layerType == 6
                                    ? '필지 : ' + e.fmap_innb
                                    : '가나다라'}
                                </div>
                                {e.layerType === 3 ||
                                e.layerType === 4 ||
                                e.layerType === 6 ? (
                                  <div className="activeLayer">
                                    {'작업 요청일 : ' + e.frmwkt_ymd}
                                  </div>
                                ) : e.layerType == 5 ? (
                                  <div className="activeLayer">
                                    {'작업일 : ' + e.job_ymd}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="icon-group contents-row">
                              <i
                                className="fa-regular fa-xmark layer-delete-icon"
                                onClick={() => {
                                  if (
                                    props.curActiveLayer &&
                                    props.curActiveLayer.layerType == 1
                                  ) {
                                    if (
                                      e.drn_img_id ==
                                      props.curActiveLayer.drn_img_id
                                    ) {
                                      props.curActiveLayerHandler();
                                      props.setSelVIindex(-1);
                                      props.vegIndexImageHandler(null);
                                    }
                                  } else if (
                                    props.curActiveLayer &&
                                    props.curActiveLayer.layerType == 2
                                  ) {
                                    if (
                                      e.them_map_id ==
                                      props.curActiveLayer.them_map_id
                                    ) {
                                      props.curActiveLayerHandler();
                                    }
                                  } else if (
                                    props.curActiveLayer &&
                                    props.curActiveLayer.layerType == 3
                                  ) {
                                    if (
                                      e.frmwkt_req_id ==
                                      props.curActiveLayer.frmwkt_req_id
                                    ) {
                                      if (props.activeLayerList.length > 1) {
                                        props.curActiveLayerHandler(
                                          props.activeLayerList[0]
                                        );
                                      } else {
                                        props.curActiveLayerHandler();
                                      }
                                    }
                                    const existingLayer1 = map.getLayer(
                                      'autoLayer' + e.meta_frmwrk_id
                                    );
                                    const existingLayer2 = map.getLayer(
                                      'manualLayer' + e.meta_frmwrk_id
                                    );
                                    const existingLayer3 = map.getLayer(
                                      'missionLayer' + e.meta_frmwrk_id
                                    );
                                    const existingLayer4 = map.getLayer(
                                      'csvLayer' + e.meta_frmwrk_id
                                    );

                                    if (existingLayer1) {
                                      removeLayer(
                                        'autoLayer' + e.meta_frmwrk_id
                                      );
                                    }
                                    if (existingLayer2) {
                                      removeLayer(
                                        'manualLayer' + e.meta_frmwrk_id
                                      );
                                    }
                                    if (existingLayer3) {
                                      removeLayer(
                                        'missionLayer' + e.meta_frmwrk_id
                                      );
                                      props.setDroneLog((prevStates) => {
                                        const {
                                          [e.meta_frmwrk_id]: _,
                                          ...rest
                                        } = prevStates;
                                        return { ...rest };
                                      });

                                      props.setStates((prevStates) => {
                                        const newState = Object.keys(
                                          prevStates
                                        ).reduce((acc, key) => {
                                          if (!key.endsWith(e.meta_frmwrk_id)) {
                                            acc[key] = prevStates[key];
                                          }
                                          return acc;
                                        }, {});
                                        return newState;
                                      });
                                    }
                                    if (existingLayer4) {
                                      removeLayer(
                                        'csvLayer' + e.meta_frmwrk_id
                                      );
                                      props.setCsvLog((prevStates) => {
                                        const {
                                          [e.meta_frmwrk_id]: _,
                                          ...rest
                                        } = prevStates;
                                        return { ...rest };
                                      });

                                      props.setCsvStates((prevStates) => {
                                        const {
                                          ['csvLayer' + e.meta_frmwrk_id]: _,
                                          ...rest
                                        } = prevStates;
                                        return { ...rest };
                                      });
                                    }
                                  } else if (
                                    props.curActiveLayer &&
                                    props.curActiveLayer.layerType == 5
                                  ) {
                                    if (
                                      e.log_sn == props.curActiveLayer.log_sn
                                    ) {
                                      if (props.activeLayerList.length > 1) {
                                        props.curActiveLayerHandler(
                                          props.activeLayerList[0]
                                        );
                                      } else {
                                        props.curActiveLayerHandler();
                                      }
                                    }

                                    const existingLayer5 = map.getLayer(
                                      'autoLayer' + 'drnLog' + e.log_sn
                                    );
                                    const existingLayer6 = map.getLayer(
                                      'manualLayer' + 'drnLog' + e.log_sn
                                    );
                                    const existingLayer7 = map.getLayer(
                                      'missionLayer' + 'drnLog' + e.log_sn
                                    );
                                    const existingLayer8 = map.getLayer(
                                      'csvLayer' + 'drnLog' + e.log_sn
                                    );

                                    if (existingLayer5) {
                                      removeLayer(
                                        'autoLayer' + 'drnLog' + e.log_sn
                                      );
                                    }
                                    if (existingLayer6) {
                                      removeLayer(
                                        'manualLayer' + 'drnLog' + e.log_sn
                                      );
                                    }
                                    if (existingLayer7) {
                                      removeLayer(
                                        'missionLayer' + 'drnLog' + e.log_sn
                                      );
                                      props.setDroneLog((prevStates) => {
                                        const {
                                          ['drnLog' + e.log_sn]: _,
                                          ...rest
                                        } = prevStates;
                                        return { ...rest };
                                      });

                                      props.setStates((prevStates) => {
                                        const newState = Object.keys(
                                          prevStates
                                        ).reduce((acc, key) => {
                                          if (!key.endsWith(e.log_sn)) {
                                            acc[key] = prevStates[key];
                                          }
                                          return acc;
                                        }, {});
                                        return newState;
                                      });
                                    }
                                    if (existingLayer8) {
                                      removeLayer(
                                        'csvLayer' + 'drnLog' + e.log_sn
                                      );
                                      props.setCsvLog((prevStates) => {
                                        const {
                                          ['drnLog' + e.log_sn]: _,
                                          ...rest
                                        } = prevStates;
                                        return { ...rest };
                                      });

                                      props.setCsvStates((prevStates) => {
                                        const {
                                          ['csvLayer' + 'drnLog' + e.log_sn]: _,
                                          ...rest
                                        } = prevStates;
                                        return { ...rest };
                                      });
                                    }
                                  } else if (
                                    props.curActiveLayer &&
                                    props.curActiveLayer.layerType === 4
                                  ) {
                                    if (
                                      e.frmwkt_req_id ==
                                      props.curActiveLayer.frmwkt_req_id
                                    ) {
                                      props.curActiveLayerHandler();
                                    }
                                  } else if (
                                    props.curActiveLayer &&
                                    props.curActiveLayer.layerType === 6
                                  ) {
                                    if (
                                      e.frmwkt_req_id ==
                                      props.curActiveLayer.frmwkt_req_id
                                    ) {
                                      props.curActiveLayerHandler();
                                    }
                                  }
                                  props.setVisibleDrnLog(false);
                                  props.layerDel(i);
                                  props.delModeHandler(i);
                                  props.setClickValue('');
                                  props.setClickNum();
                                  props.setShowChart(false);
                                  props.setNoChart(false);
                                  props.setReadyChart(false);
                                  props.setReadyCSVChart(false);
                                }}
                              ></i>
                            </div>
                          </div>

                          <div>
                            {props.droneLog[e.meta_frmwrk_id] ? (
                              <div className="li-row">
                                <div
                                  className={
                                    `layerBtn ${
                                      props.states[
                                        'missionLayer' + e.meta_frmwrk_id
                                      ]
                                        ? ''
                                        : 'active'
                                    } ` + e.meta_frmwrk_id
                                  }
                                  onClick={() => {
                                    handleClick(
                                      'missionLayer' + e.meta_frmwrk_id
                                    );
                                    layerOnOff(
                                      'missionLayer' + e.meta_frmwrk_id
                                    );
                                  }}
                                >
                                  계획 경로
                                </div>
                                <div
                                  className={`layerBtn ${
                                    props.states['autoLayer' + e.meta_frmwrk_id]
                                      ? ''
                                      : 'active'
                                  }`}
                                  onClick={() => {
                                    handleClick('autoLayer' + e.meta_frmwrk_id);
                                    layerOnOff('autoLayer' + e.meta_frmwrk_id);
                                  }}
                                >
                                  자동 경로
                                </div>
                                <div
                                  className={`layerBtn ${
                                    props.states[
                                      'manualLayer' + e.meta_frmwrk_id
                                    ]
                                      ? ''
                                      : 'active'
                                  }`}
                                  onClick={() => {
                                    handleClick(
                                      'manualLayer' + e.meta_frmwrk_id
                                    );
                                    layerOnOff(
                                      'manualLayer' + e.meta_frmwrk_id
                                    );
                                  }}
                                >
                                  수동 경로
                                </div>
                              </div>
                            ) : null}
                            {props.csvLog[e.meta_frmwrk_id] ? (
                              <div className="li-row">
                                <div
                                  style={{ width: '95%' }}
                                  className={
                                    `layerBtn ${
                                      props.csvStates[
                                        'csvLayer' + e.meta_frmwrk_id
                                      ]
                                        ? ''
                                        : 'active'
                                    } ` + e.meta_frmwrk_id
                                  }
                                  onClick={() => {
                                    csvHandleClick(
                                      'csvLayer' + e.meta_frmwrk_id
                                    );
                                    csvLayerOnOff(
                                      'csvLayer' + e.meta_frmwrk_id
                                    );
                                  }}
                                >
                                  비행 경로
                                </div>
                              </div>
                            ) : null}
                            {props.droneLog['drnLog' + e.log_sn] ? (
                              <div className="li-row">
                                <div
                                  className={`layerBtn ${
                                    props.states[
                                      'missionLayer' + 'drnLog' + e.log_sn
                                    ]
                                      ? ''
                                      : 'active'
                                  }`}
                                  onClick={() => {
                                    handleClick(
                                      'missionLayer' + 'drnLog' + e.log_sn
                                    );
                                    layerOnOff(
                                      'missionLayer' + 'drnLog' + e.log_sn
                                    );
                                  }}
                                >
                                  계획 경로
                                </div>
                                <div
                                  className={`layerBtn ${
                                    props.states[
                                      'autoLayer' + 'drnLog' + e.log_sn
                                    ]
                                      ? ''
                                      : 'active'
                                  }`}
                                  onClick={() => {
                                    handleClick(
                                      'autoLayer' + 'drnLog' + e.log_sn
                                    );
                                    layerOnOff(
                                      'autoLayer' + 'drnLog' + e.log_sn
                                    );
                                  }}
                                >
                                  자동 경로
                                </div>
                                <div
                                  className={`layerBtn ${
                                    props.states[
                                      'manualLayer' + 'drnLog' + e.log_sn
                                    ]
                                      ? ''
                                      : 'active'
                                  }`}
                                  onClick={() => {
                                    handleClick(
                                      'manualLayer' + 'drnLog' + e.log_sn
                                    );
                                    layerOnOff(
                                      'manualLayer' + 'drnLog' + e.log_sn
                                    );
                                  }}
                                >
                                  수동 경로
                                </div>
                              </div>
                            ) : null}

                            {props.csvLog['drnLog' + e.log_sn] ? (
                              <div className="li-row">
                                <div
                                  style={{ width: '95%' }}
                                  className={
                                    `layerBtn ${
                                      props.csvStates[
                                        'csvLayer' + 'drnLog' + e.log_sn
                                      ]
                                        ? ''
                                        : 'active'
                                    } ` + e.log_sn
                                  }
                                  onClick={() => {
                                    csvHandleClick(
                                      'csvLayer' + 'drnLog' + e.log_sn
                                    );
                                    csvLayerOnOff(
                                      'csvLayer' + 'drnLog' + e.log_sn
                                    );
                                  }}
                                >
                                  비행 경로
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </li>
                      </React.Fragment>
                    );
                  })}
              </ol>
            </ReactDragListView>
          </div>
        </div>

        {/* <div
          className={`main-button ${!props.mainHidden ? 'typeA' : 'typeB'}`}
          onClick={() => {
            props.setMainHidden(!props.mainHidden);
            // if(subHidden){props.setSubHidden(true);} //
            // else if(!mainHidden){setMainHidden(false);}
            // else if(mainHidden){setMainHidden(true);}
          }}
        ></div> */}

        {showList ? (
          <Modal
            show={showList}
            onCancel={() => {
              setShowList(false);
            }}
            header="태그"
            icon={
              <i
                className="code x icon"
                onClick={() => {
                  setShowList(false);
                }}
              />
            }
            className={'themeType'}
            isMobile={props.isMobile}
          >
            <div className="themeType-modal-container">
              <div className="themeType-text">※ 재배현황</div>
              <div className="contents-row group">
                <div className="themeType-item contents-row">
                  <Checkbox
                    label="정식 시기"
                    value={2}
                    onChange={handleCheck}
                    checked={selectTycd.includes(2) ? true : false}
                  ></Checkbox>
                </div>
                <div className="themeType-item contents-row">
                  <Checkbox
                    label="작물 구분"
                    value={3}
                    onChange={handleCheck}
                    checked={selectTycd.includes(3) ? true : false}
                  ></Checkbox>
                </div>
                <div className="themeType-item contents-row">
                  <Checkbox
                    label="생육 현황"
                    value={4}
                    onChange={handleCheck}
                    checked={selectTycd.includes(4) ? true : false}
                  ></Checkbox>
                </div>
                <div className="themeType-item contents-row">
                  <Checkbox
                    label="생육 이상"
                    value={5}
                    onChange={handleCheck}
                    checked={selectTycd.includes(5) ? true : false}
                  ></Checkbox>
                </div>
              </div>

              <div className="space-flexer"></div>

              <div className="themeType-text">※ 재배관리</div>
              <div className="contents-row group">
                <div className="themeType-item contents-row">
                  <Checkbox
                    label="수비 추천"
                    value={6}
                    onChange={handleCheck}
                    checked={selectTycd.includes(6) ? true : false}
                  ></Checkbox>
                </div>
                <div className="themeType-item contents-row">
                  <Checkbox
                    label="방제 추천"
                    value={7}
                    onChange={handleCheck}
                    checked={selectTycd.includes(7) ? true : false}
                  ></Checkbox>
                </div>
              </div>

              <div className="space-flexer"></div>
            </div>
            <div className="button-group">
              {/* <button className="gc-btn cancel" onClick={()=>{setShowList(false);}}>추가</button> */}
              <button className="gc-btn register" onClick={themeTypeReg}>
                확인
              </button>
            </div>
          </Modal>
        ) : null}

        <Popup
          show={showPopup}
          onCancel={() => setShowPopup(false)}
          header="알림"
          icon={
            <i className="code x icon" onClick={() => setShowPopup(false)} />
          }
        >
          <div className="modal-info">
            <p>{popupMsg}</p>
            <button className="close_btn" onClick={() => setShowPopup(false)}>
              닫기
            </button>
          </div>
        </Popup>

        {props.requestFarmwork ? (
          <FarmworkReqeust
            onFarmmapHandler={props.onFarmmapHandler}
            farmworkListHandler={props.farmworkListHandler}
            selectItrst={props.selectItrst}
            selectedFarm={props.selectedFarm}
            setSelectedFarm={props.setSelectedFarm}
            requestFarmwork={props.requestFarmwork}
            setRequestFarmwork={props.setRequestFarmwork}
            farmworkCoord={props.farmworkCoord}
            setShowTheme={props.setShowTheme}
            // activeFmMenuHandler={props.activeFmMenuHandler}
            farmworkSelect={props.farmworkSelect}
            farmworkSelectHandler={props.farmworkSelectHandler}
            searchFarmworkList={searchFarmworkList}
            handleCoord={props.handleCoord}
            loading={props.loading}
            setLoading={props.setLoading}
            mapRef={props.mapRef}
            clickFarmRequest={props.clickFarmRequest}
            setClickFarmRequst={props.setClickFarmRequst}
            farmworkDummyArr={farmworkDummyArr}
            setFarmworkDummyArr={setFarmworkDummyArr}
            itrstZoneToggle={props.itrstZoneToggle}
            setItrstZoneToggle={props.setItrstZoneToggle}
            activeLayerList={props.activeLayerList}
            mainHidden={props.mainHidden}
            setMainHidden={props.setMainHidden}
          ></FarmworkReqeust>
        ) : null}

        {showDeleteFarmworkModal ? (
          <FarmworkDelete
            selectedFrmwrkId={selectedFrmwrkId}
            setSelectedFrmwrkId={setSelectedFrmwrkId}
            selectedMetaId={selectedMetaId}
            setSelectedMetaId={setSelectedMetaId}
            showDeleteFarmworkModal={showDeleteFarmworkModal}
            setShowDeleteFarmworkModal={setShowDeleteFarmworkModal}
            setLoading={props.setLoading}
            // 농작업 리스트
            selectItrst={props.selectItrst}
            farmworkListHandler={props.farmworkListHandler}
            setTotalCount={setTotalCount}
            setTotalPages={setTotalPages}
            setCurrentPage={setCurrentPage}
            setSearchFlag={props.setSearchFlag}
          ></FarmworkDelete>
        ) : null}

        <NewFarmworkRequest
          registFarmwork={props.registFarmwork}
          setRegistFarmwork={props.setRegistFarmwork}
          onFarmmapHandler={props.onFarmmapHandler}
          selectItrst={props.selectItrst}
          selectedFarm={props.selectedFarm}
          setSelectedFarm={props.setSelectedFarm}
          farmworkCoord={props.farmworkCoord}
          // activeFmMenuHandler={props.activeFmMenuHandler}
          farmworkSelectHandler={props.farmworkSelectHandler}
          searchFarmworkSelectList={searchFarmworkSelectList}
          handleCoord={props.handleCoord}
          loading={props.loading}
          setLoading={props.setLoading}
          mapRef={props.mapRef}
          farmSelectHandler={props.farmSelectHandler}
          checkInst={props.checkInst}
          setCheckInst={props.setCheckInst}
          setSearchFlag={props.setSearchFlag}
        ></NewFarmworkRequest>

        {/* <InstFarmwork
          registFarmwork={props.registFarmwork}
          setRegistFarmwork={props.setRegistFarmwork}
          showRegistInstModal={props.showRegistInstModal}
          setShowRegistInstModal={props.setShowRegistInstModal}
          onFarmmapHandler={props.onFarmmapHandler}
          farmworkListHandler={props.farmworkListHandler}
          selectItrst={props.selectItrst}
          selectedFarm={props.selectedFarm}
          setSelectedFarm={props.setSelectedFarm}
          requestFarmwork={props.requestFarmwork}
          setRequestFarmwork={props.setRequestFarmwork}
          farmworkCoord={props.farmworkCoord}
          setShowTheme={props.setShowTheme}
          // activeFmMenuHandler={props.activeFmMenuHandler}
          farmworkSelectHandler={props.farmworkSelectHandler}
          searchFarmworkList={searchFarmworkList}
          handleCoord={props.handleCoord}
          loading={props.loading}
          setLoading={props.setLoading}
          mapRef={props.mapRef}
          farmSelectHandler={props.farmSelectHandler}
          checkInst={props.checkInst}
          setCheckInst={props.setCheckInst}
          curActiveLayer={props.curActiveLayer}
          searchFarmworkListforInst={searchFarmworkListforInst}
        ></InstFarmwork>

        <InstRequestList
          checkInst={props.checkInst}
          setCheckInst={props.setCheckInst}
          curActiveLayer={props.curActiveLayer}
          curActiveLayerHandler={props.curActiveLayerHandler}
          // 농작업 요청(경작자)
          showFarmworkSelect={props.showFarmworkSelect}
          setShowFarmworkSelect={props.setShowFarmworkSelect}
          searchFlag={props.searchFlag}
          setSearchFlag={props.setSearchFlag}
        ></InstRequestList>

        <InstFarmworkFail
          showFarmworkFail={showFarmworkFail}
          setShowFarmworkFail={setShowFarmworkFail}
          curActiveLayer={props.curActiveLayer}
          curActiveLayerHandler={props.curActiveLayerHandler}
          selectItrst={props.selectItrst}
          loading={props.loading}
          setLoading={props.setLoading}
        ></InstFarmworkFail> */}

        <Modal
          show={farmworkUpload}
          header="수행 결과 업로드"
          icon={
            <i
              className="code x icon"
              onClick={() => {
                setFarmworkUpload(false);
              }}
            />
          }
          className={`farmworkUpload`}
        >
          <div className="farmworkUpload-modal-container">
            <div className="contents-row">
              <div className="input-group contents-col full-width">
                <div className="title-text">기타 의견</div>
                <div className="contents-row">
                  <textarea
                    style={{
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 500,
                      fontSize: '15px',
                      textAlign: 'left',
                      padding: '8px',
                      color: 'var(--default-text-color)',
                      border: '1px solid rgba(0, 0, 0, 0.2)',
                      borderRadius: '5px',
                      height: '40px',
                      margin: '0 15px',
                      minHeight: '100px',
                      lineHeight: '140%',
                      resize: 'none',
                    }}
                    className="full-width"
                    value={opinion}
                    onChange={handleOpinion}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="contents-row">
              <div className="input-group contents-col full-width">
                <div className="title-text">
                  <span className="required-marker">*</span>드론 로그 파일
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      flexWrap: 'wrap',
                      width: '100%',
                      alignContent: 'center',
                    }}
                  >
                    {fileLoading ? (
                      <>
                        <Spinner />
                        <div>처리중입니다.</div>
                      </>
                    ) : (
                      <>
                        {/* 안보여지는 부분 */}
                        <form
                          onSubmit={handleSubmit}
                          style={{ display: 'none' }}
                        >
                          <input
                            type="file"
                            name="logFile"
                            ref={inputFile}
                            onChange={fileChange}
                            accept={'.log, .bin, .csv'} //.log, .bin .csv
                            style={{ display: 'none' }}
                          ></input>
                          <input
                            type="submit"
                            ref={submitButton}
                            style={{ display: 'none' }}
                          />
                        </form>
                        {/* 보여지는 부분 */}
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 10px 0 10px',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                            borderRadius: '5px',
                            width: '95%',
                            height: '40px',
                            justifyContent: 'space-between',
                            fontFamily: 'Noto Sans KR',
                          }}
                        >
                          <div style={{ fontSize: '16px' }}>{fileName}</div>
                          {beforeFileSelect ? (
                            <FileInputButton
                              style={{
                                width: '94px',
                                height: '28px',
                                fontFamily: 'Noto Sans KR',
                                fontSize: '15px',
                              }}
                              label="파일 선택"
                              onClick={() => handleButton()}
                            />
                          ) : (
                            <div className="x-icon" onClick={cancel}></div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="button-group flex-center">
              <button
                className="gc-btn farmworkSelect-cancel"
                onClick={() => {
                  setFarmworkUpload(false);
                  setFiles();
                  setFileName();
                  setBeforeFileSelect(true);
                  props.setActChart(false);
                }}
              >
                취소
              </button>
              <button
                className="gc-btn farmworkSelect-register"
                onClick={(e) => {
                  // handleUpload();
                  handleSubmit(e);
                }}
              >
                등록
              </button>
            </div>
          </div>
        </Modal>

        <Popup
          show={showFilePopup}
          onCancel={() => setShowFilePopup(false)}
          header="알림"
          icon={
            <i
              className="code x icon"
              onClick={() => setShowFilePopup(false)}
            />
          }
        >
          <div className="modal-info">
            <p>{filePopupMsg}</p>
            <button
              className="close_btn"
              onClick={() => {
                setShowFilePopup(false);
                setFarmworkUpload(false);
                props.curActiveLayerHandler();
              }}
            >
              닫기
            </button>
          </div>
        </Popup>
      </div>
    </>
  );
}

export default SideBar;
