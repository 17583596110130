import './Header.css';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Router,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
} from 'react-router-dom';

import Modal from '../../../shared/components/uiElements/Modal';
import Popup from '../uiElements/Popup';
import axios from 'axios';
import turf from 'turf';
import proj4 from 'proj4';

import ZoneSearchbar from '../zoneSearchbar/ZoneSearchbar.js';
import SubMenu from '../subMenu/subMenu.js';
import useResponsive from '../../hooks/screenSize-hook.js';
import BulletinBoardList from '../../../routes/bbs/pages/bulletinBoardList.js';

import { Icon } from '@iconify/react';
import { isMobile } from 'react-device-detect';
// import AliceCarousel from 'react-alice-carousel';

proj4.defs(
  'EPSG:5179',
  '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');

function Header(props) {
  const APIToken = localStorage.getItem('login-token');
  const userName = localStorage.getItem('user-name');
  const userID = localStorage.getItem('user-id');
  const userLevel = localStorage.getItem('user-level');

  const [count, setCount] = useState(1);
  const handleClick = () => {
    setCount((prevCount) => prevCount * -1);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    props.logout();
    navigate('/');
    window.location.reload();
    props.setShowSubmenu(false);
  };

  const [showZoneListPopup, setShowZoneListPopup] = useState(false); //관심구역 mobile modal
  const [selZoneNm, setSelZoneNm] = useState('관심구역명을 입력하세요');
  const [activeHeader, setActiveHeader] = useState(null);

  const [headerPosition, setHeaderPosition] = useState(345); //헤더 위치

  const [searchZoneName, setSearchZoneName] = useState(''); //관심구역명 검색
  const [years, setYears] = useState([]); //연도정보 Select option
  const [selectedYears, setSelectedYears] = useState([]); //연도정보 checkbox
  const [expanded, setExpanded] = useState(false); //연도정보 dropdown control
  const [tags, setTags] = useState([]); //태그 Select option
  const [showPopup, setShowPopup] = useState(false);

  const handleZoneNm = (event) => {
    setSelZoneNm(event);
  };

  useEffect(() => {
    if (props.isMobile) {
      props.setMobileActiveMenu(0);
    }
  }, []);

  useEffect(() => {
    if (props.selectItrst) {
      if (props.selectItrst.user_id === userID) {
        props.setIsMine(true);
      } else {
        props.setIsMine(false);
      }
    }
  }, [props.selectItrst]);

  // 헤더 위치
  useEffect(() => {
    if (props.mainHidden) {
      setHeaderPosition(345);
    } else {
      setHeaderPosition(45);
    }
  }, [props.mainHidden]);

  // const searchZoneList = () => {
  //   if (selectedYears) {
  //     props.handleCheckYear(selectedYears);
  //   }
  //   if (selectedTag) {
  //     props.handleCheckTag(selectedTag);
  //   }

  //   if (searchZoneName.length > 0) {
  //     props.handleMyZoneSearchName(searchZoneName);
  //     props.handleMyZoneSearchType('itrst_zone_nm');
  //   } else {
  //     props.handleMyZoneSearchName('');
  //   }
  // };

  // 관심구역 모바일 접기 더보기
  const [showZoneListAll, setShowZoneListAll] = useState(false);
  const zoneListCount = showZoneListAll
    ? props.myZoneList && props.myZoneList.length
    : 6;

  const toggleShowZoneListAll = () => {
    setShowZoneListAll(!showZoneListAll);
  };

  // 태블릿 세로일 때
  const { isTabletHeight } = useResponsive();
  const [maxHeight, setMaxHeight] = useState(400);

   // 게시판 랜더링 함수
 const renderMenuItem = (bbsType, iconType, label, postType, menuPath) => {
  
  const isActive = props.activeHeaderMenu === menuPath;

  return (
    <div className={`bbs-${bbsType} ${isActive ? 'active' : ''}`}>
      <div
        className={`bbs-link-text ${props.isMine ? '' : 'hidden'}`}
        onClick={(e) => {
          if(props.farmworkSelect){
            setShowPopup(false);
        } else {
          props.handleIsBbs_state(bbsType);
          props.handlePostTy(postType);
          props.setShowBulletinBoard(false);
          props.handleHeader_state(1);
          if (bbsType === 'notice' || (bbsType === 'bulletinBoard' && props.selectItrst)) {
            // 공지사항이거나, 관심구역이 설정된 게시판일 경우
            props.handleShowBbsList(true);
          } else if (bbsType === 'bulletinBoard' && !props.selectItrst) {
            // 관심구역이 설정되지 않은 게시판일 경우
            setShowPopup(true);
            props.handleShowBbsList(false);
          }
         }
        }}
      >
        <Icon
          icon={iconType}
          width="1.2rem"
          height="1.2rem"
          style={{
            color: isActive ? '#216aa1' : null,
          }}
        />
        {!props.isTablet && (
          <span
            className="text"
            style={{
              color: isActive ? '#216aa1' : null,
            }}
          >
            {label}
          </span>
        )}
      </div>
    </div>
    );
  };

  // useEffect(() => {
  //   const calculateHeight = () => {
  //     console.log("ttt");

  //     const headerMenuDiv = document.querySelector('.menu');
  //     const headerBbsMenuDiv = document.querySelector('.bbs-menu');

  //     if (headerBbsMenuDiv) {
  //       console.log("headerMenuDiv"+headerBbsMenuDiv);

  //       //setHeaderPosition

  //       // headerMenuDiv.style.height = `${newHeight}px`;
  //       headerMenuDiv.style.display = 'flex';
  //       headerMenuDiv.style.position = 'absolute';
  //       headerMenuDiv.style.left = '316px';
  //       headerMenuDiv.style.top = '140%';
  //     }
  //   };
  //   if (!isTabletHeight) {
  //     setTimeout(calculateHeight, 0);
  //   }
  // }, [isTabletHeight]);

  return (
    <div className="app-container header-container">
      <header>
        {props.isMobile !== true ? (
          <div className="title-container">
            <div
              style={{
                display: 'flex',
                position: 'relative',
                flex: '9 1 0%',
                gap: '10px',
                left: `${headerPosition}px`,
                transition: 'left 0.3s ease-out',
              }}
            >
              {props.isLoggedIn &&
                !props.showThemeMapAdd && (
                  <div className="menu"
                  onClick={() => {
                    if(props.farmworkSelect){
                      props.setActiveHeaderMenu('/farmwork');
                    }  
                  }}
                  >
                    <div
                      className="main-menu"
                      onClick={() => {
                        props.handleShowImage(true);
                        props.handleShowTheme(false);
                        props.handleShowFarmwork(false);
                        props.handleShowDrnLog(false);
                        props.handleShowFarmworkSelect(false);
                        props.handleShowInstFarmwork(false);

                        props.handleSideBar(true);
                        props.handleShowThemeMapAdd(false);
                        props.handleGuideLine(false);
                        if (props.sidebarHiddenFlag) {
                          props.setMainHidden(true);
                      }
                      }}
                    >
                      <Link
                        to={'/home'}
                        onClick={() => {
                          props.setActiveHeaderMenu('/image');
                          props.handleHeader_state(0);
                        }}
                        className={`sub-link-text ${
                          props.activeHeaderMenu === '/image' ? 'active' : ''
                        }`}
                      >
                        <Icon
                          className="icon"
                          icon="icon-park-outline:add-picture"
                          width="1.2rem"
                          height="1.2rem"
                        />
                        {!props.isTablet && (
                          <span className="text">관측영상</span>
                        )}
                      </Link>
                    </div>
                    <div
                      className="main-menu"
                      onClick={() => {
                        props.handleShowImage(false);
                        props.handleShowTheme(true);
                        props.handleShowFarmwork(false);
                        props.handleShowDrnLog(false);
                        props.handleShowFarmworkSelect(false);
                        props.handleShowInstFarmwork(false);

                        props.handleSideBar(true);
                        props.handleShowThemeMapAdd(false);
                        props.handleGuideLine(false);
                        if (props.sidebarHiddenFlag) {
                          props.setMainHidden(true);
                      }
                      }}
                    >
                      <Link
                        to={'/home'}
                        onClick={() => {
                          props.setActiveHeaderMenu('/theme');
                        }}
                        className={`sub-link-text ${
                          props.activeHeaderMenu === '/theme' ? 'active' : ''
                        }`}
                      >
                        <Icon
                          className="icon"
                          icon="fa-solid:chart-area"
                          width="1.2rem"
                          height="1.2rem"
                        />
                        {!props.isTablet && (
                          <span className="text">주제도</span>
                        )}
                      </Link>
                    </div>
                    <div
                      className="main-menu"
                      onClick={() => {
                        props.handleShowImage(false);
                        props.handleShowTheme(false);
                        props.handleShowFarmwork(true);
                        props.handleShowDrnLog(false);
                        props.handleShowFarmworkSelect(false);
                        props.handleShowInstFarmwork(false);

                        props.handleSideBar(true);
                        props.handleShowThemeMapAdd(false);
                        props.handleGuideLine(false);

                        props.handleHeader_state(0);

                        if (props.sidebarHiddenFlag) {
                          props.setMainHidden(true);
                      }
                      }}
                    >
                      <Link
                        to={'/home'}
                        onClick={() => {
                          props.setActiveHeaderMenu('/farmwork');
                        }}
                        className={`sub-link-text ${
                          props.activeHeaderMenu === '/farmwork' ? 'active' : ''
                        }`}
                      >
                        <Icon
                          className="icon"
                          icon="mdi:farm-home"
                          width="1.2rem"
                          height="1.2rem"
                        />
                        {!props.isTablet && (
                          <span className="text">농작업</span>
                        )}
                      </Link>
                    </div>

                    {/* <li
             className="main-menu"
             onMouseEnter={() => handleMouseEnter("header2")}
             onMouseLeave={handleMouseLeave}
           >
             <a href="#" className="main-link-text">
               농작업
             </a>
             {
               <div
                 className={`side-box ${
                   activeHeader === "header2" ? "visible" : "hidden"
                 }`}
               >
                 <ul className="sub-menu">
                   <li
                     className="image"
                     onClick={() => {
                       props.handleShowImage(false);
                       props.handleShowTheme(false);
                       props.handleShowFarmwork(true);
                       props.handleShowDrnLog(false);
                       props.handleShowFarmworkSelect(false);
                       props.handleShowInstFarmwork(false);

                       props.handleSideBar(true);
                       props.handleShowThemeMapAdd(false);
                       props.handleGuideLine(false);

                       props.handleHeader_state(false);
                     }}
                   >
                     <Link
                       to={"/home"}
                       onClick={() => {
                         props.setActiveHeaderMenu("/farmwork");
                       }}
                       className="sub-link-text"
                     >
                       농작업 조회
                     </Link>
                   </li>

                   // 새로 개발될 부분 (농작업 요청)
                   <li
                     className="theme"
                     onClick={() => {
                       props.handleShowImage(false);
                       props.handleShowTheme(false);
                       props.handleShowFarmwork(false);
                       props.handleShowDrnLog(false);
                       props.handleShowFarmworkSelect(true);
                       props.handleShowInstFarmwork(false);

                       props.handleSideBar(true);
                       props.handleShowThemeMapAdd(false);
                       props.handleGuideLine(false);

                       props.handleHeader_state(false);
                     }}
                   >
                     <Link
                       to={"/home"}
                       onClick={() => {
                         props.setActiveHeaderMenu("/farmworkSelect");
                       }}
                       className="sub-link-text"
                     >
                       농작업 요청
                     </Link>
                   </li>

                   // 새로 개발될 부분 (농작업 지원)
                   <li
                     className="theme"
                     onClick={() => {
                       props.handleShowImage(false);
                       props.handleShowTheme(false);
                       props.handleShowFarmwork(false);
                       props.handleShowDrnLog(false);
                       props.handleShowFarmworkSelect(false);
                       props.handleShowInstFarmwork(true);

                       props.handleSideBar(true);
                       props.handleShowThemeMapAdd(false);
                       props.handleGuideLine(false);

                       props.handleHeader_state(false);
                     }}
                   >
                     <Link
                       to={"/home"}
                       onClick={() => {
                         props.setActiveHeaderMenu("/instFarmwork");
                       }}
                       className="sub-link-text"
                     >
                       농작업 지원
                     </Link>
                   </li>
                 </ul>
               </div>
             }
           </li> */}

                    {/* 새로 개발될 부분 드론 로그 업로드*/}
                    <div
                      className="main-menu"
                      onClick={() => {
                        props.handleShowImage(false);
                        props.handleShowTheme(false);
                        props.handleShowFarmwork(false);
                        props.handleShowDrnLog(true);
                        props.handleShowFarmworkSelect(false);
                        props.handleShowInstFarmwork(false);

                        props.handleSideBar(true);
                        props.handleShowThemeMapAdd(false);
                        props.handleGuideLine(false);

                        props.handleHeader_state(0);

                        if (props.sidebarHiddenFlag) {
                          props.setMainHidden(true);
                      }
                      }}
                    >
                      <Link
                        to={'/home'}
                        onClick={() => {
                          props.setActiveHeaderMenu('/drnLog');
                        }}
                        className={`sub-link-text ${
                          props.activeHeaderMenu === '/drnLog' ? 'active' : ''
                        }`}
                      >
                        <Icon
                          icon="eos-icons:drone"
                          width="1.4rem"
                          height="1.4rem"
                        />
                        {!props.isTablet && (
                          <span className="text">드론로그</span>
                        )}
                      </Link>
           
                    </div>
                  </div>
                )}

          {props.isLoggedIn && !props.showThemeMapAdd && (
            <div className="bbs-menu">
              {renderMenuItem('notice', 'clarity:bell-solid', '공지사항', 0, '/notice')}
              {renderMenuItem('bulletinBoard', 'bxs:edit', '게시판', 4, '/board')}
            </div>
          )}

          {props.postTy === 0 && (
            <BulletinBoardList
              type="notice"
              postTy={props.postTy}
              setPostTy={props.setPostTy}
              headerState={props.headerState}
              handleShowBbsList={props.handleShowBbsList}
              setShowBbsList={props.setShowBbsList}
              showBbsList={props.showBbsList}
              isBbs_state={props.isBbs_state}
              selectItrst={props.selectItrst}
              showBulletinBoard={props.showBulletinBoard}
              isMobile={props.isMobile}
              setShowBulletinBoard={props.setShowBulletinBoard}
              setActiveBbs={props.setActiveBbs}
              activeBbs={props.activeBbs}
              handleIsBbs_state={props.handleIsBbs_state}
            />
          )}

          {props.postTy <= 4 && props.postTy > 0 && (
            <BulletinBoardList
              type="board"
              postTy={props.postTy}
              setPostTy={props.setPostTy}
              handleShowBbsList={props.handleShowBbsList}
              setShowBbsList={props.setShowBbsList}
              showBbsList={props.showBbsList}
              isBbs_state={props.isBbs_state}
              selectItrst={props.selectItrst}
              showBulletinBoard={props.showBulletinBoard}
              isMobile={props.isMobile}
              setShowBulletinBoard={props.setShowBulletinBoard}
              setActiveBbs={props.setActiveBbs}
              activeBbs={props.activeBbs}
              handleIsBbs_state={props.handleIsBbs_state}
            />
          )}
            </div>

            {/* searchbar */}
            <ZoneSearchbar
              isLoggedIn={props.isLoggedIn}
              isMobile={props.isMobile}
              isTablet={props.isTablet}
              showThemeMapAdd={props.showThemeMapAdd}
              setShowThemeMapAdd={props.setShowThemeMapAdd}
              showZoneListPopup={showZoneListPopup}
              setShowZoneListPopup={setShowZoneListPopup}
              selectItrst={props.selectItrst}
              setSelectItrst={props.setSelectItrst}
              handleSelectItrst={props.handleSelectItrst}
              myZoneList={props.myZoneList}
              setMyZoneList={props.setMyZoneList}
              handleMyZoneList={props.handleMyZoneList}
              searchMyZoneList={props.searchMyZoneList}
              handleSearchMyZoneList={props.handleSearchMyZoneList}
              interactCoord={props.interactCoord}
              handleCoord={props.handleCoord}
              isMine={props.isMine}
              setIsMine={props.setIsMine}
              showMng={props.showMng}
              handleShowMng={props.handleShowMng}
              mainHidden={props.mainHidden}
              setMainHidden={props.setMainHidden}
              farmworkSelect={props.farmworkSelect}
              setFarmworkSelect={props.setFarmworkSelect}
              farmworkSelectHandler={props.farmworkSelectHandler}
            />
          </div>
        ) : (
       
          // {props.isLoggedIn && (
       
          <div className="mobile-title-container">
          {props.isLoggedIn && (
            <div className="mobile-search-input-container">
              <div className="selZoneNm-mobile .text"  onClick={() => setShowZoneListPopup(true)} >        <Icon
                icon='ic:round-search'
                width='1rem'
                height='1rem'
              />{selZoneNm}
              {/* <i className="fas fa-sort-down"></i> */}
      
              </div>
   

              {/* mobile 회원정보/로그아웃 */}
              <div className="desktop-view">
                <div
                className="contents-row userName"
                onClick={() => {
                  props.setShowSubmenu(!props.showSubmenu);
                }}
                style={{alignItems:'center'}}
                >
                 
                 <Icon icon='material-symbols:person' width='1.5rem' height='1.5rem' />
                  <div className="userNm">{userName} 님 </div>
                  {/* <Icon icon='material-symbols:person' width='1.2rem' height='1.2rem' /> */}
                </div>
                <div className={`submenu ${props.showSubmenu ? "active" : ""}`}>
          
                  <div
                    className="item"
                    onClick={() => {
                      props.handleShowProfile(true);
                    }}
                  >
                    
                  <div className="userinfo"></div>
                    <div className="text">
                      회원정보
                    </div>
                  </div>
       
                  {localStorage.getItem("user-level") >= 10 && props.isMobile ? (
                    <div
                      className="item"
                      onClick={() => {
                        props.setShowSubmenu(false);
                      }}
                    >
                      <div className="adminmode"></div>
                      <div className="text">
                        관리자 모드
                      </div>
                    </div>
                  ) : null}
                  <div className="item" onClick={handleLogout}>
                    <div className="logout"></div>
                    <div className="text">로그아웃</div> 
                  </div>
                </div>
              </div>
            </div>

            )}

            {showZoneListPopup ? (
              <Modal
                show={showZoneListPopup}
                onCancel={() => setShowZoneListPopup(false)}
                header="알림"
                icon={
                  <i
                    className="code x icon"
                    onClick={() => setShowZoneListPopup(false)}
                  />
                }
                className={`zoneListPopup ${props.isMobile ? 'mobile' : ''}`}
             
                isMobile={props.isMobile}
              >
                <div className="header-container mobile">
                  <ZoneSearchbar
                    isLoggedIn={props.isLoggedIn}
                    isMobile={props.isMobile}
                    isTablet={props.isTablet}
                    showThemeMapAdd={props.showThemeMapAdd}
                    setShowThemeMapAdd={props.setShowThemeMapAdd}
                    showZoneListPopup={showZoneListPopup}
                    setShowZoneListPopup={setShowZoneListPopup}
                    selectItrst={props.selectItrst}
                    setSelectItrst={props.setSelectItrst}
                    handleSelectItrst={props.handleSelectItrst}
                    myZoneList={props.myZoneList}
                    setMyZoneList={props.setMyZoneList}
                    handleMyZoneList={props.handleMyZoneList}
                    searchMyZoneList={props.searchMyZoneList}
                    handleSearchMyZoneList={props.handleSearchMyZoneList}
                    interactCoord={props.interactCoord}
                    handleCoord={props.handleCoord}
                    isMine={props.isMine}
                    setIsMine={props.setIsMine}
                    showMng={props.showMng}
                    handleShowMng={props.handleShowMng}
                    handleZoneNm={handleZoneNm}
                  />
                </div>
              </Modal>
            ) : null}
          </div>
            // )}
        )}
      </header>
      
      <Popup
        show={showPopup}
        onCancel={() => setShowPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>관심구역을 설정해주세요</p>
          <button className="close_btn" onClick={() => {
              setShowPopup(false);
            }}>
            닫기
          </button>
        </div>
      </Popup>
    </div>
  );
}

export default Header;

