import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@iconify/react';

import { useDebounce } from '../../hooks/debounce';
import Toolbox from '../toolBox/Toolbox';
import useResponsive from '../../hooks/screenSize-hook';

import './ZoneSearchbar.css';

import axios from 'axios';
import turf from 'turf';
import proj4 from 'proj4';

const ZoneSearchbar = (props) => {
  const APIToken = localStorage.getItem('login-token');
  const userName = localStorage.getItem('user-name');
  const userID = localStorage.getItem('user-id');
  const userLevel = localStorage.getItem('user-level');

  const [searchZoneName, setSearchZoneName] = useState(''); //검색한 관심구역명(input 값)
  const debouncedInput = useDebounce(searchZoneName, 300); // 실시간 검색
  const [isListVisible, setIsListVisible] = useState(false); // 검색창 클릭 시, 리스트 표출 toggle
  const [filteringBoxVisible, setFilteringBoxVisible] = useState(false); // 태그 툴박스
  const [yearList, setYearList] = useState([]); // 검색된 결과의 날짜
  const [tagList, setTagList] = useState([]); // 전체 태그 리스트
  const [selectedTag, setSelectedTag] = useState([]); // 선택된 데이터 (필터시 필요)
  const [selectedYear, setSelectedYear] = useState([]); // 선택된 데이터 (필터시 필요)

  // 태블릿 세로일 때
  const { isTabletWidth } = useResponsive();
  // useEffect(() => {
  //   if (isTabletWidth) {

  //     props.setMainHidden(false);
  //   } else {
  //     props.setMainHidden(true);
  //   }
  // }, [isTabletWidth]);

  // 검색 초기화
  const onClearSearch = () => {
    setSearchZoneName('');
  };

  // 관심구역명 input값
  const handleSearchZoneList = (e) => {
    setSearchZoneName(e.target.value);
  };

  const toggleListVisibility = (isVisible) => {
    setIsListVisible(isVisible);
  };

  const handleItrstResult = (zone) => {
    setSearchZoneName(zone.itrst_zone_nm);
    props.handleSelectItrst(zone);
    toggleListVisibility(false);
    props.setShowZoneListPopup(false);
  };

  // const isDisabled = () => {
  //   if (props.farmworkSelect) {
  //     return true; // FLAG가 TRUE일 경우 disabled
  //   } else {
  //     return false; // FLAG가 FALSE일 경우 enabled
  //   }
  // };

  // 툴박스
  const handleToggleFilteringBox = () => {
    if (!props.isMobile) {
      props.setMainHidden(!(isTabletWidth && !props.isMobile));
    }
    setFilteringBoxVisible(!filteringBoxVisible);
  };

  const selectedYearHandler = (updateFunction) => {
    setSelectedYear((prevSelectedYear) => {
      const updatedYear =
        typeof updateFunction === 'function'
          ? updateFunction(prevSelectedYear)
          : updateFunction;
      return updatedYear;
    });
  };

  const selectedTagHandler = (updateFunction) => {
    setSelectedTag((prevSelectedTag) => {
      const updatedTag =
        typeof updateFunction === 'function'
          ? updateFunction(prevSelectedTag)
          : updateFunction;
      return updatedTag;
    });
  };

  const tagListSort = (list, ascending = true) => {
    return list.sort((a, b) => {
      return ascending ? a.max - b.max : b.max - a.max;
    });
  };

  const yearListSort = (list, ascending = true) => {
    return list.sort((a, b) => {
      return ascending ? a - b : b - a;
    });
  };

  const removeDuplicate = (list) => {
    return Array.from(new Set(list.map((item) => item.yr_info)));
  };

  // mobilel logout
  const navigate = useNavigate();
  const handleLogout = () => {
    props.logout();
    navigate('/');
    window.location.reload();
    props.setShowSubmenu(false);
  };

  // 관심구역 전체 리스트 호출
  const fetchItrestZone = async () => {
    // const APIToken = localStorage.getItem('login-token');
    axios({
      method: 'get',
      url: `/api/zone/list?page=1&perPage=0&onlyAproved=true&`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        if (result.data.total !== 0) {
          props.handleMyZoneList(result.data.data);
          // toggleListVisibility(true);
          setYearList(yearListSort(removeDuplicate(result.data.data), false));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTagList = async () => {
    axios({
      method: 'get',
      url: `/api/tag/list`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        setTagList(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.isLoggedIn) {
      fetchItrestZone();
      fetchTagList();
      if (props.isMobile) {
        toggleListVisibility(true);
      }
    }
  }, [props.isLoggedIn]);

  useEffect(() => {
    const filteredList = props.myZoneList.filter((item) => {
      const matchesInput = item.itrst_zone_nm
        .toLowerCase()
        .includes(debouncedInput.toLowerCase());

      const matchesTag =
        selectedTag.length > 0
          ? selectedTag.some((tag) => item.tag_list.includes(tag))
          : true;

      const matchesYear =
        selectedYear.length > 0
          ? selectedYear.some(
              (year) => parseInt(item.yr_info) === parseInt(year)
            )
          : true;

      return matchesInput && matchesTag && matchesYear;
    });

    props.handleSearchMyZoneList(filteredList);
  }, [debouncedInput, props.myZoneList, selectedTag, selectedYear]);

  useEffect(() => {
    if (props.selectItrst && !props.isMobile) {
      if (userLevel >= 10) {
        props.setIsMine(true);
      } else {
        if (props.selectItrst.user_id === userID) {
          props.setIsMine(true);
        } else {
          props.setIsMine(false);
        }
      }

      const geojsondata = JSON.parse(props.selectItrst.geojson);
      const center = turf.center(geojsondata);
      const bbox = turf.bbox(geojsondata);
      const centerCoord5179 = center.geometry.coordinates;
      const centerCoord4326 = proj4('EPSG:5179', 'EPSG:4326', centerCoord5179);
      if (
        centerCoord5179[0] > 124.0 &&
        centerCoord5179[0] < 132.0 &&
        centerCoord5179[1] > 32.0 &&
        centerCoord5179[1] < 39.0
      ) {
        props.handleCoord({
          centerX: centerCoord5179[0],
          centerY: centerCoord5179[1],
          bbox: bbox,
        });
      }

      if (
        centerCoord4326[0] > 124.0 &&
        centerCoord4326[0] < 132.0 &&
        centerCoord4326[1] > 32.0 &&
        centerCoord4326[1] < 39.0
      ) {
        props.handleCoord({
          centerX: centerCoord4326[0],
          centerY: centerCoord4326[1],
          bbox: bbox,
        });
      }
    }
  }, [props.selectItrst]);

  return (
    <>
      {!props.isMobile ? (
        <div
          className="zone-search-bar"
          style={{ borderRadius: isListVisible ? '8px 8px 0 0' : '8px' }}
        >
          {props.isLoggedIn && !props.showThemeMapAdd ? (
            <div className="sub">
              <div
                className="comboBox"
                onBlur={(e) => {
                  if (filteringBoxVisible) {
                    if (
                      !e.relatedTarget ||
                      !e.currentTarget.contains(e.relatedTarget)
                    ) {
                      toggleListVisibility(false);
                      // setFilteringBoxVisible(false); // Toolbox도 닫기
                    }
                  } else {
                    if (e.relatedTarget !== null) {
                      toggleListVisibility(false);
                    }
                  }
                }}
              >
                <div className="search-input-container">
                  <Icon
                    icon="mage:filter"
                    width="1.0rem"
                    height="1.0rem"
                    // onClick={handleToggleFilteringBox}
                    onClick={(e) => {
                      if (props.farmworkSelect) {
                        e.stopPropagation();
                      } else {
                        handleToggleFilteringBox();
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      flex: '2',
                      margin: '2px',
                      color: filteringBoxVisible ? '#00a2d0' : '#063761',
                    }}
                  />

                  <Icon
                    icon="lets-icons:setting-fill"
                    width="1.0rem"
                    height="1.0rem"
                    // onClick={() => props.handleShowMng(true)}
                    onClick={(e) => {
                      if (props.farmworkSelect) {
                        e.stopPropagation();
                      } else {
                        props.handleShowMng(true);
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      flex: '2',
                      color: props.showMng ? '#00a2d0' : '#063761',
                    }}
                  />

                  <input
                    className="search-text"
                    type="text"
                    placeholder="관심구역명을 입력하세요"
                    style={{
                      border: 'none',
                      flex: '6',
                      padding: '10px 5px',
                      // minWidth: '250px',
                      fontWeight: '700',
                      fontSize: '0.9vw',
                    }}
                    value={searchZoneName}
                    onChange={handleSearchZoneList}
                    onFocus={() => toggleListVisibility(true)}
                    //   onBlur={(e) => {
                    //     const container = e.currentTarget;
                    //     const relatedTarget = e.relatedTarget;

                    //     if (!container.contains(relatedTarget)) {
                    //         toggleListVisibility(false);
                    //     }
                    // }}
                    disabled={props.farmworkSelect}
                  />
                  <div style={{ minWidth: '15px' }}>
                    {searchZoneName && (
                      <Icon
                        icon="iwwa:delete"
                        onClick={onClearSearch}
                        style={{
                          cursor: 'pointer',
                          flex: '2',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      />
                    )}
                  </div>
                </div>

                {isListVisible && (
                  <div className="itrst-result-container">
                    <div className="itrst-search-result">
                      {props.searchMyZoneList.length}개의 관심구역이 검색
                      되었습니다.
                    </div>

                    {props.searchMyZoneList.map((zone, index) => {
                      const tags =
                        typeof zone.tag_list === 'string'
                          ? zone.tag_list
                              .replace(/[\[\]"'\s]/g, '')
                              .split(',')
                              .map((tag) => tag.trim())
                          : [];
                      return (
                        <div
                          key={index}
                          className="itrst-result"
                          onMouseDown={() => {
                            handleItrstResult(zone);
                            props.setMainHidden(true);
                            setFilteringBoxVisible(false);
                            onClearSearch();
                          }}
                        >
                          <div>{zone.itrst_zone_nm}</div>

                          <div>
                            {tags.map(
                              (tag, index) =>
                                tag !== '' && (
                                  <span
                                    className={`gc-btn-tag ${
                                      tags[0] !== '' ? 'tag' : ''
                                    }`}
                                    key={index}
                                  >
                                    {tag}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {filteringBoxVisible && (
                  <Toolbox
                    yearList={yearList}
                    tagList={tagList}
                    handleToggleFilteringBox={handleToggleFilteringBox}
                    selectedTagHandler={selectedTagHandler}
                    selectedYearHandler={selectedYearHandler}
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <div className="zone-search-bar">
            {props.isLoggedIn && !props.showThemeMapAdd ? (
              <div className="sub">
                <div
                  className="comboBox"
                  // onBlur={(e) => {
                  //     if (!e.currentTarget.contains(e.relatedTarget)) {
                  //         toggleListVisibility(false);

                  //     }
                  // }}
                >
                  <div className="search-input-container">
                    <Icon
                      icon="mage:filter"
                      width="1.0rem"
                      height="1.0rem"
                      onClick={handleToggleFilteringBox}
                      // onClick={() => {
                      //   handleToggleFilteringBox();
                      //   toggleListVisibility(true);
                      // }}
                      style={{
                        cursor: 'pointer',
                        flex: '1',
                        margin: '2px',
                        color: filteringBoxVisible ? '#00a2d0' : '#063761',
                      }}
                    />
                    <input
                      className="search-text"
                      type="text"
                      placeholder="관심구역을 검색하세요"
                      style={{
                        border: 'none',
                        flex: '13',
                        padding: '10px 5px',
                        fontWeight: '700',
                      }}
                      value={searchZoneName}
                      onChange={handleSearchZoneList}
                    />
                    <div style={{ minWidth: '15px' }}>
                      {searchZoneName && (
                        <Icon
                          icon="iwwa:delete"
                          onClick={onClearSearch}
                          style={{
                            cursor: 'pointer',
                            flex: '1',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {isListVisible && (
                    <div className="itrst-result-container">
                      <div className="itrst-search-result">
                        {props.searchMyZoneList.length}개의 관심구역이 검색
                        되었습니다.
                      </div>

                      {props.searchMyZoneList.map((zone, index) => {
                        const tags =
                          typeof zone.tag_list === 'string'
                            ? zone.tag_list
                                .replace(/[\[\]"'\s]/g, '')
                                .split(',')
                                .map((tag) => tag.trim())
                            : [];
                        return (
                          <div
                            key={index}
                            className="itrst-result"
                            onClick={() => {
                              if (
                                window.location.pathname === '/profile' ||
                                window.location.pathname === '/adminmode'
                              ) {
                                // navigate("/home");
                              }
                              // props.handleSideBar(true);
                              const geojsondata = JSON.parse(zone.geojson);
                              const center = turf.center(geojsondata);
                              const bbox = turf.bbox(geojsondata);
                              const centerCoord5179 =
                                center.geometry.coordinates;
                              const centerCoord4326 = proj4(
                                'EPSG:5179',
                                'EPSG:4326',
                                centerCoord5179
                              );
                              if (
                                centerCoord5179[0] > 124.0 &&
                                centerCoord5179[0] < 132.0 &&
                                centerCoord5179[1] > 32.0 &&
                                centerCoord5179[1] < 39.0
                              ) {
                                props.handleCoord({
                                  centerX: centerCoord5179[0],
                                  centerY: centerCoord5179[1],
                                  bbox: bbox,
                                });
                              }

                              if (
                                centerCoord4326[0] > 124.0 &&
                                centerCoord4326[0] < 132.0 &&
                                centerCoord4326[1] > 32.0 &&
                                centerCoord4326[1] < 39.0
                              ) {
                                props.handleCoord({
                                  centerX: centerCoord4326[0],
                                  centerY: centerCoord4326[1],
                                  bbox: bbox,
                                });
                              }

                              props.handleSelectItrst(zone);
                              // setSelZoneNm(zone.itrst_zone_nm);
                              props.setShowZoneListPopup(false);
                              props.handleZoneNm(zone.itrst_zone_nm);
                            }}
                          >
                            <div className="contents-row itrst-zone">
                              <div className="itrstNm">
                                {zone.itrst_zone_nm}
                              </div>
                              <div
                                className={`gc-btn zone-btn ${
                                  tagList && tagList.length ? 'ok-tag' : ''
                                } ${
                                  zone.user_id === userID ? 'manage' : 'view'
                                }`}
                              >
                                {zone.user_id === userID ? '관리' : '조회'}
                              </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                              {tags.map(
                                (tag, index) =>
                                  tag !== '' && (
                                    <span
                                      className={`gc-btn-tag ${
                                        tags[0] !== '' ? 'tag' : ''
                                      }`}
                                      key={index}
                                    >
                                      {tag}
                                    </span>
                                  )
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {filteringBoxVisible && (
                    <Toolbox
                      isMobile={props.isMobile}
                      yearList={yearList}
                      tagList={tagList}
                      handleToggleFilteringBox={handleToggleFilteringBox}
                      selectedTagHandler={selectedTagHandler}
                      selectedYearHandler={selectedYearHandler}
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ZoneSearchbar;
