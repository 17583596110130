import React, { useEffect, useState } from 'react';
import './Toolbox.css';
import { Icon } from '@iconify/react';

const Toolbox = ({
  isMobile,
  yearList,
  tagList,
  handleToggleFilteringBox,
  selectedTagHandler,
  selectedYearHandler,
}) => {
  const [selectedYears, setSelectedYears] = useState([]); // 선택된 연도 관리
  const [selectedTags, setSelectedTags] = useState([]); // 선택된 태그 관리

  useEffect(() => {
    // 컴포넌트가 마운트될 때 초기화
    selectedTagHandler([]);
    selectedYearHandler([]);

    // 컴포넌트가 언마운트될 때 초기화
    return () => {
      selectedTagHandler([]);
      selectedYearHandler([]);
    };
  }, []);

  const handleYearClick = (year) => {
    setSelectedYears((prevSelectedYears) => {
      if (prevSelectedYears.includes(year)) {
        // 이미 선택된 경우 제거
        const updatedYears = prevSelectedYears.filter((y) => y !== year);
        selectedYearHandler(updatedYears);
        return updatedYears;
      } else {
        // 선택되지 않은 경우 추가
        const updatedYears = [...prevSelectedYears, year];
        selectedYearHandler(updatedYears);
        return updatedYears;
      }
    });
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tag)) {
        // 이미 선택된 경우 제거
        const updatedTags = prevSelectedTags.filter((t) => t !== tag);
        selectedTagHandler(updatedTags);
        return updatedTags;
      } else {
        // 선택되지 않은 경우 추가
        const updatedTags = [...prevSelectedTags, tag];
        selectedTagHandler(updatedTags);
        return updatedTags;
      }
    });
  };

  const toolboxOverlay = isMobile
    ? 'mobile-toolbox-overlay'
    : 'toolbox-overlay';
  const toolboxModal = isMobile ? 'mobile-toolbox-modal' : 'toolbox-modal';

  return (
    <div className={toolboxOverlay}>
      <div className={toolboxModal} onClick={(e) => e.stopPropagation()}>
        <div className='toolbox-header'>
          <div className='toolbox-title'>연도 정보</div>
          <Icon
            icon='iwwa:delete'
            onClick={handleToggleFilteringBox}
            className='toolbox-close'
          />
        </div>
        <div
          className='divider'
          style={{ height: '1px', backgroundColor: '#ddd', width: '100%' }}
        ></div>

        <div className='toolbox-content'>
          {yearList.map((year, index) => (
            <div
              key={index} // 각 연도를 고유한 key 값으로 사용
              className={`gc-btn-tag ${
                selectedYears.includes(year) ? 'selected' : ''
              }`}
              onClick={() => handleYearClick(year)}
            >
              {year}
            </div>
          ))}
        </div>

        <div className='toolbox-title'>태그</div>
        <div
          className='divider'
          style={{ height: '1px', backgroundColor: '#ddd', width: '100%' }}
        ></div>
        <div className='toolbox-content'>
          {tagList.map((tag, index) => (
            <div
              key={index} // 각 태그를 고유한 key 값으로 사용
              className={`gc-btn-tag ${
                selectedTags.includes(tag.tag_nm) ? 'selected' : ''
              }`}
              onClick={() => handleTagClick(tag.tag_nm)}
            >
              {tag.tag_nm}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Toolbox;
