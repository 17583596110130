import './App.css';

import React, { useState, useCallback, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  useNavigate,
  Routes,
} from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';

import GlobalStyle from './shared/styles/GlobalStyle';

import { AuthContext } from './shared/context/auth-context';

// AXIOS
import axios from 'axios';

// JS
import Loginpage from './routes/user/pages/login';
import Header from './shared/components/header/Header';
import Footer from './shared/components/footer/Footer';
import Home from './shared/map/pages/home';
import Signup from './routes/user/pages/signup';
import Profile from './routes/user/pages/profile';
import Adminmode from './routes/user/pages/adminmode';
// import Posts from './routes/bbs/pages/bbs';
import FindId from './routes/user/pages/findid';
import FindPw from './routes/user/pages/findpw';
import ChangePw from './routes/user/pages/changePw';
import Landing from './shared/map/pages/Landing';
import TermsOfService from './routes/user/pages/TermsOfService';
import BulletinBoardList from './routes/bbs/pages/bulletinBoardList';
import BulletinBoard from './routes/bbs/pages/bulletinBoard';
import useResponsive from './shared/hooks/screenSize-hook';

function App() {
  // 로그인 되어 있는지
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // 유저
  const [userProfile, setUserProfile] = useState(null);
  // const [isMobile, setIsMobile] = useState(false);

  // 사이드바 상태
  const [mainHidden, setMainHidden] = useState(true);
  const [subHidden, setSubHidden] = useState(false);
  const [headerHidden,setHeaderHidden] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const isTablet = useMediaQuery({
    query: '(min-width: 501px) and (max-width: 1210px)',
  });

  const { isTabletHeight } = useResponsive();

  // 농작업 선점 - 필지 선택 flag
  const [farmworkSelect, setFarmworkSelect] = useState(false);

  const farmworkSelectHandler = (e) => {
    setFarmworkSelect(e);
  };

  useEffect(() => {
    if (window.location.pathname !== '/'){
      setHeaderHidden(true);
    }else{
      setHeaderHidden(false);
    }

    if (localStorage.getItem('login-token')) {
      login();
    } else {
      logout();
    }
  }, []);

  const [saveIdCheckbox, setSaveIdCheckbox] = useState(false);
  const [autoLoginCheckbox, setAutoLoginCheckbox] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('save-id') == 'true') {
      setSaveIdCheckbox(true);
    } else if (localStorage.getItem('save-id') == 'false') {
      setSaveIdCheckbox(false);
    }

    if (localStorage.getItem('auto-login') == 'true') {
      setAutoLoginCheckbox(true);
    } else if (localStorage.getItem('auto-login') == 'false') {
      setAutoLoginCheckbox(false);
    }

    if (new Date().getTime() > localStorage.getItem('login-time')) {
      logout();
    }
  }, []);

  const handleSaveIdCheckbox = () => {
    setSaveIdCheckbox(!saveIdCheckbox);
  };

  const handleAutoLoginCheckbox = () => {
    setAutoLoginCheckbox(!autoLoginCheckbox);
  };

  const login = useCallback(() => {
    const APIToken = localStorage.getItem('login-token');
    setIsLoggedIn(true);
    axios({
      method: 'get',
      url: `/api/account/profile`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        setUserProfile(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    localStorage.removeItem('login-token');
    localStorage.removeItem('user-name');
    localStorage.removeItem('user-level');
    if (localStorage.getItem('save-id') == 'false') {
      localStorage.removeItem('user-id');
    }
    localStorage.setItem('auto-login', 'false');
  }, []);

  let routes;

  // Main state
  const [showImage, setShowImage] = useState(false); // 드론영상
  const [showTheme, setShowTheme] = useState(false); // 주제도
  const [showFarmwork, setShowFarmwork] = useState(false); // 농작업
  const [showDrnLog, setShowDrnLog] = useState(false); // 드론로그
  const [showImageReg, setShowImageReg] = useState(false); // 관측영상 등록
  const [showThemeReg, setShowThemeReg] = useState(false); // 주제도 등록
  const [showDrnLogReg, setShowDrnLogReg] = useState(false); // 드론로그 등록
  const [showGen, setShowGen] = useState(false); // 관심구역 생성
  const [showMng, setShowMng] = useState(false); // 관심구역 관리
  const [showProfile, setShowProfile] = useState(false); // 프로필 관리
  const [showBbsList, setShowBbsList] = useState(false); // 게시판
  const [showAdmin, setShowAdmin] = useState(false); // 관리자

  const [interactCoord, setInteractCoord] = useState(); // 지도좌표
  const [shortcut, setShortcut] = useState(false); // 숏컷(사용X)
  const [sideBar, setSideBar] = useState(false); // 사이드바
  const [selectItrst, setSelectItrst] = useState(); // 선택관심구역
  const [guideLine, setGuideLine] = useState(false); // 가이드라인
  const [showSubmenu, setShowSubmenu] = useState(false); // 서브메뉴

  // const [selZoneNm, setSelZoneNm] = useState("관심구역");

  const [activeTheme, setActiveTheme] = useState(1);
  const [activeImg, setActiveImg] = useState(1);
  const [activeDrnLog, setActiveDrnLog] = useState(1);

  const [showThemeMapAdd, setShowThemeMapAdd] = useState(false);

  const [myZoneList, setMyZoneList] = useState([]);
  const [isMine, setIsMine] = useState(false);

  //모달 상세조회 삭제 버튼 flag
  const [modalDel, setModalDel] = useState(0);

  // 태그검색
  const [myZoneSearchType, setMyZoneSearchType] = useState('');
  const [myZoneTagList, setMyZoneTagList] = useState('');
  const [searchMyZoneList, setSearchMyZoneList] = useState([]);
  const [checkItrstYear, setCheckItrstYear] = useState([]);
  const [checkItrstTag, setCheckItrstTag] = useState([]);
  const [myZoneSearchName, setMyZoneSearchName] = useState('');

  const [showFarmworkSelect, setShowFarmworkSelect] = useState(false); // 농작업 요청(경작자)
  const [showInstFarmwork, setShowInstFarmwork] = useState(false); // 농작업 지원(기관)

  const [mobileActiveMenu, setMobileActiveMenu] = useState(1);

  const [sidebarHiddenFlag, setSidebarHiddenFlag] = useState(false);

  const handleModalDelState = (event) => {
    setModalDel(event);
  };
  const handleShowFarmworkSelect = (event) => {
    setShowFarmworkSelect(event);
  };
  const handleShowInstFarmwork = (event) => {
    setShowInstFarmwork(event);
  };
  const handleShowImage = (event) => {
    setShowImage(event);
  };
  const handleShowTheme = (event) => {
    setShowTheme(event);
  };
  const handleShowFarmwork = (event) => {
    setShowFarmwork(event);
  };
  const handleShowDrnLog = (event) => {
    setShowDrnLog(event);
  };
  const handleShowImageReg = (event) => {
    setShowImageReg(event);
  };
  const handleShowThemeReg = (event) => {
    setShowThemeReg(event);
  };
  const handleShowDrnLogReg = (event) => {
    setShowDrnLogReg(event);
  };
  const handleShowProfile = (event) => {
    setShowProfile(event);
  };
  const handleShowBbsList = (event) => {
    setShowBbsList(event);
  };
  const handleShowGen = (event) => {
    setShowGen(event);
  };
  const handleShowMng = (event) => {
    setShowMng(event);
  };
  const handleCoord = (event) => {
    setInteractCoord(event);
  };
  const handleShortcut = (event) => {
    setShortcut(event);
  };
  const handleSideBar = (event) => {
    setSideBar(event);
  };
  const handleSelectItrst = (event) => {
    setSelectItrst(event);
  };
  const handleGuideLine = (event) => {
    setGuideLine(event);
  };
  const handleShowSubmenu = (event) => {
    setShowSubmenu(event);
  };
  const handleActiveImg = (event) => {
    setActiveImg(event);
  };
  const handleActiveTheme = (event) => {
    setActiveTheme(event);
  };

  const handleShowThemeMapAdd = (event) => {
    setShowThemeMapAdd(event);
  };

  const handleActiveDrnLog = (event) => {
    setActiveDrnLog(event);
  };

  const handleMyZoneList = (event) => {
    setMyZoneList(event);
  };

  // 태그검색
  const handleMyZoneSearchType = (event) => {
    setMyZoneSearchType(event);
  };
  const handleMyZoneTagList = (event) => {
    setMyZoneTagList(event);
  };
  const handleSearchMyZoneList = (event) => {
    setSearchMyZoneList(event);
  };
  const handleCheckYear = (event) => {
    setCheckItrstYear(event);
  };
  const handleCheckTag = (event) => {
    setCheckItrstTag(event);
  };
  const handleMyZoneSearchName = (event) => {
    setMyZoneSearchName(event);
  };

  const handleMobileActiveMenu = (event) => {
    setMobileActiveMenu(event);
  };

  // 회원가입 이용약관
  const [isTerms_state, setIsTerms_state] = useState(false);
  const handleIsTerms = (value) => {
    setIsTerms_state(value);
  };

  // 게시판
  //헤더분별변수
  const [header_state, setHeader_state] = useState(0);
  const handleHeader_state = (value) => {
    setHeader_state(value);
  };

  // const [isNotice, setIsNotice] = useState(false)
  // const handleIsNotice = (event) => {setIsNotice(event);}
  const [isBbs_state, setIsBbs_state] = useState('bulletinBoard');
  const handleIsBbs_state = (value) => {
    setIsBbs_state(value);
  };

  const [postTy, setPostTy] = useState(4);
  const handlePostTy = (value) => {
    setPostTy(value);
  };

  // 조회(bbs-list), 수정(bbs-mod)
  const [activeBbs, setActiveBbs] = useState('');

  // 관측영상, 주제도 농작업
  const [activeHeaderMenu, setActiveHeaderMenu] = useState('/image');

  // 리스트? 폼?
  const [showBulletinBoard, setShowBulletinBoard] = useState(false);

  const APIToken = localStorage.getItem('login-token');

  useEffect(() => {
    if (isLoggedIn) {
      setSideBar(true);
    }
  }, [isLoggedIn]);

  const headerProps = {
    showSubmenu,
    handleShowSubmenu,
    isMobile,
    isTerms_state,
    handleIsTerms,
  };

  const loginProps = {
    login,
    isMobile,
    handleSaveIdCheckbox,
    handleAutoLoginCheckbox,
    saveIdCheckbox,
    autoLoginCheckbox,
  };

  const termsProps = {
    isTerms_state,
    handleIsTerms,
  };

  const isBbsProps = {
    // isNotice,
    // handleIsNotice,
    isBbs_state,
    handleIsBbs_state,
    selectItrst,
    postTy,
    setPostTy,
    handlePostTy,
    activeBbs,
    setActiveBbs,
    showBulletinBoard,
    setShowBulletinBoard,
  };

  const isLandingProps = {
    isMobile,
    isTablet,
  };

  function Homepage() {
    return (
      <Home
        setHeaderHidden={setHeaderHidden}
        isLoggedIn={isLoggedIn}
        isMobile={isMobile}
        isTablet={isTablet}
        userProfile={userProfile}
        showImage={showImage}
        setShowImage={setShowImage}
        handleShowImage={handleShowImage}
        showTheme={showTheme}
        setShowTheme={setShowTheme}
        handleShowTheme={handleShowTheme}
        showFarmwork={showFarmwork}
        setShowFarmwork={setShowFarmwork}
        handleShowFarmwork={handleShowFarmwork}
        showImageReg={showImageReg}
        setShowImageReg={setShowImageReg}
        handleShowImageReg={handleShowImageReg}
        showThemeReg={showThemeReg}
        setShowThemeReg={setShowThemeReg}
        handleShowThemeReg={handleShowThemeReg}
        showDrnLogReg={showDrnLogReg}
        setShowDrnLogReg={setShowDrnLogReg}
        handleShowDrnLogReg={handleShowDrnLogReg}
        handleShowProfile={handleShowProfile}
        setShowProfile={setShowProfile}
        showProfile={showProfile}
        showGen={showGen}
        setShowGen={setShowGen}
        handleShowGen={handleShowGen}
        showMng={showMng}
        setShowMng={setShowMng}
        handleShowMng={handleShowMng}
        selectItrst={selectItrst}
        setSelectItrst={setSelectItrst}
        handleSelectItrst={handleSelectItrst}
        showThemeMapAdd={showThemeMapAdd}
        setShowThemeMapAdd={setShowThemeMapAdd}
        interactCoord={interactCoord}
        handleCoord={handleCoord}
        shortcut={shortcut}
        setShortcut={setShortcut}
        sideBar={sideBar}
        setSideBar={setSideBar}
        guideLine={guideLine}
        setGuideLine={setGuideLine}
        handleGuideLine={handleGuideLine}
        showSubmenu={showSubmenu}
        setShowSubmenu={setShowSubmenu}
        handleShowSubmenu={handleShowSubmenu}
        activeImg={activeImg}
        handleActiveImg={handleActiveImg}
        showDrnLog={showDrnLog}
        setShowDrnLog={setShowDrnLog}
        handleShowDrnLog={handleShowDrnLog}
        activeDrnLog={activeDrnLog}
        handleActiveDrnLog={handleActiveDrnLog}
        activeTheme={activeTheme}
        handleActiveTheme={handleActiveTheme}
        searchMyZoneList={searchMyZoneList}
        handleSearchMyZoneList={handleSearchMyZoneList}
        handleMyZoneList={handleMyZoneList}
        setMyZoneList={setMyZoneList}
        isMine={isMine}
        setIsMine={setIsMine}
        myZoneSearchType={myZoneSearchType}
        myZoneTagList={myZoneTagList}
        activeHeaderMenu={activeHeaderMenu}
        setActiveHeaderMenu={setActiveHeaderMenu}
        checkItrstYear={checkItrstYear}
        checkItrstTag={checkItrstTag}
        myZoneSearchName={myZoneSearchName}
        // 농작업 요청(경작자)
        showFarmworkSelect={showFarmworkSelect}
        setShowFarmworkSelect={setShowFarmworkSelect}
        handleShowFarmworkSelect={handleShowFarmworkSelect}
        // 농작업 지원(기관)
        showInstFarmwork={showInstFarmwork}
        setShowInstFarmwork={setShowInstFarmwork}
        handleShowInstFarmwork={handleShowInstFarmwork}
        header_state={header_state}
        setHeader_state={setHeader_state}
        handleHeader_state={handleHeader_state}
        modalDel={modalDel}
        setModalDel={setModalDel}
        handleModalDelState={handleModalDelState}
        mainHidden={mainHidden}
        setMainHidden={setMainHidden}
        subHidden={subHidden}
        setSubHidden={setSubHidden}
        logout={logout}
        isTabletHeight={isTabletHeight}
        isTerms_state={isTerms_state}
        handleIsTerms={handleIsTerms}
        mobileActiveMenu={mobileActiveMenu}
        setMobileActiveMenu={setMobileActiveMenu}
        showAdmin={showAdmin}
        setShowAdmin={setShowAdmin}
        sidebarHiddenFlag={sidebarHiddenFlag}
        setSidebarHiddenFlag={setSidebarHiddenFlag}
        farmworkSelect={farmworkSelect}
        setFarmworkSelect={setFarmworkSelect}
        farmworkSelectHandler={farmworkSelectHandler}
      />
    );
  }

  if (isLoggedIn) {
    routes = (
      <Routes>
        <Route path='/' element={<Landing {...isLandingProps} />} />
        <Route path='/home' element={Homepage()} />
        {/* <Route path="/" element={Homepage()} /> */}
        <Route path='/image' element={Homepage()} />
        <Route path='/theme' element={Homepage()} />
        <Route path='/farmwork' element={Homepage()} />
        <Route path='/drnLog' element={Homepage()} />
        <Route path='*' element={<h1>404 Page Not Found</h1>} />
        <Route path='/profile' element={<Profile {...headerProps} />} />
        <Route path='/adminmode' element={<Adminmode {...headerProps} />} />

        <Route
          path='/bbslist'
          element={<BulletinBoardList {...isBbsProps} />}
        />
        <Route path='/bbsList' element={<BulletinBoard {...isBbsProps} />} />

        {/* <Route path="/farmworkSelect" element={Homepage()} />
        <Route path="/instFarmwork" element={Homepage()} /> */}
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path='/' element={<Landing {...isLandingProps} />} />
        <Route path='/home' element={<Loginpage {...loginProps} />} />
        {/* <Route path="/" element={<Loginpage login={login} />} /> */}
        <Route path='*' element={<h1>404 Page Not Found</h1>} />
        <Route path='/signup' element={<Signup {...termsProps} />} />
        <Route path='/findid' element={<FindId />} />
        <Route path='/findpw' element={<FindPw />} />
        <Route path='/changepw' element={<ChangePw />} />
        <Route
          path='/termsofservice'
          element={<TermsOfService {...termsProps} />}
        />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{ isLoggedIn: isLoggedIn, login: login, logout: logout }}
    >
      <GlobalStyle />
      <Router>
        {headerHidden && 
          <Header
            isLoggedIn={isLoggedIn}
            isTablet={isTablet}
            isMobile={isMobile}
            userProfile={userProfile}
            login={login}
            logout={logout}
            handleShowImage={handleShowImage}
            handleShowTheme={handleShowTheme}
            handleShowDrnLog={handleShowDrnLog}
            handleShowFarmwork={handleShowFarmwork}
            handleShowThemeReg={handleShowThemeReg}
            handleShowImageReg={handleShowImageReg}
            handleShowGen={handleShowGen}
            handleShowMng={handleShowMng}
            interactCoord={interactCoord}
            handleCoord={handleCoord}
            handleShortcut={handleShortcut}
            handleSideBar={handleSideBar}
            handleShowThemeMapAdd={handleShowThemeMapAdd}
            guideLine={guideLine}
            setGuideLine={setGuideLine}
            handleGuideLine={handleGuideLine}
            selectItrst={selectItrst}
            handleSelectItrst={handleSelectItrst}
            showSubmenu={showSubmenu}
            setShowSubmenu={setShowSubmenu}
            handleActiveImg={handleActiveImg}
            activeImg={activeImg}
            handleActiveTheme={handleActiveTheme}
            activeTheme={activeTheme}
            handleActiveDrnLog={handleActiveDrnLog}
            activeDrnLog={activeDrnLog}
            showMng={showMng}
            showImage={showImage}
            showDrnLog={showDrnLog}
            showThemeMapAdd={showThemeMapAdd}
            myZoneList={myZoneList}
            handleMyZoneList={handleMyZoneList}
            isMine={isMine}
            setIsMine={setIsMine}
            handleMyZoneSearchType={handleMyZoneSearchType}
            handleMyZoneTagList={handleMyZoneTagList}
            searchMyZoneList={searchMyZoneList}
            handleSearchMyZoneList={handleSearchMyZoneList}
            // handleBulletinBoard={handleBulletinBoard}
            // handleIsNotice={handleIsNotice}
            handleIsBbs_state={handleIsBbs_state}
            handlePostTy={handlePostTy}
            activeHeaderMenu={activeHeaderMenu}
            setActiveHeaderMenu={setActiveHeaderMenu}
            setShowBulletinBoard={setShowBulletinBoard}
            handleCheckYear={handleCheckYear}
            // checkItrstYear={checkItrstYear}
            handleCheckTag={handleCheckTag}
            handleMyZoneSearchName={handleMyZoneSearchName}
            // 농작업 요청(경작자)
            handleShowFarmworkSelect={handleShowFarmworkSelect}
            // 농작업 지원(기관)
            handleShowInstFarmwork={handleShowInstFarmwork}
            header_state={header_state}
            setHeader_state={setHeader_state}
            handleHeader_state={handleHeader_state}
            mainHidden={mainHidden}
            setMainHidden={setMainHidden}
            subHidden={subHidden}
            mobileActiveMenu={mobileActiveMenu}
            setMobileActiveMenu={setMobileActiveMenu}
            handleMobileActiveMenu={handleMobileActiveMenu}
            // --------------------------------------
            handleShowBbsList={handleShowBbsList}
            setShowBbsList={setShowBbsList}
            showBbsList={showBbsList}
            setPostTy={setPostTy}
            postTy={postTy}
            isBbs_state={isBbs_state}
            setActiveBbs={setActiveBbs}
            activeBbs={activeBbs}
            showBulletinBoard={showBulletinBoard}
            // --------------------------------------
            sidebarHiddenFlag={sidebarHiddenFlag}
            setSidebarHiddenFlag={setSidebarHiddenFlag}
            handleShowProfile={handleShowProfile}
            setShowProfile={setShowProfile}
            showProfile={showProfile}
            farmworkSelect={farmworkSelect}
            setFarmworkSelect={setFarmworkSelect}
            farmworkSelectHandler={farmworkSelectHandler}
          />
        }
        <main>{routes}</main>
        {/* <Footer /> */}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
