import './home.css';
import React, { useEffect, useState, useRef } from 'react';
import Map, { Marker, Source, Layer, NavigationControl } from 'react-map-gl';
import {
  Router,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
} from 'react-router-dom';
import {
  ChromePicker,
  GithubPicker,
  SketchPicker,
  CompactPicker,
} from 'react-color';
import ReactSlider from 'react-slider';

import { EditableInput } from 'react-color/lib/components/common';

import { Checkbox } from 'semantic-ui-react';

import FarmStatus from '../../../routes/farmStatus/farmStatus';
// import ThemeMap from '../../../routes/farmMng/farmMng';
// import FarmWork from '../../../routes/farmWork/farmWork';
import DroneImageReg from '../../../routes/imageReg/imageReg';
import ThemeMapReg from '../../../routes/themeMap/themeMap';

import ItrstGen from '../../../routes/itrstZone/itrstZone';
import ItrstMng from '../../../routes/itrstZone/components/ZoneList';

import DrnLogUpload from '../../../routes/drnLogUpload/drnLogUpload.js';
import Profile from '../../../routes/user/pages/profile.js';
import VisualDetect from '../../../routes/themeMap/themeMapAdd';

import SideBar from '../../../shared/components/sideBar/SideBar';
import FixedSidebar from '../../../shared/components/fixedSidebar/fixedSidebar';
// import Shortcut from '../components/shortcut'
// import useDidMountEffect from '../../hooks/useDidMountEffect';

import Popup from '../../components/uiElements/Popup';

import DrawControl from '../../../routes/itrstZone/draw-control.ts';

// AXIOS
import axios from 'axios';

// TURF
import turf, { polygon } from 'turf';

// Proj4
import proj4 from 'proj4';
import mapboxgl from 'mapbox-gl';

import config from '../../../config/config';
import { Icon } from '@iconify/react';
import Adminmode from '../../../routes/user/pages/adminmode.js';

proj4.defs(
  'EPSG:5179',
  '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
proj4.defs(
  'EPSG:5181',
  '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'
);
proj4.defs(
  'EPSG:5186',
  '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs'
);
proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');

function Home(props) {
  // Mapbox TOKEN
  const MAP_TOKEN =
    'pk.eyJ1Ijoic2RqMzIzIiwiYSI6ImNsZGIxazJ5cDBvaXkzb25uM2NhZ2xvc3EifQ.wMTHtB5DTOMkIn-RI-Xs-w';
  const APIToken = localStorage.getItem('login-token');

  const mapRef = React.useRef();
  const chartDIV = React.useRef();
  const [chartCurrent, setChartCurrent] = useState();

  // 지도
  const [map, setMap] = useState();
  const [mapREF, setMapREF] = useState(mapRef.current);

  useEffect(() => {
    if (mapRef.current) {
      setMap(mapRef.current.getMap());
      setMapREF(mapRef.current);
    }
  }, [mapRef.current]);

  // const chartDIV = ref;

  const navigate = useNavigate();

  // 항공&위성영상 ON/OFF
  const [onmapSat, setOnmapSat] = useState(true);
  // 팜맵 ON/OFF
  const [onFarmMap, setOnFarmMap] = useState(false);
  // 관심구역 색상 ON/OFF
  const [onSetItrst, setOnSetItrst] = useState(false);
  // 관심구역 색상
  // const [itrstColor, setItrstColor] = useState('#FF0000')
  const [itrstColor, setItrstColor] = useState('#FFFF00');
  // 관심구역 선 두께
  const [itrstLine, setItrstLine] = useState(3);
  // 관심구역 On/Off
  const [onItrst, setOnItrst] = useState(true);
  // 드론 로그 업로드 리스트
  const [drnLogFileList, setDrnLogFileList] = useState();
  // 주제도 리스트
  const [themeMapList, setThemeMapList] = useState();
  // 드론영상 리스트
  const [droneImageList, setDroneImageList] = useState();
  // 농작업 리스트
  const [farmworkList, setFarmworkList] = useState();
  // 활성화 레이어 리스트
  const [activeLayerList, setActiveLayerList] = useState();
  // 활성화 레이어 > 선택 레이어
  const [curActiveLayer, setCurAcitveLayer] = useState();
  // 재배현황 메뉴
  // const [activeFsMenu, setActiveFsMenu] = useState(5);
  // 재배관리 메뉴
  // const [activeFmMenu, setActiveFmMenu] = useState(1);
  // 농작업 요청 ON/OFF
  const [requestFarmwork, setRequestFarmwork] = useState(false);
  // 농작업 요청 - 필지 선택 flag
  // const [farmworkSelect, setFarmworkSelect] = useState(false);
  // 농작업 요청 - 선택 좌표
  const [farmworkCoord, setFarmworkCoord] = useState([]);
  // 식생지수 영상
  const [vegIndexImage, setVegIndexImage] = useState();

  // 육안판독 팜맵 flag
  const [visualDetection, setVisualDetection] = useState(false);
  // 육안판독 컬러맵
  const [cropColorMap, setCropColorMap] = useState([]);
  // 육안판독 선택 색상
  const [selCropColor, setSelCropColor] = useState();
  // 육안판독 선택 라벨
  const [selCropName, setSelCropName] = useState();
  // 육안판독 주제도명 공유
  const [themeName, setThemeName] = useState('');
  // Select Mode
  const [selectedMode, setSelectedMode] = useState(2);
  // Draw polygon points coord
  const [polygonCoord, setPolygonCoord] = useState([]);
  // Flag
  const [tempFlag, setTempFlag] = useState(false);

  // Zoomlevel
  const [zoomLevel, setZoomLevel] = useState(120);
  // Layer Delete
  const [delMode, setDelMode] = useState(-1);
  // Layer change order
  const [indexFrom, setIndexFrom] = useState();
  const [indexTo, setIndexTo] = useState();
  // loading animation
  const [loading, setLoading] = useState(false);

  const [addCheckDrn, setAddCheckDrn] = useState(false);
  const [addCheckTheme, setAddCheckTheme] = useState(false);
  const [addCheckDrnLog, setAddCheckDrnLog] = useState(false);

  const [listReturnFlag, setListReturnFlag] = useState(false);

  // const [mobileActiveMenu, setMobileActiveMenu] = useState(1);
  const [mobileLayerMenu, setMobileLayerMenu] = useState(false);
  const [mobileLegendMenu, setMobileLegendMenu] = useState(false);
  const [mobileAbstractMenu, setMobileAbstractMenu] = useState(true);

  // 레이어 관리
  const [ID_ARRAY, set_ID_ARRAY] = useState([]); // ID
  const [SRC_ARRAY, set_SRC_ARRAY] = useState([]); // Source
  const [LAYER_STYLE_ARRAY, set_LAYER_STYLE_ARRAY] = useState([]); // Style
  const [VISIBLE_ARRAY, set_VISIBLE_ARRAY] = useState([]); // Visible
  const [TYPE_ARRAY, set_TYPE_ARRAY] = useState([]); // Type

  const onmapHandler = (e) => {
    setOnmapSat(e);
  };
  const onFarmmapHandler = (e) => {
    setOnFarmMap(e);
  };
  const handleColorChange = (e) => {
    setItrstColor(e.hex);
    setOnSetItrst(false);
  };
  const handleChangeSketch = (e) => {
    setItrstColor(e.hex);
  };
  const drnLogFileListHandler = (e) => {
    setDrnLogFileList(e);
  };
  const themeMapListHandler = (e) => {
    setThemeMapList(e);
  };
  const droneImageListHandler = (e) => {
    setDroneImageList(e);
  };
  const farmworkListHandler = (e) => {
    setFarmworkList(e);
  };
  const activeLayerListHandler = (e) => {
    setActiveLayerList(e);
  };
  const curActiveLayerHandler = (e) => {
    setCurAcitveLayer(e);
  };
  // const activeFsMenuHandler = (e) => {setActiveFsMenu(e);}
  // const activeFmMenuHandler = (e) => {setActiveFmMenu(e);}
  const visualDetectionHandler = (e) => {
    setVisualDetection(e);
  };
  // const farmworkSelectHandler = (e) => {
  //   setFarmworkSelect(e);
  // };
  const selCropColorHandler = (e) => {
    setSelCropColor(e);
  };
  const selCropNameHandler = (e) => {
    setSelCropName(e);
  };
  const vegIndexImageHandler = (e) => {
    setVegIndexImage(e);
  };
  const handleSelectionMode = (e) => {
    setSelectedMode(e);
  };
  const delModeHandler = (e) => {
    setDelMode(e);
  };
  const indexFromHandler = (e) => {
    setIndexFrom(e);
  };
  const indexToHandler = (e) => {
    setIndexTo(e);
  };
  const cropColorMapHandler = (e) => {
    setCropColorMap(e);
  };
  const themeNameHandler = (e) => {
    setThemeName(e);
  };
  const listReturnFlagHandler = (e) => {
    setListReturnFlag(e);
  };

  // TYPE_ARRAY handler 추가
  const typeArrayHandler = (e) => {
    set_TYPE_ARRAY(e);
  };

  const visibleArrayHandler = (e) => {
    set_VISIBLE_ARRAY(e);
  };

  const [drone, setDrone] = useState(false);
  const droneHandler = (e) => {
    setDrone(e);
  };

  const [clickNum, setClickNum] = useState(null);
  const clickNumHandler = (e) => {
    setClickNum(e);
  };

  const [drnLogLayerFea, setDrnLogLayerFea] = useState([]);
  const clickDrnLogLayerFeaHandler = (e) => {
    setDrnLogLayerFea(e);
  };

  // 관심구역 선 두께
  const handleChangeLine = (e) => {
    // let value = Number(e.target.value);
    // console.log(value);
    // // 30보다 큰 숫자는 허용하지 않음
    // if (value <= 30) {
    //   setItrstLine(value);
    // } else {
    //   setItrstLine(30);
    // }
    setItrstLine(Number(e));
  };

  // 관심구역 토글 ON/OFF
  const [itrstZoneToggle, setItrstZoneToggle] = useState(true);
  const itrstToggle = (e, data) => {
    if (data.checked) {
      setItrstZoneToggle(true);
    } else {
      setItrstZoneToggle(false);
    }
  };

  useEffect(() => {
    if (map) {
      if (itrstZoneToggle) {
        map.setLayoutProperty(ITRST_LAYER_ID_DUMMY, 'visibility', 'visible');
        if (map.getLayer(ITRST_LAYER_ID_DUMMY)) {
          map.moveLayer(ITRST_LAYER_ID_DUMMY);
        }
      } else {
        map.setLayoutProperty(ITRST_LAYER_ID_DUMMY, 'visibility', 'none');
      }
    }
  }, [itrstZoneToggle]);

  useEffect(() => {
    if (props.selectItrst !== undefined) {
      setItrstZoneToggle(true);
    }
  }, [props.selectItrst]);

  useEffect(() => {
    if (activeLayerList !== undefined) {
      if (activeLayerList !== null) {
        if (activeLayerList.length > 0) {
          setItrstZoneToggle(false);
          setCurAcitveLayer(activeLayerList[0]);
        } else {
          setItrstZoneToggle(true);
        }
      } else {
        setItrstZoneToggle(true);
      }
    } else {
      setItrstZoneToggle(true);
    }
  }, [activeLayerList]);

  // useEffect(() => {
  //   if (props.selectItrst !== undefined && props.isMobile) {
  //     props.setMobileActiveMenu(1);
  //   }
  // }, [props.selectItrst]);

  // 활성화 레이어 - 드론 로그 있는 경우 계획경로, 자동경로, 수동경로 버튼 생성
  const [droneLog, setDroneLog] = useState({});
  const [states, setStates] = useState({});
  const [csvLog, setCsvLog] = useState({});
  const [csvStates, setCsvStates] = useState({});

  const [farmWorkLogFlag, setFarmWorkLogFlag] = useState(false);
  const [visibleDrnLog, setVisibleDrnLog] = useState(false);

  // 파일 업로드 후 차트 영역 활성화
  const [actChart, setActChart] = useState(false);

  // 차트가 위치한 div 활성화
  const [showChart, setShowChart] = useState(false);
  const [noChart, setNoChart] = useState(false);
  const [readyChart, setReadyChart] = useState(false);
  const [readyCSVChart, setReadyCSVChart] = useState(false);

  const [waypoint, setWaypoint] = useState(false);

  const [clickFarmRequest, setClickFarmRequst] = useState(false);

  // *********************농작업 요청(경작자)*********************
  // 농작업 선점 ON/OFF
  const [registFarmwork, setRegistFarmwork] = useState(false);
  // 농작업 선점 - 필지 선택 flag
  const [farmSelect, setFarmSelect] = useState(false);
  // 농작업 리스트
  const [farmworkSelectList, setFarmworkSelectList] = useState();
  // 요청 기관 확인 목록 ON/OFF
  const [checkInst, setCheckInst] = useState(false);

  const drnLogHandler = (e) => {
    setDroneLog(e);
  };

  const csvLogHandler = (e) => {
    setCsvLog(e);
  };

  const statesHandler = (e) => {
    setStates(e);
  };

  const csvStateHandler = (e) => {
    setCsvStates(e);
  };

  const farmSelectHandler = (e) => {
    setFarmSelect(e);
  };
  const farmworkSelectListHandler = (e) => {
    setFarmworkSelectList(e);
  };

  // *********************농작업 지원(기관)*********************
  // 농작업 지원 리스트
  const [farmworkListforInst, setFarmworkListforInst] = useState();
  // 농작업 지원 Modal ON/OFF
  const [showRegistInstModal, setShowRegistInstModal] = useState(false);

  const farmworkListforInstHandler = (e) => {
    setFarmworkListforInst(e);
  };

  // 리스트 검색 state
  const [searchFlag, setSearchFlag] = useState(false);

  // 드론 로그 호출 flag
  const [drnLogFlag, setDrnLogFlag] = useState(false);

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState();

  // 관측영상 식생지수 on/off
  const [selVIindex, setSelVIindex] = useState(-1);

  useEffect(() => {
    if (props.isLoggedIn) {
      axios({
        method: 'get',
        url: `/api/zone/list?page=1&perPage=0&onlyAproved=true&`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.total == 0) {
            props.handleGuideLine(true);
          } else {
            props.handleGuideLine(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.isLoggedIn]);

  const [headerSearchState, setHeaderSearchState] = useState(false);
  useEffect(() => {
    if (props.header_state == 0) {
      setHeaderSearchState(true);
    }
  }, [props.header_state, headerSearchState]);

  useEffect(() => {
    let itrstZoneListUrl = `/api/zone/list?page=1&perPage=0&filterType=${props.myZoneSearchType}&keyword=${props.myZoneSearchName}&onlyAproved=true&`;
    if (props.checkItrstYear !== undefined) {
      itrstZoneListUrl += `selectYear=${props.checkItrstYear}`;
    }
    if (props.checkItrstTag !== undefined) {
      itrstZoneListUrl += `&selectTag=${props.checkItrstTag}`;
    }

    axios({
      method: 'get',
      url: itrstZoneListUrl,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        let zoneListArr = [];
        let zoneListResult;

        zoneListResult = result.data.data;
        zoneListResult &&
          zoneListResult.map((e) => {
            if (e.actvtn_cd == 1) {
              zoneListArr.push(e);
            }
          });

        props.handleMyZoneList(zoneListArr);

        if (!headerSearchState) {
          if (props.selectItrst && props.selectItrst.itrst_zone_id) {
            const zoneIndex = zoneListArr?.findIndex(
              (item) => item.itrst_zone_id === props.selectItrst.itrst_zone_id
            );
            props.handleSelectItrst(zoneListArr[zoneIndex]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.checkItrstYear, props.myZoneSearchName, props.checkItrstTag]);

  useEffect(() => {
    // if (mapRef.current != null) {
    // mapRef.current.on("zoom", () => {
    if (mapREF !== null && mapREF !== undefined) {
      mapREF.on('zoom', () => {
        // const map = mapREF.current.getMap();
        const zoomlevel = map.getZoom();
        setZoomLevel(zoomlevel * 10);
      });

      // mapRef.current.on('click', ITRST_LAYER_ID, function (e) {
      //   var feature = e.features[0];
      //   var featureProperties = feature.properties;
      //   console.log(featureProperties);
      // });
    }
  }, [mapRef.current, mapREF]);

  const zoomlevelHandler = (e) => {
    const map = mapRef.current.getMap();
    map.setZoom(e.target.value / 10);
  };

  // 관심구역 변경시 초기화
  useEffect(() => {
    activeLayerListHandler();
    curActiveLayerHandler();
    setVegIndexImage();

    set_ID_ARRAY([]);
    set_SRC_ARRAY([]);
    set_LAYER_STYLE_ARRAY([]);
    set_VISIBLE_ARRAY([]);
    set_TYPE_ARRAY([]);
  }, [props.selectItrst]);

  // curActiveLayer 좌표이동
  useEffect(() => {
    moveCurActiveLayer();
  }, [curActiveLayer]);

  // drnLogLayerFea
  // useEffect(() => {
  //   if (drnLogLayerFea !== null) {
  //   }
  // }, [drnLogLayerFea]);

  function moveCurActiveLayer() {
    if (curActiveLayer) {
      if (mapRef.current != null) {
        const map = mapRef.current.getMap();
        // 드론영상
        if (curActiveLayer.layerType == 1) {
          if (curActiveLayer.bbox) {
            // Flyto latitude 32.0 ~ 39.0 & longitude 124.0 ~ 132.0
            if (
              curActiveLayer.bbox[0] > 124.0 &&
              curActiveLayer.bbox[2] < 132.0 &&
              curActiveLayer.bbox[1] > 32.0 &&
              curActiveLayer.bbox[3] < 39.0
            ) {
              map.jumpTo({
                center: [
                  (curActiveLayer.bbox[0] + curActiveLayer.bbox[2]) / 2,
                  (curActiveLayer.bbox[1] + curActiveLayer.bbox[3]) / 2,
                ],
                essential: true,
                // zoom: 16,
              });
              map.fitBounds(curActiveLayer.bbox, { padding: 100 });
            }
          }
        }

        // 농작업
        else if (curActiveLayer.layerType == 3) {
          if (curActiveLayer.geoJson) {
            const geojsondata = curActiveLayer.geoJson;
            const center = turf.center(geojsondata);
            const bbox = turf.bbox(geojsondata);
            map.jumpTo({
              center: [
                center.geometry.coordinates[0],
                center.geometry.coordinates[1],
              ],
              essential: true,
              // zoom: 16,
            });
            map.fitBounds(bbox, { padding: 100 });
          }
        }

        // 드론로그업로드
        // else if (curActiveLayer.layerType == 5) {
        //   console.log("curActiveLayer");
        //   console.log(drnLogLayerFea);
        //   if (drnLogLayerFea !== null) {
        //     console.log(drnLogLayerFea);
        //     map.setCenter(
        //       drnLogLayerFea[drnLogLayerFea.length / 2].geometry.coordinates
        //     );
        //   }
        // }

        // 주제도
        else if (curActiveLayer.layerType == 2) {
          if (curActiveLayer.bbox) {
            // Flyto latitude 32.0 ~ 39.0 & longitude 124.0 ~ 132.0
            if (
              curActiveLayer.bbox[0] > 124.0 &&
              curActiveLayer.bbox[2] < 132.0 &&
              curActiveLayer.bbox[1] > 32.0 &&
              curActiveLayer.bbox[3] < 39.0
            ) {
              map.jumpTo({
                center: [
                  (curActiveLayer.bbox[0] + curActiveLayer.bbox[2]) / 2,
                  (curActiveLayer.bbox[1] + curActiveLayer.bbox[3]) / 2,
                ],
                essential: true,
                // zoom: 16,
              });
              map.fitBounds(curActiveLayer.bbox, { padding: 100 });
            }
          }
          if (curActiveLayer.geoJson) {
            const geojsondata = curActiveLayer.geoJson;
            const center = turf.center(geojsondata);
            const bbox = turf.bbox(geojsondata);
            map.jumpTo({
              center: [
                center.geometry.coordinates[0],
                center.geometry.coordinates[1],
              ],
              essential: true,
              zoom: 16,
            });
            map.fitBounds(bbox, { padding: 100 });
          }
        }

        // 농작업 요청(경작자)
        else if (curActiveLayer.layerType === 4) {
          if (curActiveLayer.geoJson) {
            if (typeof curActiveLayer.geoJson !== 'object') {
              curActiveLayer.geoJson = JSON.parse(curActiveLayer.geoJson);
            } else {
              curActiveLayer.geoJson = curActiveLayer.geoJson;
            }
            // console.log(curActiveLayer.geoJson);
            const geojsondata = curActiveLayer.geoJson;
            const center = turf.center(geojsondata);
            const bbox = turf.bbox(geojsondata);
            map.jumpTo({
              center: [
                center.geometry.coordinates[0],
                center.geometry.coordinates[1],
              ],
              essential: true,
              // zoom: 16,
            });
            map.fitBounds(bbox);
          }
        }

        // 농작업 지원(경작자)
        else if (curActiveLayer.layerType === 6) {
          if (curActiveLayer.geoJson) {
            if (typeof curActiveLayer.geoJson !== 'object') {
              curActiveLayer.geoJson = JSON.parse(curActiveLayer.geoJson);
            } else {
              curActiveLayer.geoJson = curActiveLayer.geoJson;
            }
            // console.log(curActiveLayer.geoJson);
            const geojsondata = curActiveLayer.geoJson;
            const center = turf.center(geojsondata);
            const bbox = turf.bbox(geojsondata);
            map.jumpTo({
              center: [
                center.geometry.coordinates[0],
                center.geometry.coordinates[1],
              ],
              essential: true,
              // zoom: 16,
            });
            map.fitBounds(bbox);
          }
        }
      }
    }
  }

  // Raster Layer (V-World MAP)
  let MAP_LAYER_ID = 'vworld_map_satellite';
  // Vector Layer (FarmMap Shape)
  let FM_LAYER_ID = 'tn_fmap_info';
  // Itrst Layer
  let ITRST_LAYER_ID = 'selected_ItrstZone';
  let ITRST_LAYER_ID_DUMMY = 'selected_ItrstZone_Dummy';

  let MAP_SRC = {
    id: MAP_LAYER_ID,
    type: 'raster',
    minzoom: 6,
    maxzoom: 19,
    tiles: [config.vworldSat],
  };

  let FM_SRC = {
    type: 'vector',
    minzoom: 6,
    maxzoom: 19,
    tiles: [
      config.geoServer +
        '/gwc/service/wmts?layer=famid-dev:tn_fmap_info&style=&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&Format=application/vnd.mapbox-vector-tile&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}',
    ],
  };

  let MAP_LAYER_STYLE = {
    id: MAP_LAYER_ID,
    type: 'raster',
    layout: { visibility: 'visible' },
    paint: {
      'raster-opacity': 1.0,
      // 'raster-resampling': 'nearest',
      // 'raster-contrast': 0,
      // 'raster-brightness-min': 0,
      // 'raster-brightness-max': 1.0
    },
  };

  let FM_LAYER_STYLE = {
    id: FM_LAYER_ID,
    type: 'fill',
    'source-layer': FM_LAYER_ID,
    layout: { visibility: 'none' },
    paint: {
      'fill-color': 'rgba(255, 255, 255, 0.0)',
      'fill-outline-color': 'rgba(255, 255, 0, 0)',
    },
  };

  function displayBaseMap() {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (map.getLayer(FM_LAYER_ID)) {
        map.moveLayer(FM_LAYER_ID);
      }
      if (map.getLayer(ITRST_LAYER_ID)) {
        map.moveLayer(ITRST_LAYER_ID);
      }
      if (map.getLayer(ITRST_LAYER_ID_DUMMY)) {
        map.moveLayer(ITRST_LAYER_ID_DUMMY);
      }
    }
    return (
      <Source key={MAP_LAYER_ID} {...MAP_SRC}>
        <Layer {...MAP_LAYER_STYLE} />
      </Source>
    );
  }

  function displayFarmMap() {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (map.getLayer(FM_LAYER_ID)) {
        map.moveLayer(FM_LAYER_ID);
      }
      if (map.getLayer(ITRST_LAYER_ID)) {
        map.moveLayer(ITRST_LAYER_ID);
      }
      if (map.getLayer(ITRST_LAYER_ID_DUMMY)) {
        map.moveLayer(ITRST_LAYER_ID_DUMMY);
      }
    }
    return (
      <Source key={FM_LAYER_ID} {...FM_SRC}>
        <Layer {...FM_LAYER_STYLE} />
      </Source>
    );
  }

  const INIT_FM = () => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      const itrstGeojson = JSON.parse(props.selectItrst.geojson);
      if (
        itrstGeojson.features[0].properties.shp_id !== null &&
        itrstGeojson.features[0].properties.shp_id !== undefined
      ) {
        map.setPaintProperty(
          props.selectItrst.itrst_zone_nm,
          'fill-color',
          'rgba(255, 255, 255, 0.0)'
        );
        map.setPaintProperty(
          props.selectItrst.itrst_zone_nm,
          'fill-outline-color',
          'rgba(255, 255, 0, 1.0)'
        );
      } else {
        map.setPaintProperty(
          FM_LAYER_ID,
          'fill-color',
          'rgba(255, 255, 255, 0.0)'
        );
        map.setPaintProperty(
          FM_LAYER_ID,
          'fill-outline-color',
          'rgba(255, 255, 0, 1.0)'
        );
      }
    }
  };

  useEffect(() => {
    if (props.interactCoord && mapRef.current != null) {
      const map = mapRef.current.getMap();
      if (
        props.interactCoord.centerX > 124.0 &&
        props.interactCoord.centerX < 132.0 &&
        props.interactCoord.centerY > 32.0 &&
        props.interactCoord.centerY < 39.0
      ) {
        // console.log("라마바", props.interactCoord);
        map.jumpTo({
          center: [props.interactCoord.centerX, props.interactCoord.centerY],
          essential: true,
        });
        map.fitBounds(props.interactCoord.bbox, {
          padding: 100,
          animate: true,
          duration: 400, // 애니메이션 지속 시간 (밀리초 단위)
          easing: function (t = 0.5) {
            return t * (2 - t); // 애니메이션 속도 함수 (기본값: t * (2 - t))
          },
        });
      }
    }
  }, [props.interactCoord, props.selectItrst]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (!onmapSat) {
        map.setLayoutProperty(MAP_LAYER_ID, 'visibility', 'none');
      } else if (onmapSat) {
        map.setLayoutProperty(MAP_LAYER_ID, 'visibility', 'visible');
      }
      if (activeLayerList) {
        map.moveLayer(ID_ARRAY[0]);
      }
      if (map.getLayer(FM_LAYER_ID)) {
        map.moveLayer(FM_LAYER_ID);
      }
      if (map.getLayer(ITRST_LAYER_ID)) {
        map.moveLayer(ITRST_LAYER_ID);
      }
      if (map.getLayer(ITRST_LAYER_ID_DUMMY)) {
        map.moveLayer(ITRST_LAYER_ID_DUMMY);
      }
    }
  }, [onmapSat]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (!onFarmMap) {
        map.setLayoutProperty(FM_LAYER_ID, 'visibility', 'none');
      } else if (onFarmMap) {
        map.setLayoutProperty(FM_LAYER_ID, 'visibility', 'none');
        if (!clickFarmRequest) {
          map.setPaintProperty(
            FM_LAYER_ID,
            'fill-outline-color',
            'rgba(255, 255, 0, 1.0)'
          );
          map.setLayoutProperty(FM_LAYER_ID, 'visibility', 'visible');
        } else {
          map.setPaintProperty(
            FM_LAYER_ID,
            'fill-outline-color',
            'rgba(255, 255, 0, 0)'
          );
          map.setLayoutProperty(FM_LAYER_ID, 'visibility', 'visible');
        }
      }
      if (activeLayerList) {
        if (activeLayerList.length > 0) {
          map.moveLayer(ID_ARRAY[0]);
        }
      }
      if (map.getLayer(FM_LAYER_ID)) {
        map.moveLayer(FM_LAYER_ID);
      }
      if (map.getLayer(ITRST_LAYER_ID)) {
        map.moveLayer(ITRST_LAYER_ID);
      }
      if (map.getLayer(ITRST_LAYER_ID_DUMMY)) {
        map.moveLayer(ITRST_LAYER_ID_DUMMY);
      }
    }
  }, [onFarmMap]);

  // Set New Layer
  const [refreshFlag, setRefreshFlag] = useState(false);
  // Temp bbox
  const [dummybbox, setDummybbox] = useState();

  useEffect(() => {
    if (refreshFlag) {
      // Add
      let dummyArray = [...activeLayerList];
      dummyArray.unshift(refreshFlag);

      // Temp bbox
      if (dummybbox) {
        dummyArray[0].bbox = dummybbox;
      }

      // Set
      activeLayerListHandler(dummyArray);
      curActiveLayerHandler(refreshFlag);
      setDelMode(-1);

      setRefreshFlag(false);
      setDummybbox();
    }
  }, [refreshFlag]);

  useEffect(() => {
    if (activeLayerList) {
      activeLayer();
    }
  }, [activeLayerList]);

  function activeLayer() {
    let copyArrayID = [...ID_ARRAY]; //관측영상 : drone_image_layer_*** //주제도 : theme_map_layer_*** //농작업 : farmwork_request_***
    let copyArraySRC = [...SRC_ARRAY]; //주제도 : "raster", tiles,,,, //농작업 : "geojson", data,,,,
    let copyArraySTY = [...LAYER_STYLE_ARRAY]; //type:"raster", "fill",,,
    let copyArrayVIS = [...VISIBLE_ARRAY]; // true false
    let copyArrayTY = [...TYPE_ARRAY]; //"theme", "frmwrk"

    //레이어 삭제
    if (delMode >= 0) {
      copyArrayID.splice(delMode, 1);
      copyArraySRC.splice(delMode, 1);
      copyArraySTY.splice(delMode, 1);
      copyArrayVIS.splice(delMode, 1);
      copyArrayTY.splice(delMode, 1);
      setDelMode(-1);
    }

    // 레이어 순서변경
    else if (delMode == -2) {
      let item;
      item = copyArrayID.splice(indexFrom, 1);
      copyArrayID.splice(indexTo, 0, item[0]);
      item = copyArraySRC.splice(indexFrom, 1);
      copyArraySRC.splice(indexTo, 0, item[0]);
      item = copyArraySTY.splice(indexFrom, 1);
      copyArraySTY.splice(indexTo, 0, item[0]);
      item = copyArrayVIS.splice(indexFrom, 1);
      copyArrayVIS.splice(indexTo, 0, item[0]);
      item = copyArrayTY.splice(indexFrom, 1);
      copyArrayTY.splice(indexTo, 0, item[0]);
      setDelMode(-1);
    }

    // 레이어 추가
    else if (delMode == -1) {
      activeLayerList &&
        activeLayerList.map((e, i) => {
          // 드론영상
          if (e.layerType == 1) {
            let index = -1;
            copyArrayID &&
              copyArrayID.map((id) => {
                if (id == 'drone_image_layer_' + e.drn_img_id) {
                  index = i;
                }
              });
            if (index == -1) {
              if (e.drn_img_id && (e.layer_nm || e.layer_stl_nm)) {
                // ID
                const DRN_IMG_LAYER_ID = 'drone_image_layer_' + e.drn_img_id;

                // Source
                const DRN_SRC_ACTIVE = {
                  type: 'raster',
                  tiles: [
                    config.geoServer +
                      '/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&LAYER=' +
                      e.layer_nm +
                      '&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&format=image%2Fpng&TileCol={x}&TileRow={y}',
                  ],
                  bounds: [e.bbox[0], e.bbox[1], e.bbox[2], e.bbox[3]],
                };

                // Layer Style
                const DRN_LAYER_STYLE_ACTIVE = {
                  id: DRN_IMG_LAYER_ID,
                  type: 'raster',
                  layout: {
                    visibility: 'visible',
                  },
                  paint: {
                    'raster-opacity': 1.0,
                  },
                };
                copyArrayID.unshift(DRN_IMG_LAYER_ID);
                copyArraySRC.unshift(DRN_SRC_ACTIVE);
                copyArraySTY.unshift(DRN_LAYER_STYLE_ACTIVE);
                copyArrayVIS.unshift(true);
                copyArrayTY.unshift('drone');
              }
            }
          }
          //주제도
          else if (e.layerType == 2) {
            let index = -1;
            copyArrayID &&
              copyArrayID.map((id) => {
                if (id == 'theme_map_layer_' + e.them_map_id) {
                  index = i;
                }
              });
            if (index == -1) {
              if (e.hasOwnProperty('file_path')) {
                const file_format = e.file_path.substr(
                  e.file_path.length - 3,
                  3
                );

                if (file_format == 'tif') {
                  // ID
                  const THEME_LAYER_ID = 'theme_map_layer_' + e.them_map_id;
                  let THEME_SRC_ACTIVE = {};
                  let THEME_LAYER_STYLE_ACTIVE = {};

                  if (e.hasOwnProperty('layer_nm') && e.layer_nm != null) {
                    // Source
                    THEME_SRC_ACTIVE = {
                      type: 'raster',
                      tiles: [
                        config.geoServer +
                          '/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&LAYER=' +
                          e.layer_nm +
                          '&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&format=image%2Fpng&TileCol={x}&TileRow={y}',
                      ],
                      bounds: [e.bbox[0], e.bbox[1], e.bbox[2], e.bbox[3]],
                    };

                    // Layer Style
                    THEME_LAYER_STYLE_ACTIVE = {
                      id: THEME_LAYER_ID,
                      type: 'raster',
                      layout: {
                        visibility: 'visible',
                      },
                      paint: {
                        'raster-opacity': 1.0,
                      },
                    };
                  } else {
                    let bbox = [];
                    if (
                      e.hasOwnProperty('raster_stat') &&
                      e.raster_stat.hasOwnProperty('coords') &&
                      e.raster_stat.coords != null
                    ) {
                      bbox = [
                        e.raster_stat.coords.topLeft,
                        e.raster_stat.coords.topRight,
                        e.raster_stat.coords.bottomRight,
                        e.raster_stat.coords.bottomLeft,
                      ];
                    } else {
                      bbox = [
                        [e.bbox[0], e.bbox[3]],
                        [e.bbox[2], e.bbox[3]],
                        [e.bbox[2], e.bbox[1]],
                        [e.bbox[0], e.bbox[1]],
                      ];
                    }

                    // Source
                    THEME_SRC_ACTIVE = {
                      id: THEME_LAYER_ID,
                      type: 'image',
                      url: e.pngUrl,
                      coordinates: bbox,
                    };
                    // Layer Style
                    THEME_LAYER_STYLE_ACTIVE = {
                      id: THEME_LAYER_ID,
                      type: 'raster',
                      source: THEME_SRC_ACTIVE,
                      layout: {
                        visibility: 'visible',
                      },
                      paint: {
                        'raster-opacity': 1.0,
                      },
                    };
                  }
                  copyArrayID.unshift(THEME_LAYER_ID);
                  copyArraySRC.unshift(THEME_SRC_ACTIVE);
                  copyArraySTY.unshift(THEME_LAYER_STYLE_ACTIVE);
                  copyArrayVIS.unshift(true);
                  copyArrayTY.unshift('theme');
                } else if (file_format == 'shp' || file_format == 'zip') {
                  if (e.legend_info != null) {
                    const legendInfo = e.legend_info;

                    let registerTable = [];
                    let curColIdx = 0;

                    for (let i = 0; i < e.geoJson.features.length; i++) {
                      let prop = e.geoJson.features[i].properties;
                      let val = e.geoJson.features[i].properties[e.attr_info];

                      let color;

                      if (legendInfo.legendTy == 1) {
                        color = getColorForPercentageTheme(val, legendInfo);
                      } else if (legendInfo.legendTy === 6) {
                        if (legendInfo.legend.length > 0) {
                          legendInfo.legend.forEach((value, i) => {
                            if (value.value === val) {
                              let legendColor =
                                legendInfo.legend[i].color.join(',');
                              color = 'rgba(' + legendColor + ')';
                            } else if (
                              value.value === '' ||
                              value.value === 'null'
                            ) {
                              let legendColor =
                                legendInfo.legend[i].color.join(',');
                              color = 'rgba(' + legendColor + ')';
                            }
                          });
                        } else {
                          return null;
                        }
                      } else {
                        color = getColorForDivider(val, legendInfo);
                      }

                      // if (prop.color) {
                      //   break;
                      // }

                      let exist = registerTable.find((el) => {
                        return el.val == val;
                      });

                      if (!exist) {
                        registerTable.push({ index: curColIdx, val: val });
                        prop.color = color;
                        curColIdx++;
                      } else {
                        prop.color = color;
                      }
                      // if (curColIdx > colorTable.length ) curColIdx = 0;
                    }
                  }

                  // ID
                  const THEME_LAYER_ID = 'theme_map_layer_' + e.them_map_id;
                  // Source
                  const THEME_SRC_ACTIVE = {
                    type: 'geojson',
                    data: e.geoJson,
                  };
                  // Layer Style
                  const THEME_LAYER_STYLE_ACTIVE = {
                    id: THEME_LAYER_ID,
                    type: 'fill',
                    source: THEME_SRC_ACTIVE,
                    layout: {
                      visibility: 'visible',
                    },
                    paint: {
                      'fill-color': ['get', 'color'],
                      'fill-opacity': 1.0,
                      'fill-outline-color': 'rgba(255, 255, 255, 0.8)',
                    },
                  };

                  copyArrayID.unshift(THEME_LAYER_ID);
                  copyArraySRC.unshift(THEME_SRC_ACTIVE);
                  copyArraySTY.unshift(THEME_LAYER_STYLE_ACTIVE);
                  copyArrayVIS.unshift(true);
                  copyArrayTY.unshift('theme');
                }
              }
            }
          }
          // 농작업
          else if (e.layerType == 3) {
            let index = -1;

            copyArrayID &&
              copyArrayID.map((id) => {
                if (id == 'farmwork_request_' + e.frmwkt_req_id) {
                  index = i;
                }
              });

            if (e.geoJson !== null && e.geoJson !== undefined) {
              if (index == -1) {
                if (e.frmwkt_req_id) {
                  let valueArray = [];

                  for (let i = 0; i < e.geoJson.features.length; i++) {
                    let val = e.geoJson.features[i].properties.value;
                    if (val !== null) {
                      valueArray.push(val);
                    }
                  }
                  var maxValue = Math.max(...valueArray);
                  var minValue = Math.min(...valueArray);

                  let registerTable = [];
                  let curColIdx = 0;

                  for (let i = 0; i < e.geoJson.features.length; i++) {
                    if (e.geoJson.features[i].properties.value !== null) {
                      let prop = e.geoJson.features[i].properties;
                      let val = e.geoJson.features[i].properties.value;
                      let color = getColorForPercentage(
                        val,
                        minValue,
                        maxValue
                      );

                      // if (prop.color) {
                      //   break;
                      // }

                      let exist = registerTable.find((el) => {
                        return el.val == val;
                      });

                      if (!exist) {
                        registerTable.push({ index: curColIdx, val: val });
                        prop.color = color;
                        curColIdx++;
                      } else {
                        prop.color = color;
                      }

                      // if (curColIdx > colorTable.length ) curColIdx = 0;
                    } else {
                      let geojson = e.geoJson;

                      geojson.features[i].properties.color =
                        'rgba(255, 255, 255, 0)';

                      e.geoJson = geojson;
                    }
                  }

                  // ID
                  const FRMWRK_LAYER_ID = 'farmwork_request_' + e.frmwkt_req_id;
                  // Source
                  const FRMWRK_SRC_ACTIVE = {
                    type: 'geojson',
                    data: e.geoJson,
                  };
                  // Layer Style
                  const FRMWRK_LAYER_STYLE_ACTIVE = {
                    id: FRMWRK_LAYER_ID,
                    type: 'fill',
                    source: FRMWRK_SRC_ACTIVE,
                    layout: {
                      visibility: 'visible',
                    },
                    paint: {
                      'fill-color': ['get', 'color'],
                      'fill-opacity': 1.0,
                      'fill-outline-color': 'rgba(255, 255, 255, 0.8)',
                    },
                  };

                  copyArrayID.unshift(FRMWRK_LAYER_ID);
                  copyArraySRC.unshift(FRMWRK_SRC_ACTIVE);
                  copyArraySTY.unshift(FRMWRK_LAYER_STYLE_ACTIVE);
                  copyArrayVIS.unshift(true);
                  copyArrayTY.unshift('frmwrk');
                }
              }
            }
          }
          // 드론 로그 업로드
          else if (e.layerType == 5) {
            let index = -1;
            copyArrayID &&
              copyArrayID.map((id) => {
                // console.log(id);

                if (id == 'drone_log_layer_' + e.log_sn) {
                  index = i;
                }
              });
            if (index == -1) {
              if (e.log_sn) {
                // ID
                const DRN_LOG_LAYER_ID = 'drone_log_layer_' + e.log_sn;

                // Source
                const DRN_LOG_SRC_ACTIVE = {
                  type: 'geojson',
                  data: {
                    type: 'FeatureCollection',
                    features: drnLogLayerFea,
                  },
                };

                // Layer Style
                const DRN_LAYER_STYLE_ACTIVE = {
                  id: DRN_LOG_LAYER_ID,
                  type: 'fill',
                  source: DRN_LOG_SRC_ACTIVE,
                  layout: {
                    visibility: 'visible',
                  },
                  paint: {
                    'fill-color': ['get', 'color'],
                    'fill-opacity': 1.0,
                    'fill-outline-color': 'rgba(255, 255, 255, 0.8)',
                  },
                };

                copyArrayID.unshift(DRN_LOG_LAYER_ID);
                copyArraySRC.unshift(DRN_LOG_SRC_ACTIVE);
                copyArraySTY.unshift(DRN_LAYER_STYLE_ACTIVE);
                copyArrayVIS.unshift(true);
                copyArrayTY.unshift('drone_log');
              }
            }
          }
          // 농작업 요청(경작자)
          else if (e.layerType == 4) {
            let index = -1;

            copyArrayID &&
              copyArrayID.map((id) => {
                if (id == 'farmwork_request_' + e.frmwkt_req_id) {
                  index = i;
                }
              });
            // e.geoJson = JSON.parse(e.geoJson);
            // console.log("e.geoJson", e.geoJson);
            if (e.geoJson) {
              if (index == -1) {
                if (e.frmwkt_req_id) {
                  let valueArray = [];

                  for (let i = 0; i < e.geoJson.features.length; i++) {
                    let val = e.geoJson.features[i].properties.value;
                    valueArray.push(val);
                  }

                  var maxValue = Math.max(...valueArray);
                  var minValue = Math.min(...valueArray);

                  let registerTable = [];
                  let curColIdx = 0;

                  for (let i = 0; i < e.geoJson.features.length; i++) {
                    let prop = e.geoJson.features[i].properties;
                    let val = e.geoJson.features[i].properties.value;
                    let color = getColorForPercentage(val, minValue, maxValue);

                    // if (prop.color) {
                    //   break;
                    // }

                    let exist = registerTable.find((el) => {
                      return el.val == val;
                    });

                    if (!exist) {
                      registerTable.push({ index: curColIdx, val: val });
                      prop.color = color;
                      curColIdx++;
                    } else {
                      prop.color = color;
                    }

                    // if (curColIdx > colorTable.length ) curColIdx = 0;
                  }

                  // ID
                  const FRMWRK_LAYER_ID = 'farmwork_request_' + e.frmwkt_req_id;
                  // Source
                  const FRMWRK_SRC_ACTIVE = {
                    type: 'geojson',
                    data: e.geoJson,
                  };
                  // Layer Style
                  const FRMWRK_LAYER_STYLE_ACTIVE = {
                    id: FRMWRK_LAYER_ID,
                    type: 'fill',
                    source: FRMWRK_SRC_ACTIVE,
                    layout: {
                      visibility: 'visible',
                    },
                    paint: {
                      'fill-color': ['get', 'color'],
                      'fill-opacity': 1.0,
                      'fill-outline-color': 'rgba(255, 255, 255, 0.8)',
                    },
                  };

                  copyArrayID.unshift(FRMWRK_LAYER_ID);
                  copyArraySRC.unshift(FRMWRK_SRC_ACTIVE);
                  copyArraySTY.unshift(FRMWRK_LAYER_STYLE_ACTIVE);
                  copyArrayVIS.unshift(true);
                  copyArrayTY.unshift('frmwrk');
                }
              }
            } else {
              setPopupMsg(
                `농작업 데이터가 없습니다.\n관리자에게 문의 부탁드립니다.`
              );
              setShowPopup(true);
            }
          }
          // 농작업 지원(기관)
          else if (e.layerType == 6) {
            let index = -1;

            copyArrayID &&
              copyArrayID.map((id) => {
                if (id == 'farmwork_request_' + e.frmwkt_req_id) {
                  index = i;
                }
              });
            // e.geoJson = JSON.parse(e.geoJson);
            // console.log("e.geoJson", e.geoJson);
            if (e.geoJson) {
              if (index == -1) {
                if (e.frmwkt_req_id) {
                  let valueArray = [];

                  for (let i = 0; i < e.geoJson.features.length; i++) {
                    let val = e.geoJson.features[i].properties.value;
                    valueArray.push(val);
                  }

                  var maxValue = Math.max(...valueArray);
                  var minValue = Math.min(...valueArray);

                  let registerTable = [];
                  let curColIdx = 0;

                  for (let i = 0; i < e.geoJson.features.length; i++) {
                    let prop = e.geoJson.features[i].properties;
                    let val = e.geoJson.features[i].properties.value;
                    let color = getColorForPercentage(val, minValue, maxValue);

                    // if (prop.color) {
                    //   break;
                    // }

                    let exist = registerTable.find((el) => {
                      return el.val == val;
                    });

                    if (!exist) {
                      registerTable.push({ index: curColIdx, val: val });
                      prop.color = color;
                      curColIdx++;
                    } else {
                      prop.color = color;
                    }

                    // if (curColIdx > colorTable.length ) curColIdx = 0;
                  }

                  // ID
                  const FRMWRK_LAYER_ID = 'farmwork_request_' + e.frmwkt_req_id;
                  // Source
                  const FRMWRK_SRC_ACTIVE = {
                    type: 'geojson',
                    data: e.geoJson,
                  };
                  // Layer Style
                  const FRMWRK_LAYER_STYLE_ACTIVE = {
                    id: FRMWRK_LAYER_ID,
                    type: 'fill',
                    source: FRMWRK_SRC_ACTIVE,
                    layout: {
                      visibility: 'visible',
                    },
                    paint: {
                      'fill-color': ['get', 'color'],
                      'fill-opacity': 1.0,
                      'fill-outline-color': 'rgba(255, 255, 255, 0.8)',
                    },
                  };

                  copyArrayID.unshift(FRMWRK_LAYER_ID);
                  copyArraySRC.unshift(FRMWRK_SRC_ACTIVE);
                  copyArraySTY.unshift(FRMWRK_LAYER_STYLE_ACTIVE);
                  copyArrayVIS.unshift(true);
                  copyArrayTY.unshift('frmwrk');
                }
              }
            } else {
              setPopupMsg(
                `농작업 데이터가 없습니다.\n관리자에게 문의 부탁드립니다.`
              );
              setShowPopup(true);
            }
          }
        });
    }

    set_ID_ARRAY(copyArrayID);
    set_SRC_ARRAY(copyArraySRC);
    set_LAYER_STYLE_ARRAY(copyArraySTY);
    set_VISIBLE_ARRAY(copyArrayVIS);
    set_TYPE_ARRAY(copyArrayTY);
  }

  function displayItrst() {
    if (props.selectItrst) {
      const array = [];

      const ITRST_SRC_ACTIVE = {
        type: 'geojson',
        data: JSON.parse(props.selectItrst.geojson),
      };

      const ITRST_LAYER_STYLE_ACTIVE = {
        id: ITRST_LAYER_ID,
        type: 'fill',
        source: ITRST_SRC_ACTIVE,
        layout: { visibility: 'visible' },
        paint: {
          'fill-color': 'rgba(0, 0, 0, 0.0)',
        },
      };

      const ITRST_SRC_ACTIVE_DUMMY = {
        type: 'geojson',
        data: JSON.parse(props.selectItrst.geojson),
      };

      const ITRST_LAYER_STYLE_ACTIVE_DUMMY = {
        id: ITRST_LAYER_ID_DUMMY,
        type: 'line',
        source: ITRST_SRC_ACTIVE_DUMMY,
        layout: { visibility: 'visible' },
        paint: {
          'line-color': itrstColor,
          'line-width': itrstLine,
        },
      };

      array.push(
        <Source key={ITRST_LAYER_ID} {...ITRST_SRC_ACTIVE}>
          <Layer {...ITRST_LAYER_STYLE_ACTIVE} />
        </Source>
      );

      array.push(
        <Source key={ITRST_LAYER_ID_DUMMY} {...ITRST_SRC_ACTIVE_DUMMY}>
          <Layer {...ITRST_LAYER_STYLE_ACTIVE_DUMMY} />
        </Source>
      );

      return array;
    }
  }

  function displayLayer() {
    if (activeLayerList && delMode != -2) {
      if (activeLayerList.length === ID_ARRAY.length) {
        const array = [];
        // for(let i = 0; i < ID_ARRAY.length; i++)
        for (let i = ID_ARRAY.length - 1; i >= 0; i--) {
          {
            array.push(
              <Source key={i} {...SRC_ARRAY[i]}>
                <Layer {...LAYER_STYLE_ARRAY[i]} />
              </Source>
            );
          }
        }

        return array;
      }
    }
  }

  function displayVILayer() {
    if (curActiveLayer && vegIndexImage) {
      const array = [];
      const VI_IMG_LAYER_ID = 'vegetation-index-image' + vegIndexImage.name;
      let VIMAP_SRC = {};
      let VIMAP_LAYER_STYLE = {};

      if (vegIndexImage.type == 'GeoserverLayer') {
        // Source
        VIMAP_SRC = {
          type: 'raster',
          tiles: [
            config.geoServer +
              '/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&LAYER=' +
              vegIndexImage.path +
              '&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&format=image%2Fpng&TileCol={x}&TileRow={y}',
          ],
          bounds: [
            vegIndexImage.bbox[0],
            vegIndexImage.bbox[1],
            vegIndexImage.bbox[2],
            vegIndexImage.bbox[3],
          ],
        };

        // Layer Style
        VIMAP_LAYER_STYLE = {
          id: VI_IMG_LAYER_ID,
          type: 'raster',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'raster-opacity': 1.0,
          },
        };
      } else if (vegIndexImage.type == 'Image') {
        VIMAP_SRC = {
          type: 'image',
          url: vegIndexImage.path,
          coordinates: [
            [vegIndexImage.coords.topLeft[0], vegIndexImage.coords.topLeft[1]],
            [
              vegIndexImage.coords.topRight[0],
              vegIndexImage.coords.topRight[1],
            ],
            [
              vegIndexImage.coords.bottomRight[0],
              vegIndexImage.coords.bottomRight[1],
            ],
            [
              vegIndexImage.coords.bottomLeft[0],
              vegIndexImage.coords.bottomLeft[1],
            ],
          ],
        };
        VIMAP_LAYER_STYLE = {
          id: VI_IMG_LAYER_ID,
          type: 'raster',
          source: VIMAP_SRC,
          paint: {
            'raster-opacity': 1.0,
          },
        };
      }

      array.push(
        <Source key={VI_IMG_LAYER_ID} {...VIMAP_SRC}>
          <Layer {...VIMAP_LAYER_STYLE} />
        </Source>
      );

      return array;
    }
  }

  var getColorForPercentage = function (pct, min, max) {
    var percentColors = [
      { pct: min, color: { r: 0x00, g: 0xff, b: 0x00 } }, // 0 255 0 	#00ff00
      { pct: (min + max) / 2, color: { r: 0xff, g: 0xff, b: 0x00 } }, // 255 255 0  #ffff00
      { pct: max, color: { r: 0xff, g: 0x00, b: 0x00 } }, // 255 0 0  #ff0000
    ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    // console.log("color", percentColors);
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  };

  var getColorForPercentageTheme = function (pct, legendInfo) {
    let length = legendInfo.legend.length;

    var percentColors = [
      {
        pct: legendInfo.min,
        color: {
          r: legendInfo.legend[0].color[0],
          g: legendInfo.legend[0].color[1],
          b: legendInfo.legend[0].color[2],
        },
      },
      // { pct: ((legendInfo.min + legendInfo.max) / 2), color: { r: legendInfo.legend[Math.ceil((length-1)/2)].color[0], g: legendInfo.legend[Math.ceil((length-1)/2)].color[1], b: legendInfo.legend[Math.ceil((length-1)/2)].color[2] } },
      {
        pct: legendInfo.max,
        color: {
          r: legendInfo.legend[length - 1].color[0],
          g: legendInfo.legend[length - 1].color[1],
          b: legendInfo.legend[length - 1].color[2],
        },
      },
    ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;

    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;

    if (isNaN(pctLower)) {
      pctLower = 0;
    }

    if (isNaN(pctUpper)) {
      pctUpper = 1;
    }

    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  };

  var getColorForDivider = function (val, legendInfo) {
    if (val != null) {
      let index = 0;
      for (let i = 0; i < legendInfo.legend.length; i++) {
        if (val >= legendInfo.legend[i].value) {
          index++;
        } else {
          return 'rgba(' + legendInfo.legend[index].color.join(',') + ')';
        }
      }

      if (index == legendInfo.legend.length) {
        return (
          'rgba(' +
          legendInfo.legend[legendInfo.legend.length - 1].color.join(',') +
          ')'
        );
      }
    } else {
      return 'rgba(255, 255, 255, 0)';
    }
  };

  function layerOn(e) {
    const arrayStyle = [...LAYER_STYLE_ARRAY];

    arrayStyle[e].layout = { visibility: 'visible' };

    set_LAYER_STYLE_ARRAY(arrayStyle);

    const arrayVisible = [...VISIBLE_ARRAY];
    arrayVisible[e] = true;
    set_VISIBLE_ARRAY(arrayVisible);
  }

  function layerOff(e) {
    const arrayStyle = [...LAYER_STYLE_ARRAY];

    arrayStyle[e].layout = { visibility: 'none' };

    set_LAYER_STYLE_ARRAY(arrayStyle);

    const arrayVisible = [...VISIBLE_ARRAY];
    arrayVisible[e] = false;
    set_VISIBLE_ARRAY(arrayVisible);
  }

  function layerDel(e) {
    const array = [...activeLayerList];
    array.splice(e, 1);
    setActiveLayerList(array);
  }

  // 팜맵 단일 선택
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [selectedGeo, setsetSelectedGeo] = useState(null);

  const [clickValue, setClickValue] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [isRaster, setIsRaster] = useState(false);

  function displayTooltip() {
    if (clickValue !== '' && clickValue !== undefined) {
      return (
        <Marker latitude={tooltipPosition.top} longitude={tooltipPosition.left}>
          {/* <div className="onclickTooptip">{clickValue}</div> */}
          <div
            className={`onclickTooptip ${
              props.isMobile && isRaster ? 'mobile-raster' : ''
            }`}
          >
            {clickValue}
          </div>
        </Marker>
      );
    }
    return null;
  }

  useEffect(() => {
    setClickValue('');
  }, [curActiveLayer]);

  useEffect(() => {
    setClickValue('');
  }, [props.farmworkSelect]);

  const onclickmap = (e) => {
    // Onclick value
    if (activeLayerList && activeLayerList.length != 0) {
      // 드론영상
      if (activeLayerList[0].layerType == 1) {
        setIsRaster(true);
        const top = e.lngLat.lat;
        const left = e.lngLat.lng;
        setTooltipPosition({ top, left });

        const pointCoord4326 = [e.lngLat.lng, e.lngLat.lat];
        const pointCoord5179 = proj4('EPSG:4326', 'EPSG:5179', pointCoord4326);
        const offset = 1;
        const bboxArray = [
          pointCoord5179[0] - offset,
          pointCoord5179[1] - offset,
          pointCoord5179[0] + offset,
          pointCoord5179[1] + offset,
        ];

        const bbox =
          bboxArray[0] +
          ',' +
          bboxArray[1] +
          ',' +
          bboxArray[2] +
          ',' +
          bboxArray[3];

        const layer = activeLayerList[0];

        // const getFeatureUrl = `http://geocapture.co.kr:8080/geoserver/famid-dev/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo`
        const getFeatureUrl =
          config.geoServer +
          `/famid-dev/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo` +
          `&FORMAT=image%2Fjpeg&TRANSPARENT=true&QUERY_LAYERS=${layer.layer_nm}&STYLES&LAYERS=${layer.layer_nm}` +
          `&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application/json&FEATURE_COUNT=50&X=50&Y=50&SRS=EPSG%3A5179&WIDTH=101&HEIGHT=101` +
          `&BBOX=${bbox}`;

        setClickValue('');

        axios({
          method: 'get',
          url: getFeatureUrl,
          headers: {
            Authorization: `Bearer {${APIToken}}`,
          },
        })
          .then((response) => {
            if (response.data.features.length > 0 && !props.farmworkSelect) {
              // if (
              //   response.data.features[0].properties.hasOwnProperty("Band1")
              // ) {
              //   const value =
              //     `B1 : ` +
              //     `${response.data.features[0].properties.Band1.toFixed(6)}` +
              //     ` , ` +
              //     `B2 : ` +
              //     `${response.data.features[0].properties.Band2.toFixed(6)}` +
              //     ` , ` +
              //     `B3 : ` +
              //     `${response.data.features[0].properties.Band3.toFixed(6)}`;
              //   setClickValue(value);
              // } else if (
              //   response.data.features[0].properties.hasOwnProperty(
              //     "GRAY_INDEX"
              //   )
              // ) {
              //   const value =
              //     `B1 : ` +
              //     `${response.data.features[0].properties.GRAY_INDEX.toFixed(
              //       6
              //     )}`;
              //   setClickValue(value);
              // }

              let propertiesOfImg = response.data.features[0].properties;
              const { ALPHA_BAND, ...filteredObject } = propertiesOfImg; // ALPHA_BAND 제외 후 filteredObject 생성

              let value = '';
              const keys = Object.keys(filteredObject);
              keys.forEach((key, index) => {
                let displayValue = filteredObject[key];
                if (
                  typeof displayValue === 'number' &&
                  !Number.isInteger(displayValue)
                ) {
                  displayValue = displayValue.toFixed(3);
                }
                value += `${key} : ${displayValue}`;
                if (index < keys.length - 1) {
                  value += '\n';
                }
              });
              setClickValue(value);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // 주제도
      else if (activeLayerList[0].layerType == 2) {
        let file_format = '';

        if (activeLayerList[0].hasOwnProperty('file_path')) {
          file_format = activeLayerList[0].file_path.substr(
            activeLayerList[0].file_path.length - 3,
            3
          );
        }

        if (file_format == 'tif') {
          setIsRaster(true);
          const top = e.lngLat.lat;
          const left = e.lngLat.lng;
          setTooltipPosition({ top, left });

          const pointCoord4326 = [e.lngLat.lng, e.lngLat.lat];
          const pointCoord5179 = proj4(
            'EPSG:4326',
            'EPSG:5179',
            pointCoord4326
          );
          const offset = 1;
          const bboxArray = [
            pointCoord5179[0] - offset,
            pointCoord5179[1] - offset,
            pointCoord5179[0] + offset,
            pointCoord5179[1] + offset,
          ];

          const bbox =
            bboxArray[0] +
            ',' +
            bboxArray[1] +
            ',' +
            bboxArray[2] +
            ',' +
            bboxArray[3];

          const layer = activeLayerList[0];

          // const getFeatureUrl = `http://geocapture.co.kr:8080/geoserver/famid-dev/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo`
          const getFeatureUrl =
            config.geoServer +
            `/famid-dev/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo` +
            `&FORMAT=image%2Fjpeg&TRANSPARENT=true&QUERY_LAYERS=${layer.layer_nm}&STYLES&LAYERS=${layer.layer_nm}` +
            `&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application/json&FEATURE_COUNT=50&X=50&Y=50&SRS=EPSG%3A5179&WIDTH=101&HEIGHT=101` +
            `&BBOX=${bbox}`;

          setClickValue('');

          axios({
            method: 'get',
            url: getFeatureUrl,
            headers: {
              Authorization: `Bearer {${APIToken}}`,
            },
          })
            .then((response) => {
              if (response.data.features.length > 0 && !props.farmworkSelect) {
                // if (
                //   response.data.features[0].properties.hasOwnProperty("Band1")
                // ) {
                //   const value =
                //     `B1 : ` +
                //     `${response.data.features[0].properties.Band1.toFixed(6)}` +
                //     ` , ` +
                //     `B2 : ` +
                //     `${response.data.features[0].properties.Band2.toFixed(6)}` +
                //     ` , ` +
                //     `B3 : ` +
                //     `${response.data.features[0].properties.Band3.toFixed(6)}`;
                //   setClickValue(value);
                // } else if (
                //   response.data.features[0].properties.hasOwnProperty(
                //     "GRAY_INDEX"
                //   )
                // ) {
                //   const value =
                //     `B1 : ` +
                //     `${response.data.features[0].properties.GRAY_INDEX.toFixed(
                //       6
                //     )}`;
                //   setClickValue(value);
                // }
                let propertiesOfTheme = response.data.features[0].properties;

                let value = '';
                const keys = Object.keys(propertiesOfTheme);
                keys.forEach((key, index) => {
                  let displayValue = propertiesOfTheme[key];
                  if (
                    typeof displayValue === 'number' &&
                    !Number.isInteger(displayValue)
                  ) {
                    displayValue = displayValue.toFixed(3);
                  }
                  value += `${key} : ${displayValue}`;
                  if (index < keys.length - 1) {
                    value += ',  ';
                  }
                });

                setClickValue(value);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (file_format == 'shp' || file_format == 'zip') {
          setIsRaster(false);
          setClickValue('');
          const top = e.lngLat.lat;
          const left = e.lngLat.lng;
          setTooltipPosition({ top, left });

          if (!props.farmworkSelect) {
            mapRef.current.on('click', ID_ARRAY[0], function (i) {
              var feature = i.features[0];
              var featureProperties = feature.properties;
              if (
                curActiveLayer.hasOwnProperty('attr_info') &&
                curActiveLayer.attr_info != null
              ) {
                const attrName = curActiveLayer.attr_info;
                Object.keys(featureProperties).forEach((e) => {
                  if (attrName == e) {
                    if (typeof featureProperties[e] === 'number') {
                      let attrValue = featureProperties[e].toFixed(3);
                      const value = `${attrName} : ` + `${attrValue}`;
                      setClickValue(value);
                    } else if (
                      featureProperties[e] === '' ||
                      featureProperties[e] === null
                    ) {
                      const value = `${attrName} : null`;
                      setClickValue(value);
                    } else {
                      const value =
                        `${attrName} : ` + `${featureProperties[e]}`;
                      setClickValue(value);
                    }
                  }
                });
              } else {
                if (featureProperties.hasOwnProperty('attr1')) {
                  const value = `ATTR : ` + `${featureProperties.attr1}`;
                  setClickValue(value);
                } else if (featureProperties.hasOwnProperty('attr_1')) {
                  const value = `ATTR : ` + `${featureProperties.attr_1}`;
                  setClickValue(value);
                } else if (featureProperties.hasOwnProperty('name')) {
                  const value = `NAME : ` + `${featureProperties.name}`;
                  setClickValue(value);
                } else if (featureProperties.hasOwnProperty('VALUE')) {
                  const value = `VALUE : ` + `${featureProperties.VALUE}`;
                  setClickValue(value);
                } else if (featureProperties.hasOwnProperty('value')) {
                  const value = `VALUE : ` + `${featureProperties.value}`;
                  setClickValue(value);
                } else if (featureProperties.hasOwnProperty('area')) {
                  let area;
                  if (typeof featureProperties.area === 'number')
                    area = featureProperties.area.toFixed(3);
                  const value = `AREA : ` + `${area}`;
                  setClickValue(value);
                } else if (featureProperties.hasOwnProperty('AREA')) {
                  let area;

                  if (typeof featureProperties.AREA === 'number')
                    area = featureProperties.AREA.toFixed(3);
                  const value = `AREA : ` + `${area}`;
                  setClickValue(value);
                } else {
                  setClickValue(undefined);
                }
              }
            });
          }
        } else {
          console.log('Type Error.');
        }
      }

      // 농작업
      else if (activeLayerList[0].layerType == 3) {
        setIsRaster(false);
        setClickValue('');
        const top = e.lngLat.lat;
        const left = e.lngLat.lng;
        setTooltipPosition({ top, left });

        if (!props.farmworkSelect) {
          mapRef.current.on('click', ID_ARRAY[0], function (i) {
            let feature = i.features[0];
            let featureProperties = feature.properties;
            let value;
            if (
              featureProperties.value !== null &&
              featureProperties.value !== undefined &&
              !props.farmworkSelect
            ) {
              value =
                `Id : ${feature.id}\n` +
                `Value : ${featureProperties.value.toFixed(3)}`;
            } else {
              value = '';
            }
            setClickValue(value);
          });
        }
      }

      // 농작업 요청 및 지원
      else if (
        activeLayerList[0].layerType == 4 ||
        activeLayerList[0].layerType == 6
      ) {
        setIsRaster(false);
        setClickValue('');
        const top = e.lngLat.lat;
        const left = e.lngLat.lng;
        setTooltipPosition({ top, left });

        mapRef.current.on('click', ID_ARRAY[0], function (i) {
          var feature = i.features[0];
          var featureProperties = feature.properties;
          const value =
            `Id : ${feature.id}\n` +
            `Value : ${featureProperties.value.toFixed(6)}`;
          setClickValue(value);
        });
      }
    }

    if (props.selectItrst) {
      const geoJson = JSON.parse(props.selectItrst.geojson);
      const geoJsonFeature = geoJson.features;
      const selectedFeature = mapRef.current.queryRenderedFeatures(e.point, {
        layers: [ITRST_LAYER_ID],
      });
      if (selectedFeature.length != 0) {
        // 선택한 영역에 팜맵ID 없을 때
        if (
          selectedFeature[0].properties.shp_id !== null &&
          selectedFeature[0].properties.shp_id !== undefined
        ) {
          const selectedFeatureID = selectedFeature[0].properties.shp_id;
          let queryFeature;

          geoJsonFeature &&
            geoJsonFeature.map((e) => {
              if (e.properties.shp_id == selectedFeatureID) {
                queryFeature = e;
              }
            });

          if (queryFeature) {
            setSelectedFarm(queryFeature.properties);
            setsetSelectedGeo(queryFeature.geometry.coordinates);
            setFarmworkCoord([e.lngLat.lng, e.lngLat.lat]);
          }
        }
        // 선택한 영역에 팜맵ID 있을 때
        else if (selectedFeature[0].properties.FMAP_INNB) {
          const selectedFeatureID = selectedFeature[0].properties.FMAP_INNB;

          let queryFeature;

          geoJsonFeature &&
            geoJsonFeature.map((e) => {
              if (e.properties.FMAP_INNB == selectedFeatureID) {
                queryFeature = e;
              }
            });

          if (queryFeature) {
            setSelectedFarm(queryFeature.properties);
            setsetSelectedGeo(queryFeature.geometry.coordinates);
            setFarmworkCoord([e.lngLat.lng, e.lngLat.lat]);
          }
        }
        // else if (selectedFeature[0].properties.ID) {
        //   const selectedFeatureID = selectedFeature[0].properties.ID;
        //   let queryFeature;

        //   geoJsonFeature &&
        //     geoJsonFeature.map((e) => {
        //       if (e.properties.ID == selectedFeatureID) {
        //         queryFeature = e;
        //       }
        //     });

        //   if (queryFeature) {
        //     setSelectedFarm(queryFeature.properties);
        //     setsetSelectedGeo(queryFeature.geometry.coordinates);
        //     setFarmworkCoord([e.lngLat.lng, e.lngLat.lat]);
        //   }
        // }
        else {
          if (props.farmworkSelect) {
            props.farmworkSelectHandler(false);
            props.setMainHidden(true);
            onFarmmapHandler(false);
            // setRequestFarmwork(true);
            const map = mapRef.current.getMap();

            if (map.getLayer('maskLayer')) {
              map.removeLayer('maskLayer');
            }
            map.setPaintProperty(
              'selected_ItrstZone',
              'fill-color',
              'rgba(0, 0, 0, 0.0)'
            );
            map.setPaintProperty('selected_ItrstZone', 'fill-opacity', 0);

            setShowPopup(true);
            setPopupMsg(
              '해당 필지는 농작업 요청을 할 수 없습니다.\n 관리자에게 문의해주세요.'
            );
          }
        }
      }
    }
  };

  // const [selectedPolygonDummy, setSelectedPolygonDummy] = useStaste([]);

  useEffect(() => {
    if (onFarmMap) {
      if (selectedFarm) {
        if (props.showThemeMapAdd && visualDetection && selectedMode == 2) {
          let flag = false;
          const itrstGeojson = JSON.parse(props.selectItrst.geojson);

          itrstGeojson.features.map((e) => {
            if (e.properties.FMAP_INNB == selectedFarm.FMAP_INNB) {
              flag = true;
            }
          });

          // 관심구역이 shp 파일인 경우(추후 'Id'로 되어있는 부분 특정 Id로 교체할 것)
          itrstGeojson.features.map((e) => {
            if (e.properties.shp_id == selectedFarm.shp_id) {
              flag = true;
            }
          });

          if (flag) {
            let geometry;

            let tempArray = selectedGeo[0][0][0];

            if (Array.isArray(tempArray) == true) {
              geometry = {
                type: 'MultiPolygon',
                coordinates: selectedGeo,
              };
            } else {
              geometry = {
                type: 'MultiPolygon',
                coordinates: [selectedGeo],
              };
            }

            // 관심구역이 shp 파일인 경우 - 면적 계산(기존에 계산된 면적이 없으므로 turf를 통해 계산)
            let area;
            if (selectedFarm.area) {
              area = selectedFarm.area;
            } else {
              const calPolygonArea = {
                type: 'Feature',
                geometry,
              };
              area = turf.area(calPolygonArea);
            }

            let feature;
            if (selectedFarm.ID) {
              feature = {
                type: 'Feature',
                properties: {
                  ID: selectedFarm.ID,
                  FMAP_INNB: selectedFarm.FMAP_INNB,
                  FL_NM: selectedFarm.FL_NM,
                  FL_CD: selectedFarm.FL_CD,
                  STDG_CD: selectedFarm.STDG_CD,
                  address: selectedFarm.address,
                  PNU: selectedFarm.PNU,
                  LDCG_CD: selectedFarm.LDCG_CD,
                  SB_PNU: selectedFarm.SB_PNU,
                  SB_LDCG_CD: selectedFarm.SB_LDCG_CD,
                  CAD_CON_CN: selectedFarm.CAD_CON_CN,
                  SOURCE_NM: selectedFarm.SOURCE_NM,
                  SOURCE_CD: selectedFarm.SOURCE_CD,
                  FLIGHT_YMD: selectedFarm.FLIGHT_YMD,
                  UPDT_YMD: selectedFarm.UPDT_YMD,
                  UPDT_TP_NM: selectedFarm.UPDT_TP_NM,
                  UPDT_TP_CD: selectedFarm.UPDT_TP_CD,
                  CAUSE_NM: selectedFarm.CAUSE_NM,
                  CAUSE_CD: selectedFarm.CAUSE_CD,
                  L3_YN: selectedFarm.L3_YN,
                  O_UID: selectedFarm.O_UID,
                  O_FL_NM: selectedFarm.O_FL_NM,
                  AREA: Number(area),
                  color: selCropColor,
                  name: selCropName,
                },
                geometry: geometry,
              };
            } else {
              feature = {
                type: 'Feature',
                properties: {
                  FMAP_INNB: selectedFarm.FMAP_INNB,
                  VDPT_YR: selectedFarm.VDPT_YR,
                  PNU_LNM_CD: selectedFarm.PNU_LNM_CD,
                  LGL_EMD_CD: selectedFarm.LGL_EMD_CD,
                  LGL_EMD_NM: selectedFarm.LGL_EMD_NM,
                  CHG_CFCD: selectedFarm.CHG_CFCD,
                  CHG_CFNM: selectedFarm.CHG_CFNM,
                  LNM: selectedFarm.LNM,
                  INTPR_CD: selectedFarm.INTPR_CD,
                  INTPR_NM: selectedFarm.INTPR_NM,
                  INVD_CFCD: selectedFarm.INVD_CFCD,
                  INVD_CFNM: selectedFarm.INVD_CFNM,
                  ITPINP_DE: selectedFarm.ITPINP_DE,
                  RNHST_CD: selectedFarm.RNHST_CD,
                  RNHST_NM: selectedFarm.RNHST_NM,
                  AREA: Number(area),
                  color: selCropColor,
                  name: selCropName,
                },
                geometry: geometry,
              };
            }

            let matchingArray = cropColorMap;
            // console.log("matchingArray", matchingArray); // CROP_AREA,,,

            let select = null;
            let select_none = null;

            matchingArray &&
              matchingArray.map((e) => {
                if (e.CROP_ID == visualDetection - 1) {
                  select = e;
                } else {
                  if (e.CROP_GEOJSON.length !== 0) {
                    // 관심구역 shp 등록
                    if (
                      selectedFarm.shp_id !== null &&
                      selectedFarm.shp_id !== undefined
                    ) {
                      e.CROP_FMAPID &&
                        e.CROP_FMAPID.map((id) => {
                          if (id === selectedFarm.shp_id) {
                            select_none = e;
                          }
                        });
                    } else {
                      // 관심구역 팝맵 등록
                      if (selectedFarm.FMAP_INNB) {
                        e.CROP_FMAPID &&
                          e.CROP_FMAPID.map((id) => {
                            if (id === selectedFarm.FMAP_INNB) {
                              select_none = e;
                            }
                          });
                      }
                      // else {
                      //   // 그 외
                      //   e.CROP_FMAPID &&
                      //     e.CROP_FMAPID.map((id) => {
                      //       if (id === selectedFarm.Id) {
                      //         select_none = e;
                      //       }
                      //     });
                      // }
                    }
                  }
                }
              });

            let index = -1;
            let index_none = -1;

            if (select) {
              // console.log("★★ select : ", select);
              select.CROP_FMAPID &&
                select.CROP_FMAPID.map((e, i) => {
                  if (e === selectedFarm.shp_id) {
                    index = i;
                  } else {
                    if (e === selectedFarm.FMAP_INNB) {
                      index = i;
                    }
                    // else if (e === selectedFarm.Id) {
                    //   index = i;
                    // }
                  }
                });
            }

            if (select_none) {
              // console.log("★★ select_none : ", select_none);
              select_none.CROP_FMAPID &&
                select_none.CROP_FMAPID.map((e, i) => {
                  if (e === selectedFarm.shp_id) {
                    index_none = i;
                  } else {
                    if (e === selectedFarm.FMAP_INNB) {
                      index_none = i;
                    }
                    // else if (e === selectedFarm.Id) {
                    //   index_none = i;
                    // }
                  }
                });
            }

            // None
            if (index == -1 && index_none == -1) {
              if (
                selectedFarm.shp_id !== null &&
                selectedFarm.shp_id !== undefined
              ) {
                select.CROP_FMAPID.push(selectedFarm.shp_id);
              } else {
                if (selectedFarm.FMAP_INNB) {
                  select.CROP_FMAPID.push(selectedFarm.FMAP_INNB);
                }
                // else {
                //   select.CROP_FMAPID.push(selectedFarm.Id);
                // }
              }
              select.CROP_COUNT = select.CROP_COUNT + 1;
              select.CROP_AREA = Number(select.CROP_AREA) + area;
              // console.log(area);
              select.CROP_GEOJSON.push(feature);
            }
            // Same
            else if (index >= 0 && index_none == -1) {
              select.CROP_FMAPID.splice(index, 1);
              select.CROP_COUNT = select.CROP_COUNT - 1;
              select.CROP_AREA = Number(select.CROP_AREA) - area;
              select.CROP_GEOJSON.splice(index, 1);
            }
            // differ
            else if (index == -1 && index_none >= 0) {
              select_none.CROP_FMAPID.splice(index_none, 1);
              select_none.CROP_COUNT = select_none.CROP_COUNT - 1;
              select_none.CROP_AREA = Number(select_none.CROP_AREA) - area;
              select_none.CROP_GEOJSON.splice(index_none, 1);

              if (
                selectedFarm.shp_id !== null &&
                selectedFarm.shp_id !== undefined
              ) {
                select.CROP_FMAPID.push(selectedFarm.shp_id);
              } else {
                if (selectedFarm.FMAP_INNB) {
                  select.CROP_FMAPID.push(selectedFarm.FMAP_INNB);
                }
                // else {
                //   select.CROP_FMAPID.push(selectedFarm.Id);
                // }
              }
              select.CROP_COUNT = select.CROP_COUNT + 1;
              select.CROP_AREA = Number(select.CROP_AREA) + area;
              select.CROP_GEOJSON.push(feature);
            } else {
              console.log('Area duplication Error');
            }

            // console.log("matchingArray", matchingArray);
            setCropColorMap(matchingArray);

            let dummyArray = [];
            cropColorMap &&
              cropColorMap.map((e) => {
                if (e.CROP_FMAPID.length != 0) {
                  dummyArray.push([...e.CROP_FMAPID]);
                  dummyArray.push(e.CROP_COLOR);
                }
              });

            if (
              selectedFarm.shp_id !== null &&
              selectedFarm.shp_id !== undefined
            ) {
              if (dummyArray.length != 0) {
                highlightShpFillcrop([
                  'match',
                  ['get', 'shp_id'],
                  ...dummyArray,
                  'rgba(0, 0, 0, 0.0)',
                ]);
              } else {
                highlightShpFillcrop('rgba(0, 0, 0, 0.0)');
              }
            } else {
              if (selectedFarm.FMAP_INNB) {
                if (dummyArray.length != 0) {
                  highlightFarmMapFillcrop([
                    'match',
                    ['get', 'FMAP_INNB'],
                    ...dummyArray,
                    'rgba(255, 255, 255, 0.0)',
                  ]);
                } else {
                  highlightFarmMapFillcrop('rgba(255, 255, 255, 0.0)');
                }
              }
              // else {
              //   if (dummyArray.length != 0) {
              //     highlightShpFillcrop([
              //       "match",
              //       ["get", "Id"],
              //       ...dummyArray,
              //       "rgba(0, 0, 0, 0.0)",
              //     ]);
              //   } else {
              //     highlightShpFillcrop("rgba(0, 0, 0, 0.0)");
              //   }
              // }
            }
          }
        } else if (props.farmworkSelect) {
          if (
            selectedFarm.shp_id !== null &&
            selectedFarm.shp_id !== undefined
          ) {
            highlightShpFillcrop([
              'match',
              ['get', 'shp_id'],
              selectedFarm.shp_id,
              'rgba(0, 0, 255, 0.8)',
              'rgba(0, 0, 0, 0.0)',
            ]);
            setRequestFarmwork(true);
          } else {
            // 클릭했을 시
            if (selectedFarm.FMAP_INNB) {
              highlightFarmMapFill(
                selectedFarm.FMAP_INNB,
                'rgba(0, 0, 255, 0.8)'
              );
              setRequestFarmwork(true);
            }
            // else {
            //   highlightShpFillcrop([
            //     "match",
            //     ["get", "Id"],
            //     selectedFarm.Id,
            //     "rgba(0, 0, 255, 0.8)",
            //     "rgba(0, 0, 0, 0.0)",
            //   ]);
            //   setRequestFarmwork(true);
            // }
          }
          setClickFarmRequst(false);
        } else if (farmSelect) {
          if (
            selectedFarm.shp_id !== null &&
            selectedFarm.shp_id !== undefined
          ) {
            highlightShpFillcrop([
              'match',
              ['get', 'shp_id'],
              selectedFarm.shp_id,
              'rgba(0, 0, 255, 0.8)',
              'rgba(0, 0, 0, 0.0)',
            ]);
            setRegistFarmwork(true);
          } else {
            if (selectedFarm.FMAP_INNB) {
              highlightFarmMapFill(
                selectedFarm.FMAP_INNB,
                'rgba(0, 0, 255, 0.8)'
              );
              setRegistFarmwork(true);
            }
          }
        } else {
          if (selectedFarm.FMAP_INNB) {
            highlightFarmMapFill(
              selectedFarm.FMAP_INNB,
              'rgba(0, 0, 255, 0.0)'
            );
          }
        }
      }
    }
  }, [selectedFarm]);

  // 팜맵 영역 선택
  useEffect(() => {
    if (onFarmMap) {
      if (polygonCoord[0]) {
        if (props.showThemeMapAdd && visualDetection) {
          const polygonGeojson = {
            type: 'MultiPolygon',
            coordinates: [[polygonCoord[0].geometry.coordinates[0]]],
          };

          axios({
            method: 'get',
            url: `/api/fmap/search/area?geoJson=${JSON.stringify(
              polygonGeojson
            )}`,
            headers: {
              Authorization: `Bearer {${APIToken}}`,
            },
          })
            .then((response) => {
              // cropColorMap => 이미 지도에서 선택된 필지가 있는 경우 가져오게 됨
              let matchingArray = cropColorMap;

              const itrstGeojson = JSON.parse(props.selectItrst.geojson);

              // const encodedData = new TextEncoder().encode(
              //   JSON.stringify(props.selectItrst.geojson)
              // );
              // console.log("인코딩 관심구역", encodedData);

              // // ArrayBuffer를 다시 문자열로 디코딩합니다.
              // const decodedData = new TextDecoder("EUC-KR").decode(encodedData);
              // console.log("디코딩 관심구역", decodedData); // "인코딩 테스트 문자열"이 출력됩니다. EUC-KR  UTF-8
              // console.log("props.selectItrst.geojson", decodedData);

              let dummy = [];
              let selectedFeatureShp = { id: [], geometry: [] };

              // itrstGeojson의 각 Feature에 대해 겹치는 부분 찾기
              itrstGeojson.features.forEach((feature) => {
                const intersection = turf.intersect(feature, polygonGeojson);

                // 겹치는 부분 있으면 처리
                if (intersection) {
                  // console.log("겹치는 feature", feature);

                  let arr1;
                  if (
                    feature.properties.shp_id !== null &&
                    feature.properties.shp_id !== undefined
                  ) {
                    arr1 = feature.properties.shp_id;
                  } else {
                    if (feature.properties.FMAP_INNB) {
                      arr1 = feature.properties.FMAP_INNB;
                    }
                    // else {
                    //   arr1 = feature.properties.Id;
                    // }
                  }
                  dummy = [...dummy, arr1];

                  // 겹치는 부분 면적 계산
                  let area;
                  if (feature.properties.AREA) {
                    area = feature.properties.AREA;
                  } else {
                    feature.properties.AREA = turf.area(feature);
                  }

                  selectedFeatureShp.id = [
                    ...selectedFeatureShp.id,
                    feature.properties,
                  ];
                  selectedFeatureShp.geometry = [
                    ...selectedFeatureShp.geometry,
                    feature.geometry,
                  ];
                }
              });

              let select = null;
              let select_none = null;

              matchingArray &&
                matchingArray.map((e) => {
                  if (e.CROP_ID == visualDetection - 1) {
                    select = e;
                  }
                });

              const selectedFeature = response.data.data;

              let calArea;
              let geometry;

              // selectedFeature.map((e) => {
              selectedFeatureShp.id.map((e, num) => {
                let flag = false;

                itrstGeojson.features.map((i) => {
                  if (
                    i.properties.shp_id !== null &&
                    i.properties.shp_id !== undefined
                  ) {
                    if (i.properties.shp_id === e.shp_id) {
                      flag = true;
                      calArea = e.AREA;
                      geometry = selectedFeatureShp.geometry[num];
                    }
                  } else {
                    if (i.properties.FMAP_INNB === e.FMAP_INNB) {
                      flag = true;
                      calArea = e.AREA;
                      geometry = selectedFeatureShp.geometry[num];
                    }
                  }
                });

                if (flag) {
                  const feature = {
                    type: 'Feature',
                    properties: {
                      FMAP_INNB: e.FMAP_INNB,
                      VDPT_YR: e.VDPT_YR,
                      PNU_LNM_CD: e.PNU_LNM_CD,
                      LGL_EMD_CD: e.LGL_EMD_CD,
                      LGL_EMD_NM: e.LGL_EMD_NM,
                      CHG_CFCD: e.CHG_CFCD,
                      CHG_CFNM: e.CHG_CFNM,
                      LNM: e.LNM,
                      INTPR_CD: e.INTPR_CD,
                      INTPR_NM: e.INTPR_NM,
                      INVD_CFCD: e.INVD_CFCD,
                      INVD_CFNM: e.INVD_CFNM,
                      ITPINP_DE: e.ITPINP_DE,
                      RNHST_CD: e.RNHST_CD,
                      RNHST_NM: e.RNHST_NM,
                      // AREA: e.AREA,
                      AREA: calArea,
                      color: selCropColor,
                      name: selCropName,
                      shp_id: e.shp_id,
                    },
                    // geometry: JSON.parse(e.geojson),
                    geometry: geometry,
                  };

                  matchingArray &&
                    matchingArray.map((ma) => {
                      ma.CROP_FMAPID &&
                        ma.CROP_FMAPID.map((id) => {
                          if (e.shp_id !== null && e.shp_id !== undefined) {
                            if (id == e.shp_id) {
                              select_none = ma;
                            }
                          } else {
                            if (
                              e.FMAP_INNB !== null &&
                              e.FMAP_INNB !== undefined
                            ) {
                              if (id == e.FMAP_INNB) {
                                select_none = ma;
                              }
                            }
                            // else {
                            //   if (id == e.Id) {
                            //     select_none = ma;
                            //   }
                            // }
                          }
                        });
                    });

                  let index = -1;
                  let index_none = -1;

                  if (select) {
                    select.CROP_FMAPID &&
                      select.CROP_FMAPID.map((id, i) => {
                        if (e.shp_id !== null && e.shp_id !== undefined) {
                          if (id === e.shp_id) {
                            index = i;
                          }
                        } else {
                          if (
                            e.FMAP_INNB !== null &&
                            e.FMAP_INNB !== undefined
                          ) {
                            if (id === e.FMAP_INNB) {
                              index = i;
                            }
                          }
                          // else {
                          //   if (id === e.Id) {
                          //     index = i;
                          //   }
                          // }
                        }
                      });
                  }

                  if (select_none) {
                    select_none.CROP_FMAPID &&
                      select_none.CROP_FMAPID.map((id, i) => {
                        if (e.shp_id !== null && e.shp_id !== undefined) {
                          if (id === e.shp_id) {
                            index_none = i;
                          }
                        } else {
                          if (
                            e.FMAP_INNB !== null &&
                            e.FMAP_INNB !== undefined
                          ) {
                            if (id === e.FMAP_INNB) {
                              index_none = i;
                            }
                          }
                          // else {
                          //   if (id === e.Id) {
                          //     index_none = i;
                          //   }
                          // }
                        }
                      });
                  }

                  if (e.shp_id !== null && e.shp_id !== undefined) {
                    // shp
                    // None
                    if (index == -1 && index_none == -1) {
                      select.CROP_FMAPID.push(e.shp_id);
                      select.CROP_COUNT = select.CROP_COUNT + 1;
                      select.CROP_AREA = select.CROP_AREA + e.AREA;
                      select.CROP_GEOJSON.push(feature);
                    }
                    // Same
                    else if (index >= 0 && index_none == -1) {
                    }
                    // Differ
                    else if (index == -1 && index_none >= 0) {
                      select_none.CROP_FMAPID.splice(index_none, 1);
                      select_none.CROP_COUNT = select_none.CROP_COUNT - 1;
                      select_none.CROP_AREA = select_none.CROP_AREA - e.AREA;
                      select_none.CROP_GEOJSON.splice(index_none, 1);

                      select.CROP_FMAPID.push(e.shp_id);
                      select.CROP_COUNT = select.CROP_COUNT + 1;
                      select.CROP_AREA = select.CROP_AREA + e.AREA;
                      select.CROP_GEOJSON.push(feature);
                    } else {
                      // console.log("필지 중복선택 오류");
                    }
                  } else {
                    if (e.FMAP_INNB) {
                      // None
                      if (index == -1 && index_none == -1) {
                        select.CROP_FMAPID.push(e.FMAP_INNB);
                        select.CROP_COUNT = select.CROP_COUNT + 1;
                        select.CROP_AREA = select.CROP_AREA + e.AREA;
                        select.CROP_GEOJSON.push(feature);
                      }
                      // Same
                      else if (index >= 0 && index_none == -1) {
                      }
                      // Differ
                      else if (index == -1 && index_none >= 0) {
                        select_none.CROP_FMAPID.splice(index_none, 1);
                        select_none.CROP_COUNT = select_none.CROP_COUNT - 1;
                        select_none.CROP_AREA = select_none.CROP_AREA - e.AREA;
                        select_none.CROP_GEOJSON.splice(index_none, 1);

                        select.CROP_FMAPID.push(e.FMAP_INNB);
                        select.CROP_COUNT = select.CROP_COUNT + 1;
                        select.CROP_AREA = select.CROP_AREA + e.AREA;
                        select.CROP_GEOJSON.push(feature);
                      } else {
                        console.log('필지 중복선택 오류');
                      }
                    }
                    // else {
                    //   // None
                    //   if (index == -1 && index_none == -1) {
                    //     select.CROP_FMAPID.push(e.Id);
                    //     select.CROP_COUNT = select.CROP_COUNT + 1;
                    //     select.CROP_AREA = select.CROP_AREA + e.AREA;
                    //     select.CROP_GEOJSON.push(feature);
                    //   }
                    //   // Same
                    //   else if (index >= 0 && index_none == -1) {
                    //   }
                    //   // Differ
                    //   else if (index == -1 && index_none >= 0) {
                    //     select_none.CROP_FMAPID.splice(index_none, 1);
                    //     select_none.CROP_COUNT = select_none.CROP_COUNT - 1;
                    //     select_none.CROP_AREA = select_none.CROP_AREA - e.AREA;
                    //     select_none.CROP_GEOJSON.splice(index_none, 1);

                    //     select.CROP_FMAPID.push(e.Id);
                    //     select.CROP_COUNT = select.CROP_COUNT + 1;
                    //     select.CROP_AREA = select.CROP_AREA + e.AREA;
                    //     select.CROP_GEOJSON.push(feature);
                    //   } else {
                    //     console.log("필지 중복선택 오류");
                    //   }
                    // }
                  }
                }
              });

              setCropColorMap(matchingArray);
              // console.log("!!matchingArray", matchingArray);
              // matchingArray : CROP_AREA, CROP_COLOR, CROP_COUNT 등 나옴

              let dummyArray = [];
              cropColorMap &&
                cropColorMap.map((e) => {
                  if (e.CROP_FMAPID.length != 0) {
                    dummyArray.push([...e.CROP_FMAPID]);
                    dummyArray.push(e.CROP_COLOR);
                  }
                });

              if (
                itrstGeojson.features[0].properties.shp_id !== null &&
                itrstGeojson.features[0].properties.shp_id !== undefined
              ) {
                if (dummyArray.length != 0) {
                  highlightShpFillcrop([
                    'match',
                    ['get', 'shp_id'],
                    ...dummyArray,
                    'rgba(0, 0, 0, 0.0)',
                  ]);
                } else {
                  highlightShpFillcrop('rgba(255, 255, 255, 0.0)');
                }
              } else {
                if (
                  itrstGeojson.features[0].properties.FMAP_INNB !== undefined
                ) {
                  if (dummyArray.length != 0) {
                    highlightFarmMapFillcrop([
                      'match',
                      ['get', 'FMAP_INNB'],
                      ...dummyArray,
                      'rgba(255, 255, 255, 0.0)',
                    ]);
                  } else {
                    highlightFarmMapFillcrop('rgba(255, 255, 255, 0.0)');
                  }
                }
                // else {
                //   if (dummyArray.length != 0) {
                //     highlightShpFillcrop([
                //       "match",
                //       ["get", "Id"],
                //       ...dummyArray,
                //       "rgba(0, 0, 0, 0.0)",
                //     ]);
                //   } else {
                //     highlightShpFillcrop("rgba(255, 255, 255, 0.0)");
                //   }
                // }
              }
            })
            .catch((error) => {
              console.log(error);
            });
          setSelectedMode(2);
        }
      }
    }
  }, [polygonCoord]);

  // 주제도_육안판독 등록 시 필지 선택 - shp
  async function highlightShpFillcrop(colormap) {
    if (mapRef.current) {
      const map = mapRef.current.getMap();

      map.setPaintProperty(
        props.selectItrst.itrst_zone_nm,
        'fill-color',
        colormap
      );
      map.moveLayer(props.selectItrst.itrst_zone_nm);
      setTempFlag(!tempFlag);
    }
  }

  // 농작업 요청 시 필지 선택 - 팜맵
  function highlightFarmMapFill(sID, color) {
    if (sID == '') {
      sID = '00000000';
    }

    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.setPaintProperty(FM_LAYER_ID, 'fill-color', [
        'match',
        ['get', 'FMAP_INNB'],
        sID,
        color,
        'rgba(255, 255, 255, 0.0)',
      ]);
      map.setPaintProperty(
        FM_LAYER_ID,
        'fill-outline-color',
        'rgba(255, 255, 0, 0.0)'
      );
    }
  }

  // 주제도_육안판독 등록 시 필지 선택 - 팜맵
  function highlightFarmMapFillcrop(colormap) {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.setPaintProperty(FM_LAYER_ID, 'fill-color', colormap);
      setTempFlag(!tempFlag);
    }
  }

  const cropColorDelMapHandler = (i) => {
    let cropColorMapArray = cropColorMap;
    cropColorMapArray.splice(i, 1);
    setCropColorMap(cropColorMapArray);

    let dummyArray = [];
    cropColorMapArray &&
      cropColorMapArray.map((e) => {
        if (e.CROP_FMAPID.length != 0) {
          dummyArray.push([...e.CROP_FMAPID]);
          dummyArray.push(e.CROP_COLOR);
        }
      });

    if (dummyArray.length !== 0) {
      if (
        JSON.parse(props.selectItrst.geojson).features[0].properties.shp_id !==
          null &&
        JSON.parse(props.selectItrst.geojson).features[0].properties.shp_id !==
          undefined
      ) {
        highlightShpFillcrop([
          'match',
          ['get', 'shp_id'],
          ...dummyArray,
          'rgba(255, 255, 255, 0.0)',
        ]);
      } else {
        if (selectedFarm.FMAP_INNB) {
          highlightFarmMapFillcrop([
            'match',
            ['get', 'FMAP_INNB'],
            ...dummyArray,
            'rgba(255, 255, 255, 0.0)',
          ]);
        }
        // else {
        //   highlightShpFillcrop([
        //     "match",
        //     ["get", "Id"],
        //     ...dummyArray,
        //     "rgba(255, 255, 255, 0.0)",
        //   ]);
        // }
      }
    } else {
      if (
        JSON.parse(props.selectItrst.geojson).features[0].properties.shp_id !==
          null &&
        JSON.parse(props.selectItrst.geojson).features[0].properties.shp_id !==
          undefined
      ) {
        highlightShpFillcrop('rgba(255, 255, 255, 0.0)');
      } else {
        highlightFarmMapFillcrop('rgba(255, 255, 255, 0.0)');
      }
    }
  };

  // 특정 ID Layer를 찾기 위한 함수
  function findExistLayer(targetId) {
    if (map !== undefined) {
      if (map.getStyle() !== undefined) {
        // console.log("map", map);
        // console.log("map.getStyle()", map.getStyle());
        const mapStyle = map.getStyle();
        const mapLayers = mapStyle.layers;

        const layer = mapLayers.find((layer) => layer.id === targetId);

        if (layer) {
          map.removeLayer(layer.id);
          map.removeSource(layer.source);
        }
      }
    }
  }

  const removeAllLayer = async () => {
    if (map !== undefined && map !== null) {
      // console.log("sidebar map", map);
      // console.log("sidebar map", map.getStyle());
      // setTimeout(() => {
      // console.log("실행");
      // if (map.getStyle().version >= 8) {
      const mapLayers = await map.getStyle().layers;
      mapLayers.forEach((layer) => {
        let changeName = layer.id.replace('Layer', 'Source');
        if (layer.id.startsWith('missionLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('autoLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('manualLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('csvLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
      });
      map.setLayoutProperty(ITRST_LAYER_ID_DUMMY, 'visibility', 'visible');
      // }
      // }, 150);
    }
  };

  useEffect(() => {
    if (props.selectItrst !== undefined) {
      if (props.selectItrst.itrst_zone_id !== undefined) {
        props.searchMyZoneList.forEach((layer) => {
          findExistLayer(layer.itrst_zone_nm);
        });

        if (
          JSON.parse(props.selectItrst.geojson).features[0].properties
            .shp_id !== null &&
          JSON.parse(props.selectItrst.geojson).features[0].properties
            .shp_id !== undefined
        ) {
          if (map) {
            let multiPolygon = JSON.parse(props.selectItrst.geojson).features;

            if (map !== undefined) {
              map.addSource(props.selectItrst.itrst_zone_nm, {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: multiPolygon,
                },
              });
              map.addLayer({
                id: props.selectItrst.itrst_zone_nm,
                source: props.selectItrst.itrst_zone_nm,
                type: 'fill',
                layout: { visibility: 'visible' },
                paint: {
                  'fill-color': 'rgba(255, 255, 255, 0.0)',
                  'fill-outline-color': 'rgba(255, 255, 0, 0.0)',
                },
              });
              map.moveLayer(props.selectItrst.itrst_zone_nm);
            }
          }
        }
        removeAllLayer();
        setClickValue();
        displayTooltip();
        setOnSetItrst(false);
      }
    }
  }, [props.selectItrst]);

  // 모바일
  // const [showMobModifyLegend, setShowMobModifyLegend] = useState();
  // const handlShowModifyLegend = (event) => {
  //   setShowMobModifyLegend(event)
  // }
  // const [showModifyLegend, setShowModifyLegend] = useState(false);

  // // showModifyLegend를 변경할 수 있는 함수
  // const handlShowModifyLegend = () => {
  //   setShowModifyLegend(!showModifyLegend);
  // };

  return (
    <React.Fragment>
      {props.guideLine && (
        <div className='guideLine'>
          <div className='main-guide-text'>
            플랫폼 이용을 위해 관심구역을 생성하세요.
          </div>
          <div className='sub-guide-text'>
            ※ 관심구역을 생성하시면 해당 구역에 대한 데이터를 직접 등록하고
            관리할 수 있습니다.
          </div>
          <div className='sub-guide-text'>
            ※ 관심구역을 선택하시면 해당 구역으로 이동하며 메뉴가 활성화됩니다.
          </div>
          <button
            className='itrstReg gc-btn'
            onClick={() => {
              props.setShowGen(true);
            }}
          >
            관심구역 생성
          </button>
        </div>
      )}
      {props.sideBar ? (
        <>
          {props.isMobile !== true && (
            <FixedSidebar
              logout={props.logout}
              showGen={props.showGen}
              setShowGen={props.setShowGen}
              handleShowGen={props.handleShowGen}
              isTabletHeight={props.isTabletHeight}
              showProfile={props.showProfile}
              setShowProfile={props.setShowProfile}
              isMobile={props.isMobile}
              mainHidden={props.mainHidden}
              setMainHidden={props.setMainHidden}
              showThemeMapAdd={props.showThemeMapAdd}
              setShowThemeMapAdd={props.setShowThemeMapAdd}
              setShowAdmin={props.setShowAdmin}
              farmworkSelect={props.farmworkSelect}
            />
          )}
          <SideBar
            setHeaderHidden={props.setHeaderHidden}
            visibleDrnLog={visibleDrnLog}
            setVisibleDrnLog={setVisibleDrnLog}
            drnLogLayerFea={drnLogLayerFea}
            clickDrnLogLayerFeaHandler={clickDrnLogLayerFeaHandler}
            instId={props.instId}
            setSideBar={props.setSideBar}
            showImage={props.showImage}
            setShowImage={props.setShowImage}
            showTheme={props.showTheme}
            setShowTheme={props.setShowTheme}
            showFarmwork={props.showFarmwork}
            setShowFarmwork={props.setShowFarmwork}
            showDrnLog={props.showDrnLog}
            setShowDrnLog={props.setShowDrnLog}
            handleShowDrnLog={props.handleShowDrnLog}
            showMng={props.showMng}
            requestFarmwork={requestFarmwork}
            setRequestFarmwork={setRequestFarmwork}
            farmworkCoord={farmworkCoord}
            farmworkSelectHandler={props.farmworkSelectHandler}
            selectedFarm={selectedFarm}
            setSelectedFarm={setSelectedFarm}
            drnLogFileList={drnLogFileList}
            drnLogFileListHandler={drnLogFileListHandler}
            themeMapList={themeMapList}
            themeMapListHandler={themeMapListHandler}
            droneImageList={droneImageList}
            droneImageListHandler={droneImageListHandler}
            farmworkList={farmworkList}
            farmworkListHandler={farmworkListHandler}
            activeLayerList={activeLayerList}
            activeLayerListHandler={activeLayerListHandler}
            curActiveLayer={curActiveLayer}
            curActiveLayerHandler={curActiveLayerHandler}
            selectItrst={props.selectItrst}
            onFarmmapHandler={onFarmmapHandler}
            handleActiveImg={props.handleActiveImg}
            handleActiveTheme={props.handleActiveTheme}
            handleActiveDrnLog={props.handleActiveDrnLog}
            moveCurActiveLayer={moveCurActiveLayer}
            setShowThemeReg={props.setShowThemeReg}
            setShowImageReg={props.setShowImageReg}
            showDrnLogReg={props.showDrnLogReg}
            setShowDrnLogReg={props.setShowDrnLogReg}
            handleShowDrnLogReg={props.handleShowDrnLogReg}
            // activeFsMenu={activeFsMenu}
            // activeFmMenu={activeFmMenu}
            // activeFsMenuHandler={activeFsMenuHandler}
            // activeFmMenuHandler={activeFmMenuHandler}
            vegIndexImageHandler={vegIndexImageHandler}
            delModeHandler={delModeHandler}
            indexFromHandler={indexFromHandler}
            indexToHandler={indexToHandler}
            listReturnFlag={listReturnFlag}
            listReturnFlagHandler={listReturnFlagHandler}
            loading={loading}
            setLoading={setLoading}
            VISIBLE_ARRAY={VISIBLE_ARRAY}
            visibleArrayHandler={visibleArrayHandler} // ??누구??
            TYPE_ARRAY={TYPE_ARRAY}
            typeArrayHandler={typeArrayHandler} // ??누구??
            layerOn={layerOn}
            layerOff={layerOff}
            layerDel={layerDel}
            handleCoord={props.handleCoord}
            mapRef={mapRef}
            myZoneList={props.myZoneList}
            isMine={props.isMine}
            setIsMine={props.setIsMine}
            addCheckDrn={addCheckDrn}
            addCheckTheme={addCheckTheme}
            addCheckDrnLog={addCheckDrnLog}
            setAddCheckDrn={setAddCheckDrn}
            setAddCheckTheme={setAddCheckTheme}
            setAddCheckDrnLog={setAddCheckDrnLog}
            isMobile={props.isMobile}
            isTablet={props.isTablet}
            mobileActiveMenu={props.mobileActiveMenu}
            setMobileActiveMenu={props.setMobileActiveMenu}
            mobileLayerMenu={mobileLayerMenu}
            setMobileLayerMenu={setMobileLayerMenu}
            setClickValue={setClickValue}
            activeHeaderMenu={props.activeHeaderMenu}
            drone={drone}
            setDrone={setDrone}
            droneHandler={droneHandler}
            chartDIV={chartDIV}
            // ref={ref}
            clickNum={clickNum}
            setClickNum={setClickNum}
            // clickNumHandler={clickNumHandler}
            droneLog={droneLog}
            setDroneLog={setDroneLog}
            states={states}
            setStates={setStates}
            csvLog={csvLog}
            setCsvLog={setCsvLog}
            csvStates={csvStates}
            setCsvStates={setCsvStates}
            actChart={actChart}
            setActChart={setActChart}
            showChart={showChart}
            setShowChart={setShowChart}
            noChart={noChart}
            setNoChart={setNoChart}
            readyChart={readyChart}
            setReadyChart={setReadyChart}
            readyCSVChart={readyCSVChart}
            setReadyCSVChart={setReadyCSVChart}
            waypoint={waypoint}
            setWaypoint={setWaypoint}
            interactCoord={props.interactCoord}
            clickFarmRequest={clickFarmRequest}
            setClickFarmRequst={setClickFarmRequst}
            // 농작업 요청(경작자)
            showFarmworkSelect={props.showFarmworkSelect}
            setShowFarmworkSelect={props.setShowFarmworkSelect}
            farmSelect={farmSelect}
            setFarmSelect={setFarmSelect}
            farmSelectHandler={farmSelectHandler}
            registFarmwork={registFarmwork}
            setRegistFarmwork={setRegistFarmwork}
            checkInst={checkInst}
            setCheckInst={setCheckInst}
            farmworkSelectList={farmworkSelectList}
            setFarmworkSelectList={setFarmworkSelectList}
            farmworkSelectListHandler={farmworkSelectListHandler}
            // 농작업 지원(기관)
            showInstFarmwork={props.showInstFarmwork}
            setShowInstFarmwork={props.setShowInstFarmwork}
            farmworkListforInst={farmworkListforInst}
            setFarmworkListforInst={setFarmworkListforInst}
            farmworkListforInstHandler={farmworkListforInstHandler}
            showRegistInstModal={showRegistInstModal}
            setShowRegistInstModal={setShowRegistInstModal}
            // 검색 flag
            searchFlag={searchFlag}
            setSearchFlag={setSearchFlag}
            // 관측영상 ndvi
            selVIindex={selVIindex}
            setSelVIindex={setSelVIindex}
            modalDel={props.modalDel}
            setModalDel={props.setModalDel}
            handleModalDelState={props.handleModalDelState}
            drnLogFlag={drnLogFlag}
            setDrnLogFlag={setDrnLogFlag}
            statesHandler={statesHandler}
            csvStateHandler={csvStateHandler}
            farmWorkLogFlag={farmWorkLogFlag}
            setFarmWorkLogFlag={setFarmWorkLogFlag}
            drnLogHandler={drnLogHandler}
            csvLogHandler={csvLogHandler}
            mainHidden={props.mainHidden}
            setMainHidden={props.setMainHidden}
            subHidden={props.subHidden}
            setSubHidden={props.setSubHidden}
            // isTabletHeight={props.isTabletHeight}
            handleShowProfile={props.handleShowProfile}
            itrstZoneToggle={itrstZoneToggle}
            setItrstZoneToggle={setItrstZoneToggle}
            sidebarHiddenFlag={props.sidebarHiddenFlag}
            setSidebarHiddenFlag={props.setSidebarHiddenFlag}
          />
        </>
      ) : null}
      {props.showThemeMapAdd ? (
        <FixedSidebar
          logout={props.logout}
          showGen={props.showGen}
          setShowGen={props.setShowGen}
          handleShowGen={props.handleShowGen}
          isTabletHeight={props.isTabletHeight}
          showProfile={props.showProfile}
          setShowProfile={props.setShowProfile}
          isMobile={props.isMobile}
          mainHidden={props.mainHidden}
          setMainHidden={props.setMainHidden}
          showThemeMapAdd={props.showThemeMapAdd}
          setShowThemeMapAdd={props.setShowThemeMapAdd}
        />
      ) : null}
      <Map
        className='Mapbox-Map'
        mapboxAccessToken={MAP_TOKEN}
        ref={mapRef}
        initialViewState={{
          latitude: 36.22516386,
          longitude: 127.9544141,
          zoom: 1,
          minZoom: 7,
          maxZoom: 24,
          maxBounds: [
            [122.0, 33.0], // Southwest coordinates
            [132.0, 40.0], // Northeast coordinates
          ],
        }}
        mapStyle='mapbox://styles/mapbox/light-v9?optimize=true'
        onClick={(e) => {
          onclickmap(e);
          if (props.showSubmenu) {
            props.handleShowSubmenu(false);
          }
          if (onSetItrst) {
            setOnSetItrst(false);
          }
        }}
        doubleClickZoom={false}
        // mapStyle="mapbox://styles/mapbox/satellite-v9"
        // {...viewport}
        // onViewportChange={(viewport) => setViewport(viewport)}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {selectedMode == 3 ? (
          <DrawControl
            position='bottom-right'
            displayControlsDefault={false}
            controls={
              {
                // polygon: true,
                // trash: true
              }
            }
            defaultMode='draw_polygon'
            onCreate={(data) => {
              setPolygonCoord(data.features);
            }}
          />
        ) : null}

        <div className='mapcontrol-container contents-col'>
          <div
            className={`itrst-setcolor btn ${onSetItrst ? 'active' : ''}`}
            onClick={() => {
              setOnSetItrst(!onSetItrst);
            }}
            style={{
              backgroundColor: itrstColor,
            }}
          >
            관심구역
          </div>

          <div
            className={`shp-onoff btn ${onFarmMap ? 'active' : ''}`}
            onClick={() => {
              setOnFarmMap(!onFarmMap);
            }}
          >
            팜맵
          </div>

          <div className='map-select contents-col flex-center'>
            <div
              className={`aerial-image btn ${onmapSat ? 'active' : ''}`}
              onClick={() => {
                onmapHandler(true);
              }}
            >
              <Icon icon='fa:plane' style={{ marginTop: '5px' }} />
              <span className='text'>항공</span>
            </div>
            <div
              className={`normal-image btn ${onmapSat ? '' : 'active'}`}
              onClick={() => {
                onmapHandler(false);
              }}
            >
              <Icon
                className='icon'
                icon='ri:map-fill'
                style={{ marginTop: '5px' }}
              />
              <span className='text'>일반</span>
            </div>
          </div>

          {props.isMobile == false ? (
            <NavigationControl
              position='bottom-right'
              className='NavigationControl'
              style={{
                display: 'flex',
                // height: '40px',
                padding: '5px',
                borderRadius: '8px',
                boxShadow: '0px 15px 20px rgba(0, 0, 0, 0.3)',
                height: '150px',
                width: '50px',
                flexDirection: 'column',
                // right: '50px !important',
              }}
            >
              <button className='custom-button'></button>
            </NavigationControl>
          ) : null}
        </div>

        {onSetItrst ? (
          props.isMobile ? (
            <div className='itrst-sketchPicker-modal contents-col'>
              <div className='text'>관심구역 색상을 선택하세요.</div>
              <div className='contents-row flex-center'>
                <div
                  className='colors red'
                  onClick={() => {
                    setItrstColor('#FF0000');
                    setOnSetItrst(false);
                  }}
                ></div>
                <div
                  className='colors green'
                  onClick={() => {
                    setItrstColor('#00FF00');
                    setOnSetItrst(false);
                  }}
                ></div>
                <div
                  className='colors blue'
                  onClick={() => {
                    setItrstColor('#0000FF');
                    setOnSetItrst(false);
                  }}
                ></div>
                <div
                  className='colors yellow'
                  onClick={() => {
                    setItrstColor('#FFFF00');
                    setOnSetItrst(false);
                  }}
                ></div>
                <div
                  className='colors white'
                  onClick={() => {
                    setItrstColor('#FFFFFF');
                    setOnSetItrst(false);
                  }}
                ></div>
                <div
                  className='colors black'
                  onClick={() => {
                    setItrstColor('#000000');
                    setOnSetItrst(false);
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <>
              {/* <GithubPicker
                className={`itrst-sketchPicker ${
                  props.isMobile ? "mobile " : ""
                }`}
                color={itrstColor}
                onChange={handleColorChange}
                disableAlpha={true}
                colors={[
                  "#FF0000",
                  "#DB3E00",
                  "#FCCB00",
                  "#008B02",
                  "#006B76",
                  "#1273DE",
                  "#004DCF",
                  "#EB9694",
                  "#FAD0C3",
                  "#FEF3BD",
                  "#C1E1C5",
                  "#BEDADC",
                  "#C4DEF6",
                  "#BED3F3",
                  "#FFFFFF",
                  "#DDDDDD",
                  "#BBBBBB",
                  "#999999",
                  "#666666",
                  "#333333",
                  "#000000",
                ]}
              /> */}
              <div className={'sketchpickerDIV'}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <i
                    className='x-button'
                    onClick={() => {
                      setOnSetItrst(false);
                    }}
                  />
                </div>
                <div className='itrst-modal contents-row'>
                  <div className='title'>
                    관심구역 <br />선 두께
                  </div>
                  <ReactSlider
                    className='slider'
                    thumbClassName='example-thumb'
                    trackClassName='example-track'
                    // defaultValue={3}
                    min={0}
                    max={30}
                    renderThumb={(props, state) => (
                      <div {...props}>{state.valueNow}</div>
                    )}
                    value={itrstLine}
                    // ariaLabel={['Lower thumb', 'Upper thumb']}
                    // pearling
                    // step={1}
                    // minDistance={1}
                    // onChange={handleChangeLine}
                    onAfterChange={handleChangeLine}
                    // style={{ margin: '0' }}
                  ></ReactSlider>
                  {/* <input
                    style={{ height: '25px', width: '120px', fontSize: '16px' }}
                    onChange={handleChangeLine}
                    value={itrstLine}
                  ></input> */}
                </div>
                <div className='itrst-modal'>
                  <div className='title'>관심구역 보이기</div>
                  <Checkbox
                    toggle={true}
                    onChange={itrstToggle}
                    checked={itrstZoneToggle}
                  />
                </div>
                <CompactPicker
                  className={`itrst-sketchPicker ${
                    props.isMobile ? 'mobile ' : ''
                  }`}
                  color={itrstColor}
                  onChange={handleChangeSketch}
                />
              </div>
            </>
          )
        ) : null}

        {/* <Source {...MAP_SRC}>
          <Layer {...MAP_LAYER_STYLE} />
        </Source> */}

        {/* <Source {...FM_SRC}>
          <Layer {...FM_LAYER_STYLE}/>
        </Source> */}

        {/* {displayBaseMap()} */}
        <Source key={MAP_LAYER_ID} {...MAP_SRC}>
          <Layer {...MAP_LAYER_STYLE} />
        </Source>

        {/* {displayFarmMap()} */}
        <Source key={FM_LAYER_ID} {...FM_SRC}>
          <Layer {...FM_LAYER_STYLE} />
        </Source>

        {displayItrst()}

        {displayLayer()}

        {displayVILayer()}

        {displayTooltip()}
      </Map>

      <FarmStatus
        visibleDrnLog={visibleDrnLog}
        setVisibleDrnLog={setVisibleDrnLog}
        selectedFarm={selectedFarm}
        selectItrst={props.selectItrst}
        // activeFsMenu={activeFsMenu}
        // activeFsMenuHandler={activeFsMenuHandler}
        activeLayerList={activeLayerList}
        activeLayerListHandler={activeLayerListHandler}
        curActiveLayer={curActiveLayer}
        curActiveLayerHandler={curActiveLayerHandler}
        themeMapListHandler={themeMapListHandler}
        droneImageListHandler={droneImageListHandler}
        farmworkListHandler={farmworkListHandler}
        drnLogFileListHandler={drnLogFileListHandler}
        vegIndexImage={vegIndexImage}
        setVegIndexImage={setVegIndexImage}
        vegIndexImageHandler={vegIndexImageHandler}
        onmapHandler={onmapHandler}
        mapRef={mapRef}
        onFarmMap={onFarmMap}
        activeImg={props.activeImg}
        handleActiveImg={props.handleActiveImg}
        activeTheme={props.activeTheme}
        handleActiveTheme={props.handleActiveTheme}
        showImageReg={props.showImageReg}
        setShowImageReg={props.setShowImageReg}
        handleShowDrnLog={props.handleShowDrnLog}
        showDrnLog={props.showDrnLog}
        setShowDrnLog={props.setShowDrnLog}
        activeDrnLog={props.activeDrnLog}
        handleActiveDrnLog={props.handleActiveDrnLog}
        handleShowImageReg={props.handleShowImageReg}
        handleShowThemeReg={props.handleShowThemeReg}
        handleShowDrnLogReg={props.handleShowDrnLogReg}
        listReturnFlagHandler={listReturnFlagHandler}
        loading={loading}
        setLoading={setLoading}
        layerDel={layerDel}
        delModeHandler={delModeHandler}
        setRefreshFlag={setRefreshFlag}
        setDummybbox={setDummybbox}
        isMine={props.isMine}
        setIsMine={props.setIsMine}
        isMobile={props.isMobile}
        mobileAbstractMenu={mobileAbstractMenu}
        setMobileAbstractMenu={setMobileAbstractMenu}
        mobileLegendMenu={mobileLegendMenu}
        setMobileLegendMenu={setMobileLegendMenu}
        setClickValue={setClickValue}
        drone={drone}
        setDrone={setDrone}
        droneHandler={droneHandler}
        chartCurrent={chartCurrent}
        setChartCurrent={setChartCurrent}
        ref={chartDIV}
        clickNum={clickNum}
        setClickNum={setClickNum}
        clickNumHandler={clickNumHandler}
        droneLog={droneLog}
        setDroneLog={setDroneLog}
        states={states}
        setStates={setStates}
        csvLog={csvLog}
        setCsvLog={setCsvLog}
        csvStates={csvStates}
        setCsvStates={setCsvStates}
        actChart={actChart}
        setActChart={setActChart}
        showChart={showChart}
        setShowChart={setShowChart}
        noChart={noChart}
        setNoChart={setNoChart}
        readyChart={readyChart}
        setReadyChart={setReadyChart}
        readyCSVChart={readyCSVChart}
        setReadyCSVChart={setReadyCSVChart}
        waypoint={waypoint}
        setWaypoint={setWaypoint}
        // 농작업 요청(경작자)
        showFarmworkSelect={props.showFarmworkSelect}
        setShowFarmworkSelect={props.setShowFarmworkSelect}
        // 검색 flag
        searchFlag={searchFlag}
        setSearchFlag={setSearchFlag}
        // 관측영상 ndvi
        selVIindex={selVIindex}
        setSelVIindex={setSelVIindex}
        statesHandler={statesHandler}
        csvStateHandler={csvStateHandler}
        farmWorkLogFlag={farmWorkLogFlag}
        setFarmWorkLogFlag={setFarmWorkLogFlag}
        drnLogHandler={drnLogHandler}
        csvLogHandler={csvLogHandler}
        // farmworkList={props.farmworkList}
        mainHidden={props.mainHidden}
        setMainHidden={props.setMainHidden}
      />

      {/* {
        props.showImage?
        <DroneImage 
          selectedFarm={selectedFarm}
          selectItrst={props.selectItrst}
          // activeFsMenu={activeFsMenu}
          // activeFsMenuHandler={activeFsMenuHandler}
          activeLayerList={activeLayerList}
          activeLayerListHandler={activeLayerListHandler}
          curActiveLayer={curActiveLayer}
          curActiveLayerHandler={curActiveLayerHandler}
          themeMapListHandler={themeMapListHandler}
          droneImageListHandler={droneImageListHandler}
          farmworkListHandler={farmworkListHandler}
          
          vegIndexImage={vegIndexImage}
          setVegIndexImage={setVegIndexImage}
          vegIndexImageHandler={vegIndexImageHandler}
          onmapHandler={onmapHandler}
          mapRef={mapRef}
          onFarmMap={onFarmMap}

          activeImg={props.activeImg}
          handleActiveImg={props.handleActiveImg}
          activeTheme={props.activeTheme}
          handleActiveTheme={props.handleActiveTheme}
          
          showImageReg={props.showImageReg}
          setShowImageReg={props.setShowImageReg}
          handleShowImageReg={props.handleShowImageReg}
          handleShowThemeReg={props.handleShowThemeReg}
          listReturnFlagHandler={listReturnFlagHandler}

          loading={loading}
          setLoading={setLoading}
          layerDel={layerDel}
          delModeHandler={delModeHandler}
          setRefreshFlag={setRefreshFlag}
          setDummybbox={setDummybbox}
          isMine={props.isMine}
          setIsMine={props.setIsMine}
        />
        : null
      }
      {
        props.showTheme?
        <ThemeMap
          selectedFarm={selectedFarm}
          selectItrst={props.selectItrst}
          // activeFmMenu={activeFmMenu}
          // activeFmMenuHandler={activeFmMenuHandler}
          activeLayerList={activeLayerList}
          activeLayerListHandler={activeLayerListHandler}
          curActiveLayer={curActiveLayer}
          curActiveLayerHandler={curActiveLayerHandler}
          themeMapListHandler={themeMapListHandler}
          droneImageListHandler={droneImageListHandler}
          farmworkListHandler={farmworkListHandler}

          vegIndexImage={vegIndexImage}
          setVegIndexImage={setVegIndexImage}
          vegIndexImageHandler={vegIndexImageHandler}
          onmapHandler={onmapHandler}
          mapRef={mapRef}
          onFarmMap={onFarmMap}

          activeImg={props.activeImg}
          handleActiveImg={props.handleActiveImg}
          activeTheme={props.activeTheme}
          handleActiveTheme={props.handleActiveTheme}

          showImageReg={props.showImageReg}
          setShowImageReg={props.setShowImageReg}
          handleShowImageReg={props.handleShowImageReg}
          handleShowThemeReg={props.handleShowThemeReg}
          listReturnFlagHandler={listReturnFlagHandler}

          loading={loading}
          setLoading={setLoading}
          layerDel={layerDel}
          delModeHandler={delModeHandler}
          setRefreshFlag={setRefreshFlag}
          setDummybbox={setDummybbox}
          isMine={props.isMine}
          setIsMine={props.setIsMine}
        />
        : null
      }
      {
        props.showFarmwork?
        <FarmWork
          selectedFarm={selectedFarm}
          selectItrst={props.selectItrst}
          // activeFmMenu={activeFmMenu}
          // activeFmMenuHandler={activeFmMenuHandler}
          activeLayerList={activeLayerList}
          activeLayerListHandler={activeLayerListHandler}
          curActiveLayer={curActiveLayer}
          curActiveLayerHandler={curActiveLayerHandler}
          themeMapListHandler={themeMapListHandler}
          droneImageListHandler={droneImageListHandler}
          farmworkListHandler={farmworkListHandler}

          vegIndexImage={vegIndexImage}
          setVegIndexImage={setVegIndexImage}
          vegIndexImageHandler={vegIndexImageHandler}
          onmapHandler={onmapHandler}
          mapRef={mapRef}
          onFarmMap={onFarmMap}

          activeImg={props.activeImg}
          handleActiveImg={props.handleActiveImg}
          activeTheme={props.activeTheme}
          handleActiveTheme={props.handleActiveTheme}

          showImageReg={props.showImageReg}
          setShowImageReg={props.setShowImageReg}
          handleShowImageReg={props.handleShowImageReg}
          handleShowThemeReg={props.handleShowThemeReg}
          listReturnFlagHandler={listReturnFlagHandler}

          loading={loading}
          setLoading={setLoading}
          layerDel={layerDel}
          delModeHandler={delModeHandler}
          setRefreshFlag={setRefreshFlag}
          setDummybbox={setDummybbox}
          isMine={props.isMine}
          setIsMine={props.setIsMine}
        />
        : null
      } */}

      {props.showDrnLogReg ? (
        <DrnLogUpload
          activeDrnLog={props.activeDrnLog}
          handleActiveDrnLog={props.handleActiveDrnLog}
          showDrnLog={props.showDrnLog}
          handleShowDrnLog={props.handleShowDrnLog}
          setShowDrnLog={props.setShowDrnLog}
          showImageReg={props.showImageReg}
          setShowImageReg={props.setShowImageReg}
          // activeFsMenuHandler={activeFsMenuHandler}
          handleShowImage={props.handleShowImage}
          handleActiveImg={props.handleActiveImg}
          activeImg={props.activeImg}
          activeLayerList={activeLayerList}
          activeLayerListHandler={activeLayerListHandler}
          curActiveLayer={curActiveLayer}
          curActiveLayerHandler={curActiveLayerHandler}
          loading={loading}
          setLoading={setLoading}
          selectItrst={props.selectItrst}
          setAddCheckDrn={setAddCheckDrn}
          isMobile={props.isMobile}
          layerDel={layerDel}
          delModeHandler={delModeHandler}
          setClickValue={setClickValue}
          modalDel={props.modalDel}
          setModalDel={props.setModalDel}
          handleModalDelState={props.handleModalDelState}
          states={states}
          setStates={setStates}
          csvLog={csvLog}
          setCsvLog={setCsvLog}
          csvStates={csvStates}
          setCsvStates={setCsvStates}
          statesHandler={statesHandler}
          csvStateHandler={csvStateHandler}
          drnLogFlag={drnLogFlag}
          setDrnLogFlag={setDrnLogFlag}
          showDrnLogReg={props.showDrnLogReg}
          setShowDrnLogReg={props.setShowDrnLogReg}
          handleShowDrnLogReg={props.handleShowDrnLogReg}
          addCheckDrnLog={addCheckDrnLog}
          setAddCheckDrnLog={setAddCheckDrnLog}
        />
      ) : null}

      {props.showImageReg ? (
        <DroneImageReg
          showImageReg={props.showImageReg}
          setShowImageReg={props.setShowImageReg}
          // activeFsMenuHandler={activeFsMenuHandler}
          handleShowImage={props.handleShowImage}
          handleActiveImg={props.handleActiveImg}
          activeImg={props.activeImg}
          activeLayerList={activeLayerList}
          activeLayerListHandler={activeLayerListHandler}
          curActiveLayer={curActiveLayer}
          curActiveLayerHandler={curActiveLayerHandler}
          loading={loading}
          setLoading={setLoading}
          selectItrst={props.selectItrst}
          setAddCheckDrn={setAddCheckDrn}
          isTablet={props.isTablet}
          isMobile={props.isMobile}
          layerDel={layerDel}
          delModeHandler={delModeHandler}
          setClickValue={setClickValue}
        />
      ) : null}
      {props.showThemeReg ? (
        <ThemeMapReg
          showThemeReg={props.showThemeReg}
          setShowThemeReg={props.setShowThemeReg}
          showThemeMapAdd={props.showThemeMapAdd}
          setShowThemeMapAdd={props.setShowThemeMapAdd}
          setSideBar={props.setSideBar}
          setShowImage={props.setShowImage}
          setShowTheme={props.setShowTheme}
          // activeFsMenuHandler={activeFsMenuHandler}
          handleShowImage={props.handleShowImage}
          selectItrst={props.selectItrst}
          handleActiveTheme={props.handleActiveTheme}
          activeTheme={props.activeTheme}
          activeLayerList={activeLayerList}
          activeLayerListHandler={activeLayerListHandler}
          curActiveLayer={curActiveLayer}
          curActiveLayerHandler={curActiveLayerHandler}
          loading={loading}
          setLoading={setLoading}
          onFarmmapHandler={onFarmmapHandler}
          setAddCheckTheme={setAddCheckTheme}
          themeNameHandler={themeNameHandler}
          isMobile={props.isMobile}
          layerDel={layerDel}
          delModeHandler={delModeHandler}
          setClickValue={setClickValue}
        />
      ) : null}
      {props.showGen ? (
        <ItrstGen
          showMng={props.showMng}
          setShowMng={props.setShowMng}
          showGen={props.showGen}
          setShowGen={props.setShowGen}
        />
      ) : null}
      {props.showMng ? (
        <ItrstMng
          showMng={props.showMng}
          setShowMng={props.setShowMng}
          showGen={props.showGen}
          setShowGen={props.setShowGen}
          guideLine={props.guideLine}
          setGuideLine={props.setGuideLine}
          handleGuideLine={props.handleGuideLine}
        />
      ) : null}
      {props.showThemeMapAdd ? (
        <VisualDetect
          selectItrst={props.selectItrst}
          setSideBar={props.setSideBar}
          showThemeMapAdd={props.showThemeMapAdd}
          setShowThemeMapAdd={props.setShowThemeMapAdd}
          handleShowImage={props.handleShowImage}
          selectedFarm={selectedFarm}
          setSelectedFarm={setSelectedFarm}
          cropColorMap={cropColorMap}
          setCropColorMap={setCropColorMap}
          cropColorMapHandler={cropColorMapHandler}
          cropColorDelMapHandler={cropColorDelMapHandler}
          visualDetection={visualDetection}
          setVisualDetection={setVisualDetection}
          visualDetectionHandler={visualDetectionHandler}
          selectedMode={selectedMode}
          handleSelectionMode={handleSelectionMode}
          onFarmmapHandler={onFarmmapHandler}
          selCropColorHandler={selCropColorHandler}
          selCropNameHandler={selCropNameHandler}
          activeLayerList={activeLayerList}
          activeLayerListHandler={activeLayerListHandler}
          curActiveLayer={curActiveLayer}
          curActiveLayerHandler={curActiveLayerHandler}
          themeName={themeName}
          tempFlag={tempFlag}
          INIT_FM={INIT_FM}
          mapRef={mapRef}
          mainHidden={props.mainHidden}
          setMainHidden={props.setMainHidden}
        />
      ) : null}

      {props.handleShowProfile && (
        <Profile
          handleShowProfile={props.handleShowProfile}
          setShowProfile={props.setShowProfile}
          showProfile={props.showProfile}
          handleIsTerms={props.handleIsTerms}
          isTerms_state={props.isTerms_state}
          isMobile={props.isMobile}
        />
      )}

      {props.handleShowProfile && (
        <Adminmode
          showAdmin={props.showAdmin}
          setShowAdmin={props.setShowAdmin}
        />
      )}

      {loading == true ? (
        <div className='loading-container'>
          <div id='loading'></div>
        </div>
      ) : null}

      {props.isMobile ? (
        <div className='mobile-topmenu'>
          <div
            className={`mobile itrst-setcolor ${onSetItrst ? 'active' : ''}`}
            onClick={() => {
              setOnSetItrst(!onSetItrst);
            }}
            style={{
              backgroundColor: itrstColor,
            }}
          ></div>
          <div
            className={`mobile abstract ${curActiveLayer ? '' : 'hidden'}`}
            onClick={() => {
              setMobileAbstractMenu(!mobileAbstractMenu);
            }}
          ></div>
          <div
            className={`mobile legend ${
              curActiveLayer && curActiveLayer.layerType == 2 ? '' : 'hidden'
            }`}
            onClick={() => {
              setMobileLegendMenu(!mobileLegendMenu);
              // props.setShowModifyLegend(true);
            }}
          ></div>
        </div>
      ) : null}
      {props.isMobile ? (
        <div className='mobile_menu contents-row'>
          <div
            className={`item mobile_menu_drone contents-col ${
              props.mobileActiveMenu == 1 ? 'active' : ''
            }`}
            onClick={() => {
              if (props.mobileActiveMenu == 1) {
                props.setMobileActiveMenu(0);
              } else {
                props.setMobileActiveMenu(1);
                props.setActiveHeaderMenu('/image');
                props.handleShowImage(true);
                props.handleShowTheme(false);
                props.handleShowFarmwork(false);
                setMobileLayerMenu(false);
                // 레이어가 선택된게 있어야 true일텐데
              }
            }}
          >
            <Link
              to={'/home'}
              onClick={() => {
                props.setActiveHeaderMenu('/image');
              }}
              className='link-text'
            >
              {props.isMobile == 5 ? (
                <div className='image'></div>
              ) : (
                <div className='image' style={{ marginLeft: '5px' }}></div>
              )}
              {/* <div className="image"></div> */}
              <div className='text'>관측영상</div>
            </Link>
            {/* <Link to={"/image"} className="contents-col link-box">
              <div className="image"></div> */}
            {/* <i class="image fa-solid fa-panorama"></i> */}
            {/* <div className="text">관측영상</div>
            </Link> */}
          </div>
          <div
            className={`item mobile_menu_theme contents-col ${
              props.mobileActiveMenu == 2 ? 'active' : ''
            }`}
            onClick={() => {
              if (props.mobileActiveMenu == 2) {
                props.setMobileActiveMenu(0);
              } else {
                props.setMobileActiveMenu(2);
                props.setActiveHeaderMenu('/theme');
                props.handleShowTheme(true);
                props.handleShowImage(false);
                props.handleShowFarmwork(false);
                setMobileLayerMenu(false);
              }
            }}
          >
            <Link
              to={'/home'}
              onClick={() => {
                props.setActiveHeaderMenu('/theme');
              }}
              className='link-text'
            >
              <div className='image'></div>
              <div className='text'>주제도</div>
            </Link>
            {/* <Link to={"/theme"} className="contents-col link-box">
              <div className="image"></div> */}
            {/* <i class="image fa-solid fa-map-location-dot"></i> */}
            {/* <div className="text">주제도</div>
            </Link> */}
          </div>
          <div
            className={`item mobile_menu_frmwork contents-col ${
              props.mobileActiveMenu == 3 ? 'active' : ''
            }`}
            onClick={() => {
              if (props.mobileActiveMenu == 3) {
                props.setMobileActiveMenu(0);
              } else {
                props.setMobileActiveMenu(3);
                props.setActiveHeaderMenu('/farmwork');
                props.handleShowFarmwork(true);
                props.handleShowImage(false);
                props.handleShowTheme(false);
                setMobileLayerMenu(false);
              }
            }}
          >
            <Link
              to={'/home'}
              onClick={() => {
                props.setActiveHeaderMenu('/farmwork');
              }}
              className='link-text'
            >
              <div className='image'></div>
              <div className='text'>농작업</div>
            </Link>
            {/* <Link to="/farmwork" className="contents-col link-box">
              <div className="image"></div> */}
            {/* <i class="image fa-solid fa-wheat-awn"></i> */}
            {/* <div className="text">농작업</div>
            </Link> */}
          </div>

          <div
            className={`item mobile_menu_frmwork contents-col ${
              props.mobileActiveMenu == 5 ? 'active' : ''
            }`}
            // style={{ display: "none" }}
            onClick={() => {
              if (props.mobileActiveMenu == 5) {
                props.setMobileActiveMenu(0);
              } else {
                props.setMobileActiveMenu(5);
                props.setActiveHeaderMenu('/drnLog');
                props.handleShowFarmwork(false);
                props.handleShowImage(false);
                props.handleShowTheme(false);
                props.handleShowDrnLog(true);
                setMobileLayerMenu(false);
              }
            }}
          >
            <Link
              to={'/home'}
              onClick={() => {
                props.setActiveHeaderMenu('/drnLog');
              }}
              className='link-text'
            >
              {props.isMobile == 5 ? (
                <div className='image'></div>
              ) : (
                <div className='image' style={{ marginLeft: '5px' }}></div>
              )}
              {/* <div className="image"></div> */}
              <div className='text'>
                {props.isMobile == 5 ? '드론 로그 업로드' : '드론 로그'}
              </div>
            </Link>
          </div>

          <div
            className={`item mobile_menu_bbs contents-col ${
              props.mobileActiveMenu == 4 ? 'active' : ''
            }`}
            onClick={() => {
              if (props.mobileActiveMenu == 4) {
                props.setMobileActiveMenu(0);
              } else {
                props.setMobileActiveMenu(4);
              }
            }}
          >
            <div className='image'></div>
            {/* <i class="image fa-solid fa-comments"></i> */}
            <div className='text'>게시판</div>
          </div>
          <div
            className={`item mobile_menu_layer contents-col ${
              mobileLayerMenu == true ? 'active' : ''
            }`}
            onClick={() => {
              if (mobileLayerMenu == false) {
                //false
                props.setMobileActiveMenu(0);
                setMobileLayerMenu(true);
                // setMobileLayerMenu(true);
                // props.handleShowImage(false);
                // props.handleShowTheme(false);
                // props.handleShowFarmwork(false);
              } else {
                setMobileLayerMenu(false);
              }
            }}
          >
            <div className='image'></div>
            {/* <i class="image fa-solid fa-layer-group"></i> */}
            <div className='text'>레이어</div>
          </div>
        </div>
      ) : null}

      <Popup
        show={showPopup}
        onCancel={() => setShowPopup(false)}
        header='알림'
        icon={<i className='code x icon' onClick={() => setShowPopup(false)} />}
      >
        <div className='modal-info'>
          <p>{popupMsg}</p>
          <button className='close_btn' onClick={() => setShowPopup(false)}>
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default Home;

// Viewport (2023.05.15 delete viewport handling)
// const [viewport, setViewport] = useState({
//   width: '100vw',
//   height: '90vh',
//   latitude: props.interactCoord.Y,
//   longitude: props.interactCoord.X,
//   zoom: 12
// });

// const viewportHandler = (coord, zoomlevel) => {
//   setViewport({
//     width: '100vw',
//     height: '90vh',
//     longitude: Number(coord.X),
//     latitude: Number(coord.Y),
//     zoom: zoomlevel
//   })
// }

// useDidMountEffect(()=>{
//   viewportHandler(props.interactCoord, 15)
// }
// ,[props.interactCoord])

// Geojson
// const beforGeojson = e.geoJson;
// const features = [];

// beforGeojson.features.map((f)=>{
//   let coord4326 = [];
//   f.geometry.coordinates[0].map((coord)=>{
//     coord4326.push(proj4("EPSG:5179", "EPSG:4326", [Number(coord[0]), Number(coord[1])]))
//   })
//   let feature = {
//     'type': 'Feature',
//     'geometry': {
//     'type': 'Polygon',
//     'coordinates': [
//       coord4326
//     ]
//     }
//   }
//   features.push(feature);
// })
// Source
// const DRN_SRC_ACTIVE = {
//     type: "geojson",
//     data: {
//     'type': "FeatureCollection",
//     'features': features
//   }
// };

// const colorTable = [
//   '#CC3D3D', // Red
//   '#F15F5F',
//   '#FFA7A7',
//   '#FFC19E',
//   '#FFE08C',
//   '#FAED7D',
//   '#CEF279',
//   '#BCE55C',
//   '#9FC93C', // Green
// ]

// let registerTable = [];
// let curColIdx = 0;
// for (let i = 0; i< e.geoJson.features.length; i++) {
//   let prop = e.geoJson.features[i].properties;
//   let val = e.geoJson.features[i].properties[e.attr_info];

//   if (prop.color) {
//     break;
//   }

//   let exist = registerTable.find(el => {
//     return el.val == val
//   });

//   if (!exist) {
//     registerTable.push({index : curColIdx, val : val});
//     prop.color = colorTable[curColIdx];
//     curColIdx++;
//   }else {
//     prop.color = colorTable[exist.index];
//   }

//   if (curColIdx > colorTable.length ) curColIdx = 0;
// }

// <div
//     className={`subcontents-info farmmap-attribute ${visibleAtr? "active" : ""}`}
// >속성정보
//     {visibleAtr ?
//     <div
//         className="gc-shrink_icon"
//         onClick={() => {
//             setVisibleAtr(!visibleAtr)
//         }}
//     /> :
//     <div
//         className="gc-expansion_icon"
//         onClick={() => {
//             setVisibleAtr(!visibleAtr)
//         }}
//     />}
//     {visibleAtr ?

//     <div className="farmmap-attribute-content contents-col">
//         {props.selectedFarm?
//         <div>
//         <div className="contents-row">
//             <div className="subtitle">ID</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.FMAP_INNB}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">영역</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.AREA}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">갱신일자</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.ITPINP_DE}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">법정동코드</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.LGL_EMD_CD}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">법정동</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.LGL_EMD_NM}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">상세주소</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.LNM}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">PNU코드</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.PNU_LNM_CD}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">속성</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.RNHST_NM}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">등록일자</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.VDPT_YR}</div> : <div className="info"/>}
//         </div>
//         <div className="contents-row">
//             <div className="subtitle">레이어</div>
//             {props.selectedFarm? <div className="info">{props.selectedFarm.layer}</div> : <div className="info"/>}
//         </div>
//         </div>
//         :null}
//     </div>
//     :
//     null
//     }
// </div>
