import './ZoneList.css';

import React, { useEffect, useState } from 'react';
import { Checkbox, Select, Icon, Pagination } from 'semantic-ui-react';

import ZoneInfo from './ZoneInfo';
import Modal from '../../../shared/components/uiElements/Modal'
import Popup from '../../../shared/components/uiElements/Popup'
import questionMarkGuide from '../../../config/questionMarkGuide'

// AXIOS
import axios from "axios";

// TURF
import turf from "turf"

// Proj4
import proj4 from 'proj4'

proj4.defs("EPSG:5179", "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:4326", "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs")

const ZoneList = (props) => {

    // ZoneInfo
    const [showInfo, setShowInfo] = useState(false);
    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState();

    const APIToken = localStorage.getItem('login-token');
    const userID = localStorage.getItem('user-id');

    const [itrstListMy, setItrstListMy] = useState();
    const [itrstListFalse, setItrstListFalse] = useState();

    const [authCheck, setAuthCheck] = useState();

    const [name, setName] = useState(null);
    const [ymd, setYmd] = useState(null);
    const [year, setYear] = useState(null);
    const [crop, setCrop] = useState(null);
    const [etc, setEtc] = useState(null);
    const [id, setID] = useState(null);
    const [username, setUsername] = useState(null);
    const [geojson, setGeojson] = useState(null);
    const [geometry, setGeometry] = useState(null);
    const [tagList, setTagList] = useState(null);

    const [zoneChecked, setZoneChecked] = useState([]);

    // loading animation
    const [loading, setLoading] = useState(false);

    // 관심구역 권한 신청
    const authApplyHandler = (e) => {
        axios({
            method: 'post',
            url: `/api/zone/auth/aply`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: { itrstZoneId: e },
        })
            .then((result) => {
                setPopupMsg(result.data.msg);
                setShowPopup(true);
                searchZoneList();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // 검색 Select
    const options = [
        { key: 1, text: '구역명', value: 'itrst_zone_nm' },
        { key: 2, text: '연도', value: 'yr_info' },
        { key: 3, text: '이모작여부', value: 'cd_expln' },
        { key: 4, text: '생성기관', value: 'inst_nm' },
        // { key: 5, text: '권한상태', value: 'aprv_stts_nm' },
        { key: 5, text: '태그', value: 'tag_list' },
    ]
    // if (localStorage.getItem('user-level') < 10) {
    //     options.push({ key: 6, text: '권한상태', value: 'aprv_stts_nm' });
    // }

    const [itrstZoneSearch_state, setItrstZoneSearch] = useState(options[0].value);
    const handleSearchOption = (e, { value }) => {
        setItrstZoneSearch(value);
    };

    // 검색 input
    const [searchZoneListWord, setSearchZoneListWord] = useState('');

    const handleSearchZoneList = (e, data) => {
        const { value } = e.target;
        setSearchZoneListWord(value);
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0); //페이지 block num
    const handlePagination = (event, { activePage }) => {
        setCurrentPage(activePage);
    };

    // 게시판 조회
    const pageSize = 10;


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            searchZoneList();
        }

    };

    // 관심구역목록 리스트표출
    const statusOptions = [
        { key: 1, text: '전체', value: '1' },
        { key: 2, text: '내 관심구역', value: '2' },
        { key: 3, text: '승인완료', value: '3' },
        { key: 4, text: '승인대기', value: '4' },
    ]
    const [zoneListStatusOptions, setZoneListStatusOptions] = useState(statusOptions[0].value);
    const handleZoneListStatus = (e, { value }) => {
        setZoneListStatusOptions(value);
    }

    const [zoneListStatus, setZoneListStatus] = useState();

    function searchZoneList() {

        setLoading(true);

        let authrtCdResult;

        axios({
            method: 'get',
            url: `/api/zone/list?filterType=${itrstZoneSearch_state}&keyword=${searchZoneListWord}&status=${zoneListStatusOptions}&page=${currentPage}&perPage=${pageSize}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
            .then((result) => {
                if (result.data.success) {//setItrstZoneSearch
                    const searchResult = result.data.data;
                    setZoneListStatus(searchResult);

                    if (searchResult.length === 0) {
                        setCurrentPage(1);
                    }

                    //console.log(searchResult);
                    authrtCdResult = searchResult.authrtcd_nm;
                    // localStorage.getItem('user-level') >= 10 ? (seartchResult.authrtCd === 2) : seartchResult.authrtCd;
                    //authrtCdResult = localStorage.getItem('user-level') >= 10 ? (seartchResult.authrtCdNm === 2) : seartchResult.authrtCdNm;
                    setTotalCount(result.data.total);
                    setTotalPages(Math.ceil((result.data.total) / pageSize));
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
    }

    // 정렬하기
    const sortedItemsClick = () => {
        const sortedItems = [...itrstListMy];
        sortedItems.sort((a, b) => a.itrst_zone_nm.localeCompare(b.itrst_zone_nm));
        setItrstListMy(sortedItems);
    };

    useEffect(() => {
        searchZoneList();
    }, [currentPage, zoneListStatusOptions])

    // 검색
    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (searchZoneListWord) {
                searchZoneList();
            }
            else {
                setPopupMsg('검색어를 입력하세요.');
                setShowPopup(true);
            }
        }
    };

    // 물음표 가이드
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    // const dynamicStyle = {
    //     whiteSpace: isHovered ? 'normal' : 'nowrap',
    // };

    const handleCheckboxChange = (id) => {
        if (zoneChecked.includes(id)) {
            // 이미 체크된 경우, ID 제거
            setZoneChecked(zoneChecked.filter(checkedId => checkedId !== id));
        } else {
            setZoneChecked([...zoneChecked, id]);
        }
    };

    return (
        <React.Fragment>
            <Modal
                isFixed={false}
                show={props.showMng}
                // onCancel={() => props.setShowMng(false)}
                header="관심구역 관리"
                icon={<i className="code x icon" onClick={() => { props.setShowMng(false); }} />}
                isMobile={props.isMobile}
                className={`zoneList`}
            >
                <div className="contents-col zonelist-container">
                    <div className="contents-row main">
                        <div className="title">전체 관심구역 목록</div>
                        <div className="contents-row search">
                            <Select
                                className="select"
                                // placeholder="구역명"
                                options={statusOptions}
                                value={zoneListStatusOptions}
                                onChange={handleZoneListStatus}
                            />
                            {/* <div className='gc-btn' onClick={sortedItemsClick}>정렬하기</div> */}
                            <Select
                                className="select"
                                // placeholder="구역명"
                                options={options}
                                value={itrstZoneSearch_state}
                                onChange={handleSearchOption}
                            />
                            <div className="contents-row field group position-btn">
                                <input
                                    className="input"
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='검색어를 입력하세요.'
                                    value={searchZoneListWord}
                                    onKeyDown={handleKeyPress}
                                    onChange={handleSearchZoneList}
                                    autoComplete="off"
                                />
                                <div className="searchIcon" onClick={searchZoneList}></div>
                            </div>
                        </div>
                    </div>
                    <div className="contents">
                        <table className="zone_list">
                            <thead>
                                <tr>
                                    {/* <th width="5%">
                                    <input 
                                                  type="checkbox" 
                                                //   checked={zoneChecked.includes(todo.itrst_zone_id)} 
                                                  />
                                </th>  */}
                                    <th width="5%">No.</th>
                                    <th width="30%">관심구역명</th>
                                    <th width="10%">태그</th>
                                    <th width="10%">연도</th>
                                    <th width="10%">이모작 여부</th>
                                    <th width="12%">등록일</th>
                                    <th width="10%">생성기관</th>
                                    <th width="12%">권한구분
                                        <i
                                            className={`fa-solid fa-circle-question ${isHovered ? 'hover' : ''}`}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        // style={dynamicStyle}
                                        >
                                            {/* <div className={`tooltip ${isHovered ? 'hover' : ''}`}>{questionMarkGuide.approve}</div> */}
                                            <span className={`tooltip ${isHovered ? 'hover' : ''}`} dangerouslySetInnerHTML={{ __html: questionMarkGuide.approve }} />
                                        </i>

                                    </th>
                                    {/* <i class="fa-solid fa-circle-question" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}></i> */}
                                    {/* <div className={`tooltip ${isHovered ? 'hover' : ''}`}>{questionMarkGuide.approve}</div> */}
                                    {/* <div className={`tooltip ${isHovered ? 'hover' : ''}`}>안내문구 나오는 내용</div> */}
                                    {/* <th><div><i class="fa-solid fa-circle-question"></i></div></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    zoneListStatus && zoneListStatus.map((todo, index) => {
                                        // console.log("todo",todo);
                                        // console.log("index",index);
                                        let tagList = todo.tag_list;
                                        if (typeof tagList === 'string') {
                                            tagList = tagList.replace(/[\[\]"'\s]/g, '').split(',');
                                        }
                                        return (
                                            <tr key={todo.itrst_zone_id}>
                                                {/* <td>
                                            <input 
                                               type="checkbox" 
                                               checked={zoneChecked.includes(todo.itrst_zone_id)}
                                               onChange={() => handleCheckboxChange(todo.itrst_zone_id)}
                                        
                                        /> 
                                        </td> */}
                                                <td>{totalCount - (currentPage - 1) * pageSize - index}</td>
                                                <td
                                                    className="zoneInfoName">
                                                    {todo.itrst_zone_nm}</td>
                                                <td>
                                                    {tagList && tagList.length && tagList[0] !== '' ? (
                                                        <button className="button tagList-one">
                                                            {tagList[0]}
                                                        </button>
                                                    ) : null}
                                                </td>
                                                {/* <td><div className={`button ${tagList && tagList.length ? 'tagList-one' : ''}`}>{tagList && tagList ? tagList[0] : ''}</div></td> */}
                                                <td>{todo.yr_info}</td>
                                                <td>{
                                                    todo.crop_ty_cd === 1 ?
                                                        <p>단작</p>
                                                        :
                                                        todo.crop_ty_cd === 2 ?
                                                            <p>하작</p>
                                                            :
                                                            <p>동작</p>
                                                }</td>
                                                <td>{todo.reg_ymd}</td>
                                                <td>{todo.inst_nm}</td>
                                                <td>
                                                    {
                                                    todo.user_id == userID ?
                                                        (
                                                                <button className="button approve"
                                                                    onClick={() => {

                                                                        setName(todo.itrst_zone_nm)
                                                                        setYmd(todo.reg_ymd)
                                                                        setYear(todo.yr_info)
                                                                        setCrop(todo.crop_ty_cd)
                                                                        setEtc(todo.etc_cn)
                                                                        setID(todo.itrst_zone_id)
                                                                        setUsername(todo.user_nm)
                                                                        setGeojson(todo.geojson)
                                                                        setTagList(tagList)

                                                                        const geometry = JSON.parse(todo.geojson);
                                                                        // const polygon = turf.polygon(geometry.coordinates[0]);
                                                                        const center = turf.center(geometry);
                                                                        const centerCoord5179 = center.geometry.coordinates;
                                                                        const centerCoord4326 = proj4("EPSG:5179", "EPSG:4326", centerCoord5179);

                                                                        if (centerCoord5179[0] > 124.0 && centerCoord5179[0] < 132.0 && centerCoord5179[1] > 32.0 && centerCoord5179[1] < 39.0) {
                                                                            setGeometry(centerCoord5179);
                                                                        }
                                                                        if (centerCoord4326[0] > 124.0 && centerCoord4326[0] < 132.0 && centerCoord4326[1] > 32.0 && centerCoord4326[1] < 39.0) {
                                                                            setGeometry(centerCoord4326);
                                                                        }
                                                                        setAuthCheck(1);

                                                                        setShowInfo(true);


                                                                    }}
                                                                >관리</button>
                                                        ) : 
                                                        (
                                                            todo.authrtcd_nm == '신청' ? (
                                                                    localStorage.getItem('user-level') >= 10 ? (
                                                                        <button
                                                                            className="button approve"
                                                                            onClick={() => {
                                                                                setName(todo.itrst_zone_nm)
                                                                                setYmd(todo.reg_ymd)
                                                                                setYear(todo.yr_info)
                                                                                setCrop(todo.crop_ty_cd)
                                                                                setEtc(todo.etc_cn)
                                                                                setID(todo.itrst_zone_id)
                                                                                setUsername(todo.user_nm)
                                                                                setGeojson(todo.geojson)
                                                                                setTagList(tagList)

                                                                                const geometry = JSON.parse(todo.geojson);
                                                                                // const polygon = turf.polygon(geometry.coordinates[0]);
                                                                                const center = turf.center(geometry);
                                                                                const centerCoord5179 = center.geometry.coordinates;
                                                                                const centerCoord4326 = proj4("EPSG:5179", "EPSG:4326", centerCoord5179);

                                                                                if (centerCoord5179[0] > 124.0 && centerCoord5179[0] < 132.0 && centerCoord5179[1] > 32.0 && centerCoord5179[1] < 39.0) {
                                                                                    setGeometry(centerCoord5179);
                                                                                }
                                                                                if (centerCoord4326[0] > 124.0 && centerCoord4326[0] < 132.0 && centerCoord4326[1] > 32.0 && centerCoord4326[1] < 39.0) {
                                                                                    setGeometry(centerCoord4326);
                                                                                }
                                                                                setAuthCheck(2);
                                                                                setShowInfo(true);
                                                                            }}
                                                                        >
                                                                            조회
                                                                        </button>

                                                                    ) : (
                                                                        <button
                                                                            value={`${todo.itrst_zone_id}`}
                                                                            onClick={e => authApplyHandler(`${todo.itrst_zone_id}`)}
                                                                            type="submit"
                                                                            className="button apply"
                                                                        >
                                                                            신청
                                                                        </button>
                                                                    )
                                                            ):(
                                                                todo.authrtcd_nm === '승인대기' ?
                                                                        <button className="button wait">승인 대기</button>
                                                                        :
                                                                        (todo.authrtcd_nm === '승인완료' ?
                                                                            <button
                                                                                className="button approve"
                                                                                onClick={() => {
                                                                                    setName(todo.itrst_zone_nm)
                                                                                    setYmd(todo.reg_ymd)
                                                                                    setYear(todo.yr_info)
                                                                                    setCrop(todo.crop_ty_cd)
                                                                                    setEtc(todo.etc_cn)
                                                                                    setID(todo.itrst_zone_id)
                                                                                    setUsername(todo.user_nm)
                                                                                    setGeojson(todo.geojson)
                                                                                    setTagList(tagList)

                                                                                    const geometry = JSON.parse(todo.geojson);
                                                                                    // const polygon = turf.polygon(geometry.coordinates[0]);
                                                                                    const center = turf.center(geometry);
                                                                                    const centerCoord5179 = center.geometry.coordinates;
                                                                                    const centerCoord4326 = proj4("EPSG:5179", "EPSG:4326", centerCoord5179);

                                                                                    if (centerCoord5179[0] > 124.0 && centerCoord5179[0] < 132.0 && centerCoord5179[1] > 32.0 && centerCoord5179[1] < 39.0) {
                                                                                        setGeometry(centerCoord5179);
                                                                                    }
                                                                                    if (centerCoord4326[0] > 124.0 && centerCoord4326[0] < 132.0 && centerCoord4326[1] > 32.0 && centerCoord4326[1] < 39.0) {
                                                                                        setGeometry(centerCoord4326);
                                                                                    }
                                                                                    setAuthCheck(2);
                                                                                    setShowInfo(true);
                                                                                }}
                                                                            >
                                                                                조회
                                                                            </button>
                                                                            :
                                                                            <button className="button wait red">승인 거부</button>))

                                                            )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="group">
                        <div className="pagination">
                            <Pagination
                                className="pagination"
                                activePage={currentPage}
                                totalPages={totalPages}
                                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                onPageChange={handlePagination}
                            >
                            </Pagination>
                        </div>
                    </div>
                    <div className='button-container'>
                        <button className="button" onClick={() => {
                            props.setShowMng(false);
                            props.setShowGen(true);
                        }}>관심구역 생성</button>
                    </div>
                </div>
            </Modal>
            {
                showInfo ?
                    <ZoneInfo
                        showInfo={showInfo}
                        setShowInfo={setShowInfo}
                        itrst_zone_nm={name}
                        reg_ymd={ymd}
                        yr_info={year}
                        crop_ty_cd={crop}
                        etc_cn={etc}
                        itrst_zone_id={id}
                        user_nm={username}
                        geoJson={geojson}
                        geometry={geometry}
                        tag_list={tagList}
                        authCheck={authCheck}
                        searchZoneList={searchZoneList}
                    ></ZoneInfo>
                    : null
            }

            <Popup
                show={showPopup}
                onCancel={() => { setShowPopup(false) }}
                header="알림"
                icon={<i className="code x icon" onClick={() => { setShowPopup(false) }} />}
            >
                <div className='modal-info'>
                    <p>{popupMsg}</p>
                    <button
                        className="close_btn"
                        onClick={() => {
                            setShowPopup(false);
                        }
                        }
                    >닫기</button>
                </div>
            </Popup>
            {
                loading == true ?
                    <div className="loading-container">
                        <div id="loading"></div>
                    </div>
                    : null
            }
        </React.Fragment>



    );
}

export default ZoneList;    